.snackbar
	+mbi()
	+br()
	+flexSpread()
	min-height: 5rem
	padding: .8rem $padding - .6 .8rem $padding + .6
	min-width: 36rem
	max-width: 68rem
	color: $white
	font-size: $txt + .1
	line-height: 2rem
	background-color: $dark70
	box-shadow: 0 .3rem .5rem -.1rem $dark20, 0 .6rem 1rem 0 $dark14, 0 .1rem 1.8rem 0 $dark12
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale
	&--success
		background-color: $green
	&--error
		background-color: $red
	&--warning
		background-color: $orange
	&--info
		background-color: $blue
	.icon
		stroke: $white
	&__text
		+textShadow()
		font-size: $txt + .1
		line-height: 2rem
		pointer-events: auto
	&__wrapper
		+flexAxis()
		position: fixed
		bottom: 3rem
		right: 3rem
		z-index: 2000
