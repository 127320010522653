.tooltip
	position: relative
	display: inline-flex
	&:before
		position: absolute
		z-index: 1000001
		display: none
		width: 0
		height: 0
		color: $black80
		pointer-events: none
		content: ''
		border: .6rem solid transparent
		opacity: 0
	&:after
		+br()
		position: absolute
		z-index: 1000000
		display: none
		-webkit-font-smoothing: subpixel-antialiased
		color: $white
		text-align: center
		text-decoration: none
		text-shadow: none
		text-transform: none
		letter-spacing: normal
		word-wrap: break-word
		white-space: pre
		pointer-events: none
		content: attr(aria-label)
		background-color: $black80
		min-height: $buttonHeight + .6
		padding: 0 $padding + .4
		opacity: 0
		box-shadow: 0 0 1.6rem $black15
	// delay animation for tooltip
	@keyframes tooltip-appear
		from
			opacity: 0
		to
			opacity: 1

	&:hover,
	&:active,
	&:focus
		&:before,
		&:after
			+flexAxis()
			text-decoration: none
			animation-name: tooltip-appear
			animation-duration: 0.1s
			animation-fill-mode: forwards
			animation-timing-function: ease-in
			animation-delay: 0.4s

	&.tooltipped
		&-no-delay
			&:hover,
			&:active,
			&:focus
				&:before,
				&:after
					animation-delay: 0s
		&-multiline
			&:hover,
			&:active,
			&:focus
				&:after
					padding-top: 1rem
					padding-bottom: 1rem
					text-align: left
					min-width: 16rem
					white-space: normal
		// Tooltipped south
		&-s,
		&-se,
		&-sw
			&:after
				top: 100%
				right: 50%
				margin-top: .8rem
			&:before
				top: auto
				right: 50%
				bottom: -.9rem
				margin-right: -.6rem
				border-bottom-color: $black80
		&-se
			&:after
				right: auto
				left: 50%
				margin-left: -1.4rem
		&-sw
			&:after
				margin-right: -1.4rem
		// Tooltips above the object
		&-n,
		&-ne,
		&-nw
			&:after
				right: 50%
				bottom: 100%
				margin-bottom: 1.2rem
			&:before
				top: -1.2rem
				right: 50%
				bottom: auto
				margin-right: -.6rem
				border-top-color: $black80
		&-ne
			&:after
				right: auto
				left: 50%
				margin-left: -1.4rem
		&-nw
			&:after
				margin-right: -1.4rem
		// Move the tooltip body to the center of the object.
		&-s,
		&-n
			&:after
				transform: translateX(50%)
		// Tooltipped to the left
		&-w
			&:after
				right: 100%
				bottom: 50%
				margin-right: 1.2rem
				transform: translateY(50%)
			&:before
				top: 50%
				bottom: 50%
				left: -1.2rem
				margin-top: -.6rem
				border-left-color: $black80
		// tooltipped to the right
		&-e
			&:after
				bottom: 50%
				left: 100%
				margin-left: 1.2rem
				transform: translateY(50%)
			&:before
				top: 50%
				right: -1.2rem
				bottom: 50%
				margin-top: -.6rem
				border-right-color: $black80
	&-multiline
		&:after
			width: max-content
			max-width: 25rem
			word-wrap: break-word
			white-space: pre-line
			border-collapse: separate
		&.tooltipped
			&-s,
			&-n
				&:after
					right: auto
					left: 50%
					transform: translateX(-50%)
			&-w,
			&-e
				&:after
					right: 100%
	&-align
		&-right-2
			&:after
				right: 0
				margin-right: 0
			&:before
				right: 1.5rem
		&-left-2
			&:after
				left: 0
				margin-left: 0
			&:before
				left: 1rem
