.box
	+mbib(1)
	+br()
	display: flex
	flex-direction: column
	justify-content: center
	padding: 1.5rem 1rem 1rem 1rem
	border: .1rem solid $border
	text-align: center
	flex-grow: 1
	box-shadow: 0 .3rem 0 $black8
	background-color: $white
	max-width: 26rem
	&:first-child
		margin-right: .5rem
	&:last-child
		margin-left: .5rem
	&__value
		+bold()
		+ellipsis()
		font-size: $title
		max-width: 100%
		min-width: 0
		width: 100%
		margin: auto
	&__label
		color: $light
		font-size: $txtMini
		letter-spacing: .1rem
		max-width: 16rem
		margin: auto
