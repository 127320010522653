.badge
	+flexAxis()
	+sq(2)
	flex-shrink: 0
	background-color: $dark
	color: $white
	&--round
		+br(5)
	&--blue
		background-color: $blue
	&--green
		background-color: $green
	&--red
		background-color: $red
	&--orange
		background-color: $orange
	&--yellow
		background-color: $yellow
	&--pink
		background-color: $pink
	&--blue-dark
		background-color: #0275b2
	&--nano
		+sq(.7)
