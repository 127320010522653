.templates
	position: relative
	.content__sidebar__body__section
		margin-bottom: 3rem
		overflow-y: auto
		height: 100%
		border: 0
		.input
			margin-bottom: 2rem
		&__separator
			border: 0
			height: 3rem
			margin: 0
	&__header
		+flex()
		min-height: 6.5rem
		padding: 0 3rem
		flex-shrink: 0
		border-bottom: .1rem solid $border
	&__sections
		+flexCol()
		height: 100%
		width: 100%
		overflow-y: auto
	&__section
		+flexCol()
		background-color: $blue6
		border-bottom: .1rem solid $border
		flex-grow: 1
		&:only-child
			background-color: transparent
		&:last-child:not(:only-child)
			background-color: transparent
			.templates__section
				&__vars .btn
					background-color: $dark6
					&:hover
						background-color: $blue15
				&__header
					h2
						margin-bottom: 1.2rem
		.templates__section__vars .btn
			background-color: $blue10
			&:hover
				background-color: $blue15
		&__header
			padding: 1.6rem 3rem 0 3rem
			h2
				margin-bottom: 0
		&__options
			+flex()
			padding: 1.6rem 3rem
		&__counter-char
			+flex()
			+bold()
			flex-shrink: 0
			font-size: 1.2rem
			margin: 0 3rem 1rem 3rem
			color: $black
		&__vars
			display: flex
			align-items: center
			flex-wrap: wrap
			gap: .6rem
			.btn
				transition: 0s background-color ease-out
				width: auto
				background-color: $sidebar
				&:hover
					background-color: $blue6
			&__info
				margin-right: 3rem !important
		&__message
			padding: 0 3rem 0 3rem
			flex-grow: 1
			.input__line,
			.input__item,
			.input__container
				height: 100%
			.input
				height: 100%
				&__item
					font-size: 1.4rem !important
					min-height: 16rem
					line-height: 2.4rem !important
		&__footer
			+flex()
			flex-wrap: wrap
			padding: 1rem 3rem
			flex-shrink: 0
			position: relative
			border: 0
			gap: .2rem .2rem
			&__buttons
				+flex()
				gap: .3rem
				margin-right: 1.6rem
			.publication__attachment
				border: 0
		&__attachments
			+flex()
	&__header
		+flex()
		padding: 0 3rem
		.input
			&__item
				+bold()
				transition: 0s background-color ease-out
		&__actions
			+mbi(1)
			+flex()
			justify-content: flex-start
			margin-left: auto
	&__footer
		+flex()
		+mbi(1.6)
		padding: 1.6rem 12rem 1.6rem 3rem
		justify-content: flex-end
	&__type
		+mbi(2)
		display: flex
		height: 100%
		border-right: .1rem solid $border
		margin-right: 3rem
		padding: 0 3rem 0 0
		.no-wrap,
		.dropdown
			+flex()
		.templates__type-button
			> .btn
				transition: 0s background-color ease-out
				width: auto
				background-color: $sidebar
				text-transform: capitalize
				&:hover
					background-color: $blue6
