.timezone
	position relative
	.settings__content
		+mbib(3)
	h4
		margin-bottom: 2rem
	&__week-days
		+br()
		border: .1rem solid $border
		display: flex
		gap: 3rem
		padding: 3rem
		background-color: $white
		ul
			flex-grow: 1
			li
				+bold()
				margin-bottom: 1rem
	&__hours
		+flex()
		+bold()
		gap: 2rem
