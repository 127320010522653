.subscription
	.loader-wrapper
		width: 100%
		height: 100%
	.content__sidebar
		&--large
			max-width: 36rem
			min-width: 28rem
			width: 100%
			flex-shrink: 1
			border-color: $black10
	&--program
		.content__body
			+flexAxis()
			overflow: auto
			.subscription
				&__content
					+mbib(2)
					overflow: auto
					width: auto
					height: auto
					background-color: $white
					margin-bottom: 7rem
			.title
				+bold()
				font-size: 4rem
				line-height: 1.4
		.appsumo
			text-align: center
			&-img
				&-white,
				&-dark
					max-width: 40rem
					margin-bottom: 0 !important
				&-dark
					display: none
	&--choose
		width: 100%
		height: 100%
		background-color: #FFF
		overflow-y: auto
		.billing
			&-switcher
				margin-bottom: 1rem
				padding: 2rem 0
		.subscription
			&__header
				+flex()
				+mbi()
				padding: 1.6rem $sidebarPadding 2.1rem $sidebarPadding
			&__content
				background-color: #FFF
				overflow-y: unset
				height: auto
				padding: 3rem
				max-width: 125rem
				margin: auto
	&--manage
		width: 100%
		height: 100%
		overflow-y: hidden
	&__content
		width: 100%
		height: 100%
		overflow-y: auto
		background-color: $contentSideBarColor
	&__footer-cta
		+flexAxis()
		padding: 0 2rem 2rem 2rem
		.btn
			&:hover
				background-color: $pink !important
	&__info
		+mbib(.4)
		margin-bottom: 1rem
		color: $black
	&__invoice
		&:not(.btn)
			+br()
			border: .1rem solid $border
			background-color: $white
			display: block
			padding: 1rem 1.6rem
			color: $dark
			margin-bottom: 1rem
			&:hover
				text-decoration: none
				border-color: $dark80
				color: $black
				.icon
					stroke: $black
		&__header
			+flex()
			+mbi(.4)
			margin-bottom: 1rem
		&__footer
			color: $blue
		&__amount
			+bold()
			margin-left: auto
	&__detail
		+ellipsis()
		width: 100%
		display: flex
		justify-content: center
		flex-wrap: wrap
		padding: 0 3rem
		&.network
			padding: 0 4rem
			gap: 2rem
	&__quotas
		text-align: center
		width: 100%
		margin: auto
		.box
			padding: 1.7rem 2.3rem
			&__value
				font-size: 3rem
				line-height: 1.4
				margin-bottom: 0
		h1
			font-size: $title + 1
		&--enrich,
		&--scrape
			.account__detail
				padding: 0
				gap: 2rem
				overflow: visible
		&--enrich
			.box__value
				color: $violet

		&--scrape
			.box__value
				color: $blue

.subscribe
	.rc-slider-rail
		background-color: $black16
	.modal__body
		padding: 0
		overflow: hidden
		+flexCol()
	&__wrapper
		height: 100%
		overflow-y: auto
	&__content
		position: relative
		z-index: 1
	&__conditions
		margin: 1.6rem 2.7rem
		text-align: center
		.title
			font-size: 1.4rem
			line-height: 1.4
	&__header
		+flexSpread()
		width: 100%
		border-bottom: .1rem solid $black10
		padding-bottom: 1.6rem
	&__qty
		width: 21rem
		text-align: center
	&__title
		margin-left: 2.7rem
	&__plan-item
		+trans()
		display: flex
		flex-direction: column
		align-items: center
		padding: 1rem 2rem
		color: $black
		min-height: 24rem
		cursor: pointer
		&.active
			cursor: default
			background-color: $blue !important
			color: $white
			.subscribe__plan-item
				&__price,
				&__name
					font-weight: 900
			.plans-limits
				li
					.icon
						stroke: $white
		&:hover:not(.active)
			background-color: rgba($blue, 30%) !important
		&__header
			width: 100%
			margin-bottom: 1.6rem
		&__name
			font-size: 1.5rem
			font-weight: 900
			text-transform: capitalize
			margin-bottom: .6rem
		.plans-limits
			margin-bottom: 0
			li
				font-size: 1.2rem
				gap: .5rem
				font-weight: 500
				.icon
					position: relative
					top: .3rem
					stroke-width: .3rem
	&__seats,
	&__plans
		margin-bottom: 2rem
		.title
			text-align: center
			margin-bottom: 1rem
	&__seats
		&-qty
			width: 100%
			max-width: 20rem
			display: flex
			margin: auto
			gap: .3rem
			.input__item:not([type=button]):not([type=checkbox]):not([type=radio])
				min-width: 4rem
				text-align: center
	&__plans
		padding: 0 2rem
		&__tab
			+br()
			overflow: hidden
			display: flex
			align-items: center
			border: .1rem solid $border
			> .subscribe__plan-item
				flex: 1 1 0px
				&:nth-child(1)
					background-color: #f8f7d8
				&:nth-child(2)
					background-color: #d9f8f9
				&:nth-child(3)
					background-color: #d6ffe9
				&:nth-child(4)
					background-color: #e4eafd

	&__plan
		+flexSpread()
		height: 6rem
		padding: 0 3rem
		border-bottom: .1rem solid $black10
		.input__item
			+bold()
			font-size: 1.5rem
			min-width: unset !important
			max-width: 6rem
			text-align: center
			border: .1rem solid $black20 !important
	&__additionnal-enrich
		margin: 0rem 2.7rem 3rem 2.7rem
	&__plan-enrich
		+flexAxis()
		flex-direction: column
		padding: 0 8rem 5rem 8rem
		position: relative
		min-height: 10rem
	&__amount
		+bold()
		+flexSpread()
		justify-content: flex-end
		width: 100%
		font-size: 1.6rem
		background-color: $blue
		color: $white
		padding: 1.2rem 3rem
		&.alert
			justify-content: flex-start
			margin-bottom: .1rem
			background-color: $red
		&.prorata,
		&.credit
			margin-bottom: .1rem
			.subscribe__month-amount
				justify-content: space-between
		&.credit
			background-color: $green
			text-shadow: .2rem .2rem .2rem $black20
		&.small
			background-color: $blue80
			.subscribe__total-amount
				font-size: 1.9rem
		.prorata__text
			width: 70%
			white-space: normal

	&__year-amount,
	&__month-amount
		+flex()
		+mbi(1)
		white-space: nowrap
	&__total-amount
		justify-content: flex-end
		font-size: 2.4rem

.billing
	&-switcher
		text-align: center
	&-choice
		+flex()
		justify-content: center

@media screen and (min-width: 960px) and (max-width: 1124px)
	.rc-slider-mark-text
		font-size: 1.2rem !important
@media screen and (max-width: 959px)
	.subscription--choose
		.subscription
			&-wrapper
				gap: 6rem
			&-name
				font-size: 3rem !important
			&-amount
				font-size: 3rem !important
			&-wrapper
				width: 100%
				flex-direction: column
				align-items: center
				gap: 6rem
			&-header
				padding: 2rem 3rem
				position: relative !important
				top: unset !important
				&.stuck
					position: relative !important
					top: unset !important
					.subscription
						&-desc
							display: flex
						&-header-info
							margin-bottom: 2rem
						&-cta
							+flex()
							justify-content: center
						&-price
							flex-direction: column
				.pricing-desktop
					display: none
				.pricing-mobile
					display: inline
				&.bottom
					.subscription-header-info
						display: flex
			&-pack
				flex-direction: column
				max-width: 80rem
				.subscription-name
					font-size: 3rem !important
				&-desc,
				&-info
					width: 100%
@media screen and (max-width: 400px)
	.subscription--choose
		.subscription
			&-name
				font-size: 2.6rem !important
			&-amount
				font-size: 2.6rem !important
			&-desc
				font-size: 1.6rem
			&-pack
				&-info
					padding: 1rem
			&-option
				&-container
					padding: 0
					background-color: unset
					border: 0
				&-name
					font-size: 1.6rem
			&-header
				padding: .8rem 1.6rem 1.6rem 1.6rem !important
				&-info
					margin-bottom: 1rem
				&.bottom
					.subscription-option-name
						margin-bottom: 3rem

			&-pack-desc
				padding: .8rem 1.6rem 1.6rem 1.6rem
				.subscription
					&-header
						padding: 0 !important
					&-name
						font-size: 2.2rem !important
					&-option-desc
						font-size: 1.6rem
	.rc-slider-mark-text
		font-size: 1.2rem !important
