.chat
	+trans()
	position: relative
	flex-shrink: 0
	display: flex
	width: calc(100% - 2rem)
	margin-bottom: 1.3rem
	background-color: $chatMessage
	border-radius: 0.6rem
	box-shadow: 0 .3rem 0 $black5
	padding: 1rem 1rem 1.3rem 1rem
	margin: 0 auto 1.3rem auto
	&.me
		background-color: $chatMessageMe
	&.sponsored
		background-color: $sponsorMessage
		.chat
			&__header
				max-width: calc(100% - 11rem)
			&__sponsored
				right: 1rem
	&:hover
		.chat__reaction-btn
			visibility: visible
	&__body
		width: 100%
		min-width: 0
	&__avatar
		+avatar(4.8rem, 1.2rem)
	&__header
		+mbi(.4)
		+flex()
		margin-bottom: .2rem
		max-width: calc(100% - 2.4rem)
	&__info
		+flex()
	&__name
		+bold()
		white-space: nowrap
	&__timestamp
		color: $light
		+ellipsis()
	&__sponsored
		+txtMini()
		+bold()
		+br()
		padding: .2rem .8rem
		background-color: $sponsor
		color: $white
		position: absolute
		right: 3.6rem
		top: .8rem
	&__reaction-btn
		+sq(3)
		position: absolute
		top: .4rem
		right: .4rem
		visibility: hidden
		.popover__child
			+sq(3)
			> .btn
				+sq(3)
				padding: 0
				.icon
					transition: none
				&:hover
					background-color: transparent !important
					.icon
						stroke: $blue !important
	&__message
		display: flex
		flex-direction: column
		&__display
			font-size: $txt + .1
			line-height: $txt * 1.6
			a:not(.attachment)
				color: $blue
				cursor: pointer
				text-decoration: underline
				word-break: break-word
		&__read
			position: absolute
			bottom: .6rem
			right: 1rem
			&__avatar
				+avatar(1.8rem, 0)
		&__reactions
			+mbi(.4)
			margin-top: .4rem
			display: inline-flex
			align-items: center
			flex-wrap: wrap
			overflow: visible
			&__button
				width: 0
				height: 0
				position: relative
			.reaction
				+sq(2.8)
				+br(2)
				+flexAxis()
				cursor: pointer
				border: .1rem solid $black10
				background-color: #ffffff
	&__attachment
		+br()
		margin: .4rem 0 1rem 0
		overflow: hidden
		min-height: 0
		overflow: visible
		> :not(:last-child)
			margin-bottom: 1rem
		video
			+br()
			overflow: hidden
			width: 100%
		audio
			width: 30rem
			height: 3rem
			&::-webkit-media-controls-current-time-display,
			&::-webkit-media-controls-time-remaining-display
				font-size: $txtSmall
			&::-webkit-media-controls-panel
				padding: 1rem 0
			&::-webkit-media-controls-enclosure
				background-color: $blue10
				max-height: 8rem
		.attachment
			display: flex
		img
			+br()
			overflow: hidden
