.tab
	display: flex
	flex-direction: column
	+fullwh()
	&__header
		display: flex
		align-items: flex-start
		justify-content: space-between
		flex-shrink: 0
		&__buttons
			+mbi(3)
			+flex()
			width: 0
			position: relative
			li
				min-width: 0
	&__content
		+fullwh()
		margin-top: 3rem
		display: none
		&.active
			display: block
	&__header
		&--separator
			position: relative
			&:before
				content: ''
				position: absolute
				height: .1rem
				border-bottom: .1rem solid $dark25
				width: 100%
				bottom: .1rem
				left: 0
	&--pills
		.tab__header
			+br()
			+mbi(.5)
			padding: 0 $buttonPadding
			height: 5.2rem
			background-color: $blue6
		.tab__content
			padding: $buttonPadding
			margin-top: $buttonPadding

$tabPadding: 2rem

.demo__code
	.tab
		height: 100%
		pre, code
			margin: 0 !important
			padding: 0 !important
			background-color: transparent !important
			width: 100%
		&__header
			+flexSpread()
			padding: 0 $tabPadding
			flex-shrink: 0
			&__buttons
				+flex()
				+mbi(3)
				width: 0
				position: relative
				li
					min-width: 0
				.btn--tab
					height: auto
					padding: 0 .8rem
					&:after
						top: 2.7rem
		&__content
			line-height: 1.2
			margin: 0
			padding: 1.6rem
			overflow: auto
			max-height: 40rem
			&.active
				display: flex
				height: 100%
