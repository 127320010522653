.pricing
	&__content
		margin: auto
		max-width: 128rem
		width: 100%
	&-layer
		width: 100%
		overflow-x: auto
		padding: 4rem 2rem 2rem 2rem
		position: relative
		z-index: 0

	&-cta
		margin-top: auto
		display: flex
		justify-content: center

	&-wrapper
		flex-shrink: 0
		width: 100%
		display: flex
		font-size: 1.4rem
		position: relative
		> :nth-child(1)
			background-color: #f8f7d8
			border-radius: $br 0 0 $br
			.plans-price-wrapper
				border: .1rem solid #dbd641
		> :nth-child(2)
			background-color: #d9f8f9
			.plans-price-wrapper
				border: .1rem solid #5fced1
		> :nth-child(3)
			background-color: #d6ffe9
			box-shadow: 0 0 2rem $black10
			z-index: 10
			.plans-price-wrapper
				border: .1rem solid #67dd9e
		> :nth-child(4)
			background-color: #e4eafd
			border-radius: 0 $br $br 0
			.plans-price-wrapper
				border: .1rem solid #8ea5f0

	&-notice
		text-align: center
		font-size: 1.2rem
		margin-top: 2rem
	&-key-features
		color: $black
		font-size: 1.6rem
		width: 38rem
		@media screen and (max-width: 860px)
			width: 100%
		&-title
			width: 100%
			position: relative
			margin-bottom: 6rem
			padding: 0 1rem
			@media screen and (max-width: 860px)
				margin-top: 6rem
			&:before
				content: ''
				height: .1rem
				width: calc(100% - 2rem)
				background-color: $black
				display: block
				position: absolute
				top: 3rem
				z-index: 1
				@media screen and (max-width: 860px)
					content: unset
					font-size: 2.3rem
			.small-title
				display: inline-block
				margin: auto
				position: relative
				z-index: 2
				padding: 0 2rem 0 0
				background-color: $white
				text-align: left
				@media screen and (max-width: 860px)
					padding: 0

		&-wrapper
			+br()
			width: 100%
			gap: 4rem
			margin: auto
			display: flex
			flex-wrap: wrap
			justify-content: center
			padding: 0 1rem
			// box-shadow: 0 0 1rem $black10
		&-cat
			font-size: 1.8rem
			text-align: left
			margin-bottom: 1.6rem
			line-height: 1.4
		&-list
			margin-bottom: 3rem
			li
				list-style: none
				display: flex
				gap: .6rem
				margin-bottom: .6rem
				.icon
					position: relative
					top: .4rem
					stroke-width: .2rem
.currency
	font-size: 2.6rem
	position: relative
	bottom: .6rem
.plans
	display: flex
	flex-direction: column
	flex: 1 1 0px
	flex-shrink: 0
	text-align: left
	min-width: 32rem
	padding: 2rem 1.6rem 4rem 1.6rem
	position: relative
	@media screen and (max-width: 460px)
		min-width: 90%
	h4
		text-align: left
		width: 100%
		font-size: 1.6rem
		line-height: 1.4
		margin: 0 0 2rem 0
	&-highlight
		margin-top: 4rem
		margin-bottom: 3rem
		font-weight: 800
		color: $pink
		font-size: 1.7rem
		@media screen and (max-width: 460px)
			padding: 0 2rem
	&-name
		font-size: 2.2rem
		font-weight: 800
		height: 5rem
	&-desc
		font-size: 1.4rem
		margin-bottom: 1rem
		min-height: 6rem
		font-weight: 700
	&-yearly
		&-details
			display: flex
			gap: 1rem
			font-weight: 600
			margin-bottom: 1rem
			.currency
				font-size: 1.3rem
				bottom: 0
			.normal-price
				color: $black60
				text-decoration: line-through
			.discount-price
				color: $pink
	&-limits
		margin-bottom: 3rem
		li
			list-style: none
			display: flex
			align-self: center
			gap: 1rem
			font-weight: 680
			font-size: 1.4rem
			margin-bottom: 1rem
			.icon
				stroke-width: .2rem
	.most-popular
		border-radius: $br $br 0 0
		position: absolute
		width: calc(100% + .2rem)
		border-bottom: 0
		top: -3.4rem
		left: -.1rem
		display: flex
		justify-content: center
		background-color: $black
		&-label
			+br()
			display: inline-flex
			color: $white
			font-weight: 700
			font-size: 1.2rem
			padding: .8rem 1.2rem

	&-price
		font-size: 3.8rem
		line-height: 1
		&-wrapper
			+br()
			display: flex
			flex-direction: column
			min-height: 10rem
			padding: 1rem 2rem
			background-color: $white70
			justify-content: center
			margin-bottom: 3rem
			min-width: 20rem
			box-shadow: 0 0 .4rem $black5
		&-secondary
			.plan-price
				font-size: 2.2rem
				font-weight: 600
			.currency
				font-size: 1.6rem
				bottom: .2rem
			&:before
				content: '+ '
				font-size: 1.2rem
				position: relative
				bottom: .3rem
		&-details
			letter-spacing: .05rem
.email-packs
	+br()
	width: 100%
	color: $black
	margin: 0 0 6rem 0
	position: relative
	background-color: #fff3d7
	padding: 2rem 3rem 8rem 3rem
	font-size: 1.4rem
	&-header
		display: flex
		justify-content: space-between
		font-size: 1.6rem
		gap: 3rem
		@media screen and (max-width: 660px)
			flex-direction: column
	.rc-slider
		max-width: 96%
		margin: auto
		&-mark-text
			white-space: nowrap
	&-cta
		font-size: 1.8rem
		font-weight: 800
		margin-bottom: 4rem
	.plans
		&-name
			height: auto
			margin-bottom: 1rem
			font-size: 2.6rem
		&-price-wrapper
			text-align: center
			border: .1rem solid #f8d992
			min-height: unset
			margin-bottom: 0
		&-limits
			margin: 3rem 0 4rem 0
			li
				font-size: 1.6rem
	.btn
		background-color: $black
		&:hover
			background-color: $primary
	&-desc
		width: 50%
		padding: 2rem 3rem
	&-info
		width: 50%
		background-color: $white
		padding: 1rem 5rem
	&.pack-one
		.subscription-pack-desc
			background-color: $packOne
