.pipeline
	.input__item
		font-weight: 760
		color: $black
	.column
		+br(1)
		background-color: #F4F4F4
		padding: 1.9rem
		width: 29rem
		flex-shrink: 0
		.info
			margin-bottom: 1.3rem
		.autom-step
			+sq(3.5)
			margin: 2rem auto
	.type
		+br()
		+flex()
		min-height: 4.6rem
		padding: 1rem 1rem 1rem 5rem
		color: $white
		font-weight: 700
		margin-bottom: 1rem
	.move,
	.lbl
		+br()
		background-color: $white
		color: $black
		font-weight: 700
		padding: 2rem
		&-header
			+flex()
			gap: 1rem
			margin-bottom: 2rem
			img
				max-width: 2.8rem
	.move
		margin-bottom: .6rem

	.action
		&-blue
			.type
				background-color: $blue
				background-image: url(../../images/automations/play.webp)
				background-size: 2.8rem
				background-repeat: no-repeat
				background-position: 1rem .9rem
			.autom-step
				background-color: $blue
		&-green
			.type
				background-color: $step-green
				background-image: url(../../images/automations/response.webp)
				background-size: 2.8rem
				background-repeat: no-repeat
				background-position: 1rem .9rem
			.autom-step
				background-color: $step-green
		&-red
			.type
				background-color: $step-red
				background-image: url(../../images/automations/hourglass.webp)
				background-size: 2.8rem
				background-repeat: no-repeat
				background-position: 1rem .9rem
			.autom-step
				background-color: $step-red
		&-purple
			.type
				background-color: $step-purple
				background-image: url(../../images/automations/checkmark.webp)
				background-size: 2.8rem
				background-repeat: no-repeat
				background-position: 1rem .9rem
			.autom-step
				background-color: $step-purple
