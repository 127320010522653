.modal
	+fullwh()
	position: fixed
	top: 0
	left: 0
	z-index: 1040
	background-color: $modalBg
	&__wrapper
		+fullwh()
		+flex()
		position: fixed
		top: 0
		left: 0
		z-index: 1050
		overflow-x: hidden
		overflow-y: auto
		outline: 0
		padding: 1rem
	&__content
		+br()
		display: flex
		flex-direction: column
		flex-grow: 1
		z-index: 100
		background: #fff
		position: relative
		margin: auto
		max-width: $modalWidth
		width: 100%
		max-height: 100%
		box-shadow: 0 0 30rem $modalBg
		.modal--full &
			+fullwh()
			max-width: 100%
	&__body
		+fullwh()
		position: relative
		padding: 0 2.4rem
		overflow-y: auto
		.modal--full &
			padding: 0
		&--blur
			pointer-events: none
		&--overflow
			overflow: visible
		&__loading-layer
			+fullwh()
			+flexCol()
			z-index: 1
			position: absolute
			align-items: center
			justify-content: center
			-webkit-backdrop-filter: +blur(2rem)
			backdrop-filter: +blur(2rem)
	&__close
		&--outside
			position: fixed
			top: 2rem
			right: 2rem
			cursor: pointer
			.icon
				stroke: $white
	&__footer,
	&__header
		flex-shrink: 0
		padding: 1.8rem 2.4rem
	&__header
		h1
			margin-bottom: 0
		.modal--full &
			border-bottom: .1rem solid $dark30
		&__actions
			display: flex
			justify-content: flex-end
			min-width: 6rem
	&__footer
		.modal--full &
			border-top: .1rem solid $dark30
		&__stepper
			+flexSpread()
			width: 100%
			&__left,
			&__center,
			&__right
				+flex()
				flex-basis: 100%
				flex-grow: 1
			&__center
				justify-content: center
			&__right
				justify-content: flex-end
				+mbi(1)
				.btn--skip
					margin-right: 2rem
	&__header
		+flexSpread()
		h1
			font-size: $title
	&__footer
		+mbi()
		+flex()
		justify-content: flex-end
		&__error
			color: $red
