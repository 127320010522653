.gif-picker
	+flexCol()
	position: relative
	width: 35rem
	background-color: #fff
	height: 100%
	max-height: 40rem
	overflow: hidden
	&-header
		width: 100%
		padding: .6rem 2rem
		border-bottom: .1rem solid $black10
	&-content
		+fullwh()
		display: flex
		padding: .4rem
		gap: .4rem
		overflow-y: auto
		flex-wrap: wrap
		background-color: $black80
	&-item
		+br()
		overflow: hidden
		flex-shrink: 0
		width: 100%
		max-width: 16.6rem
		background-color: $white
	&-wrapper
		margin-left: auto
