.datetime
	.rdtPicker
		+box()
		padding: calc($padding / 2) $padding - .4rem
		min-width: 29rem
		thead
			tr
				&:first-of-type
					th
						vertical-align: middle
						font-weight: 900
						&.rdtPrev,&.rdtNext
							+sq()
							margin: 0
						&:hover
							background-color: transparent
		.dow
			padding: 1rem 0
		.rdtDay
			+br(50)
			+sq()
			vertical-align: middle
			+trans(.2s)
			&:hover
				+br(50)
				background-color: $blue
				color: $white
				font-weight: 900
			&.rdtOld
				color: $dark30
				&:hover
					color: $white
					background-color: $dark
			&.rdtNew
				color: $dark30
				&:hover
					color: $white
					background-color: $blue
			&.rdtActive
				+br(50)
				background-color: $blue
				color: $white
				font-weight: 900
			&.rdtToday
				+br(50)
				background-color: $red
				color: $white
				font-weight: 900
				&:before
					content: ''
					border: 0
				&.rdtActive
					+br(50)
					background-color: $blue
					content: ''
					border: .2rem solid $red
					&:hover
						color: $white
						background-color: $blue
				&:hover
					color: $white
					background-color: $blue
		.rdtTimeToggle
			height: $inputHeight + 1
			vertical-align: middle
			font-weight: 900
			border-top: .1rem solid $dark30
			padding: 1rem 0
			&:hover
				+br()
				background-color: $blue15
				color: $blue
	.rdtCounters
		+mbi(.4rem)
		+flexAxis()
		> :last-child
			margin-left: .4rem
	.rdtCounter
		height: auto
		.rdtBtn
			+sq()
			+br(50)
			+trans(.2s)
			margin: .4rem 0
			&:hover
				background-color: $blue15
				color: $blue
	.rdtCount
		+br()
		height: 3rem
		+flexAxis()
		background-color: $dark15
		line-height: 1
	.rdtCounterSeparator
		line-height: 10.8rem
	.rdtSwitch
		+trans(.2s)
		+br()
		font-weight: 700 !important
		font-size: 1.8rem
		height: 3.4rem
		vertical-align: middle
		&:hover
			color: $blue
			background-color: $blue15 !important
	.rdtPrev, .rdtNext
		> span
			+sq()
			+br(50)
			+trans(.2s)
			+regular()
			background-color: $dark15
			font-size: 3rem
			color: $white1
			&:before
				color: $dark
				content: '‹'
				position: relative
				top: .6rem
				left: .4rem
		&:hover
			> span
				background-color: $blue15
				&:before
					color: $blue
	.rdtNext
		> span
			&:before
				content: '›'
				left: .5rem
	&--time-only
		.rdtPicker
			padding: 0 $padding + .6
			min-width: auto
