.flash
	+br()
	+mbi(.6)
	+flex()
	flex-shrink: 0
	border-width: .1rem
	border-style: solid
	border-color: $flashLightDark
	color: $black90
	&:not(.flash--inverse)
		min-height: $buttonHeight
		padding: 1.2rem 1.6rem
	.icon
		stroke: $black90
	&--fill
		width: 100%
	&--iconed
		padding: .9rem $padding .9rem $padding - .4
	&--green
		background-color: $flashGreen
		border-color: $flashGreen
	&--blue
		background-color: $flashBlue
		border-color: $flashBlueDark
	&--yellow
		background-color: $flashYellow
		border-color: $flashYellowDark
	&--orange
		background-color: $flashOrange
		border-color: $flashOrangeDark
	&--red
		background-color: $flashRed
		border-color: $flashRedDark
	ul li
		list-style-type: disc
		list-style-position: inside
	&-content
		+ellipsis()
	&--wrap
		.flash-content
			white-space: normal
			overflow: auto
			word-break: unset
