.api
	&.settings__content
		background-color: $white
	&__header
		+flexSpread()
		max-height: $listHeight
		height: 100%
		flex-shrink: 0
		border-bottom: .1rem solid $border
		padding: 0 $boardPad
	&__link
		+br()
		display: inline-flex
		align-items: center
		border: .1rem solid $black
		align-self: flex-start
		gap: 2rem
		padding: 1rem 2rem
		&__key
			+bold()
			font-size: 1.6rem
			min-width: 33rem
