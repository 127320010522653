$dark60: rgba(60, 67, 89, .6)
.help-popover
	&__content
		display: flex
		flex-direction: column
		max-width: 20rem
		margin-bottom: 1rem
		font-size: 1.2rem
		color: $dark60
		&__url
			font-size: 1.2rem
	&__button
		&:not(:hover):not(:focus)
			.icon
				opacity: .3

@media screen and (max-width: 1090px)
	.help-popover__button
		.icon
			display: none
