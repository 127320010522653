
.connections
	&__header
		+flex()
		height: $listHeight
		flex-shrink: 0
		overflow-x: auto
		border-bottom: .1rem solid $border
		&__actions
			+flex()
			border-left: .1rem solid $border
			height: 100%
			margin-left: auto
			.popover__child
				height: 100%

	.content
		&__body
			width: 100%
			height: 100%
			overflow: auto
		&__sidebar
			&__header
				padding-bottom: 0.4rem
			&__body
				&__section
					&-container
						background-color: transparent
					&__filters
						.label,
						.popover__child
							width: 100%
							margin: 0
						.popover__tooltip__cursor
							justify-content: flex-start
							width: 100%
							> div
								flex-grow: 1
								opacity: .4

						.btn
							height: 3.4rem !important
							&--group-sidebar
								.dropdown
									&__menu
										right: -1.6rem
									> .btn
										height: 2.6rem !important
									.btn--dropdown-item
										&.btn--notification
											color: $blue !important
											&:before
												content: unset !important
					.input
						margin-bottom: 1.1rem
						&.input--filter
							&.input--iconed
								&.input--small
									.icon
										left: -.2rem
										top: .5rem
					ul ul
										margin-left: 2rem
	&--list
		width: 100%
		height: 100%
		overflow: auto
		.list__item
			&:first-child
				.popover__tooltip__cursor,
				.popover__child
					height: 100%
					padding: 0
			.dropdown-menu
				.popover__child
					width: 100%
		.card-notes
			.btn
				margin: auto

	.list
		.inactive-label
			+regular()
			color: $dark
			margin-left: 1rem
			display: inline-block
			font-style: italic
		&__filter
			&.service-message--info
				z-index: 0
				+bold()
				a
					color: $black
					text-decoration: underline
			.popover__child
				display: inline-flex
				align-items: center
				height: 100% !important
				.btn
					flex-shrink: 0
					padding: 0 2.4rem 0 1.8rem
					height: 100%
					&--processing
						padding: 0 1.8rem
			&--actions
				&__btn,
				&__btns
					+flexAxis()
					flex-shrink: 0
					width: 4.8rem
					height: 100%
					border-right: .1rem solid $border
					.dropdown,
					.tooltip
						width: 100%
						height: 100%
					.dropdown
						&__menu
							min-width: 26rem
					&.last
						margin-left: auto
						border: 0
						border-left: .1rem solid $border
				&__unread
					flex-shrink: 0
					height: 100%
					.dropdown
						height: 100%
						> .btn
							+flexAxis()
							+bold()
							width: auto
							min-width: 8rem
							padding: 0 2.2rem 0 1.8rem
							height: 100%
							border-radius: 0
							border-right: .1rem solid $border
							background-color: $blue10
						.popover
							&__child
								width: 100%
								height: $buttonHeight
							&__tooltip__cursor
								width: 100%
								height: 100%
								.btn
									padding: $buttonPadding
									color: $dark40

				&__btns
					max-width: 9.7rem
				.select-indicator
					+trans()
					+mbi(1)
					+flexAxis()
					border-radius: 0
					height: 100%
					border-right: .1rem solid $border
					flex-shrink: 0
					padding: 0 1.7rem
					.tooltip
						width: auto
						height: auto
						max-width: unset
			&__selected
				+flex()
				flex-shrink: 1
				flex-wrap: wrap
				> *
					margin-right: .6rem
					&:last-child
						margin-right: 2rem
			&__actions
				+flex()
		&__col
			&.date-picker
				.dropdown
					&__menu
						min-width: 26rem
			&--email
				max-width: 26rem
			&--checkbox
				max-width: unset
				min-width: unset
				width: 6rem
				height: 100%
				flex-shrink: 0
				padding-right: 0 !important
				.input
					+fullwh()
					+flexAxis()
					&__line
						min-width: unset
						max-width: unset
						width: auto
			&--info
				+flex()
				height: 100%
				overflow: visible !important
			&--name
				+flex()
				max-width: 26rem
				min-width: 16rem
				height: 100%
				padding-left: .3rem
				overflow: visible !important
			&--headline
				+regular()
				min-width: 6rem
			&--board
				max-width: 26rem
				justify-content: flex-start
				.btn-group
					height: 3.4rem
					.btn
						height: 100%
			&--label
				max-width: 9rem
				justify-content: flex-start
				.tooltip
					&:before
						left: -1.7rem
					&:after
						margin-right: 1.7rem
				.label
					+sq(2.2)
					position: relative
					.icon
						position: absolute
						top: calc(50% - 1.2rem)
						left: calc(50% - 1.2rem)
				.tooltip
					+txt()
				.lnuser__labels__header
					margin-bottom: 0
		&__item
			transition: unset
			.btn--board
				transition: unset
				.btn
					transition: unset
			.btn--label,
			.btn.btn--board,
			.btn--board .btn,
			.btn--pin:not(.active),
			.btn--reminder
				transition: unset !important
				text-decoration: none !important
				visibility: hidden
				color: $light
				.icon,
				.btn__label
					transition: unset !important
				&:hover
					color: $blue
			&:hover
				.btn--label,
				.btn.btn--board,
				.btn--board .btn,
				.btn--pin,
				.btn--reminder
					visibility: visible
				.btn--group-item
					border-color: $dark15 !important
			.btn--pin
				&:hover
					background-color: $blue10
				&.active
					background-color: transparent
					&:hover
						background-color: $blue10
			&.lead-contact-item,
			&.lead-relation-item,
			&.relation-item,
			&.contact-item,
			&.sponsor-item
				.relation-status
					flex-shrink: 0
					&.tooltipped-e
						&:after
							margin-left: 0
						&:before
							right: 0
			&.lead-contact-item
				.list__col--checkbox
					+userStatus($leadContact)
					position: relative
				.relation-status
					&:after
						background-color: $leadContact
					&:before
						border-right-color: $leadContact
			&.lead-relation-item
				.list__col--checkbox
					+userStatus($leadRelation)
					position: relative
				.relation-status
					&:after
						background-color: $leadRelation
					&:before
						border-right-color: $leadRelation
			&.relation-item
				.list__col--checkbox
					+userStatus()
					position: relative
				.relation-status
					&:after
						background-color: $relation
					&:before
						border-right-color: $relation
			&.contact-item
				.list__col--checkbox
					+userStatus($contact)
					position: relative
				.relation-status
					&:after
						background-color: $contact
					&:before
						border-right-color: $contact
			&.sponsor-item
				.list__col--checkbox
					+userStatus($sponsorContact)
					position: relative
				.relation-status
					&:after
						color: $dark
						background-color: #fefefe
					&:before
						border-right-color: #fefefe
			&.unread
				.list__row
					background-color: $unread
					// box-shadow: inset 0 0 2rem $black8
					position: relative
				&:hover
					.list__row
						background-color: $unreadHover
				.connections__list__user
					&__item
						*
							color: $black
					&__message
						+bold()
			&.remind
				.list__row
					background-color: $remind
					position: relative
				&:hover
					.list__row
						background-color: $remindHover
				&.unread
					.connections__list__user
						&__item
							*
								color: $black
						&__message
							+bold()

			.invited
				margin-left: .4rem
				margin-right: .3rem
				flex-shrink: 0
				&.tooltipped-e
					&:after
						margin-left: .6rem
						background-color: $blue
					&:before
						right: -.6rem
						border-right-color: $blue
	&__list
		&__user
			+flex()
			width: 100%
			position: relative
			.starred-conversation
				position: absolute
				top: -.8rem
				left: -.8rem
			.sponsored
				+txtNano()
				+br()
				color: $black90 !important
				background-color: $white
				padding: 0 .5rem
				border: .1rem solid $black60
				line-height: 1.8
			.sponsored-message
				+mbi(.6)
				display: flex
				align-items: center
				color: $dark
			&__avatar
				+avatar(5.1rem)
			&__item
				overflow: hidden
			&__info
				overflow: hidden
				display: flex
				width: 100%
				min-width: 0
			&__name
				+ellipsis()
				+regular
				+flex()
				position: inherit
				margin-bottom: .2rem
				color: $light
				.name
					+bold()
					color: $dark
				.headline
					+ellipsis()
				.enriched-profile
					border-color: $dark
					.icon
						stroke: $dark
			&__date
				+regular()
				+txtSmall()
				color: $light
				display: inline-block
				&:first-letter
					text-transform: capitalize
			&__message
				+regular()
				+ellipsis()
				display: flex
				flex-direction: column
