.leads
	.list-owner
		+flex()
		.avatar
			+avatar(3,.4)
			+br(10)
	&.content
		width: 100%
		overflow: hidden
	.content
		&__body
			width: 100%
			overflow: hidden
		&__sidebar
			&__header
				padding: 1.6rem $sidebarPadding - .4 2.1rem $sidebarPadding - .4
			&__body
				&__section
					+flexCol()
					height: 100%
					padding: 0
					.title
						+flexSpread()
						padding: 0 $sidebarPadding - .4
						height: 4.8rem
						border-bottom: .1rem solid $border
						margin: 0
					&__filters
						overflow-y: auto
						height: 100%
						background-color: $white
						margin-bottom: 0
	&__filter
		padding: 1.4rem $sidebarPadding - .4
		border-bottom: .1rem solid $border
		min-height: 5rem
		&__header
			+flexSpread()
			flex-shrink: 0
			color: $black
			.btn
				&:disabled
					display: none !important
		&__body
			position: inherit
		&__activated
			+flex()
			flex-wrap: wrap
			width: 100%
			margin-top: .6rem
			.label
				margin: .3rem .6rem .3rem 0
				&__name
					+mbi(.4)
					&__value
						+bold()
		&__add
			+flex()
			width: 100%
			&__btn-group
				+flex()
				margin-top: .6rem
				> :first-child
					flex-grow: 1
					border-radius: .6rem 0 0 .6rem !important
					border: .1rem solid $inputBorderColor
					border-right: 0
				> :nth-child(2):not(:last-child)
					border-radius: 0 !important
				> :nth-child(2):not(:last-child):not(.btn)
					border: .1rem solid $inputBorderColor
				> :last-child
					border-radius: 0 .6rem .6rem 0 !important
				> :last-child:not(.btn)
					border: .1rem solid $inputBorderColor
				.input
					height: 3.6rem
					&__item
						height: 3.4rem !important
						border: 0 !important
						min-width: 6rem !important
				.dropdown
					+flexSpread()
					padding: 0 1rem
					height: $buttonHeight
					background-color: $black3
					.btn
						width: 100%
						&__label
							flex-grow: 1
							white-space: nowrap
							margin-right: .4rem !important
					&__menu
						right: 0
		&__remove
			margin-left: 1rem
			margin-top: .6rem
			.icon
				stroke: $dark40
				stroke-width: .2rem
