@mixin list()
	+flexAxis()
	transition: all .3s ease
	transition-property: box-shadow, border
	width: 100%
	min-height: $listHeight
	border-bottom: .1rem solid $border
	padding: 0 $listPadding 0 0
.list
	height: 100%
	width: 100%
	position: relative
	overflow: visible !important
	&--even-odd
		.list__item:nth-child(even)
			.list__row:not(.list__row--red)
				background-color: #F9F9F9
		.list.list--clickable
			.list__row
				&:hover
					background-color: $listHoverDark
			.list__item:nth-child(even)
				.list__row
					&:hover
						background-color: $listHoverDark

	&--pattern
		background-color: transparent
		+bgPattern()
		position: relative
	&--bg
		background-color: $dark3
	&--empty
		+flexAxis()
		position: relative

	&__item
		position: relative
		width: 100%
		min-width: 0
		&.archived--item
			.list__row
				background-image: url(../../images/archived-list.png)
		&--all-leads
			.list__row
				background-color: #fbfbfb
		&__name
			+ellipsis()

	&__header
		+txtSmall()
		+flex()
		width: 100%
		height: 3.3rem
		background: #F9F9F9
		padding-right: $listPadding + .4
		flex-shrink: 0
		border-bottom: .1rem solid $border
		.list__col--fill
			+regular()
	&__filter
		display: flex
		height: $listHeight
		border-bottom: .1rem solid $border
		flex-shrink: 1
		min-width: 0
		width: 100%
		&--legend
			display: flex
			align-items: center
			padding: 0 $listPadding
			height: 100%
			border-right: .1rem solid $border
			background-color: $white
			gap: 2rem
			.legend
				+br()
				display: flex
				align-items: center
				padding: 1rem
				min-width: 6rem
				box-shadow: 0 0 1rem $black15
				gap: 1rem
				&-inbox
					background-color: #fdffe9
				&-duplicated
					background-color: #fff3f3
		.bulks
			min-width: 0
			display: flex
			flex-shrink: 0
			&--linkedin
				border-bottom: .1rem solid rgba($linkedin, 50%)
			&--kanbox
				border-bottom: .1rem solid rgba($light, 80%)
			&-container
				display: flex
				flex-grow: 1
				flex-shrink: 1
			.ln
				position: relative
				background: linear-gradient(210deg, $linkedin -100%, #fff 30%)
				&:before
					content: ''
					+sq(1)
					position: absolute
					background-image: url(../../images/linkedin.webp)
					background-repeat: no-repeat
					z-index: 10
					background-size: 1rem
					top: .4rem
					right: .4rem
		.input
			+flex()
			flex-grow: 1
			padding: 0 1.9rem 0 3rem
			height: 100%
			&__item
				+bold()
				color: $black
		&--activated
			.input
				position: relative
				z-index: 10
				background-color: $blue12
		&--actions
			&__btn,
			.btn--processing
				+flexAxis()
				flex-shrink: 0
				height: 100%
				border-right: .1rem solid $border
				.tooltip
					width: 100%
					height: 100%
				&.last
					margin-left: auto
					border: 0
					border-left: .1rem solid $border
				.popover__child
					width: 100%
					height: 100%
			&__btn--processing
				max-width: unset
				flex-shrink: 0
				height: 100%

	&__row
		+list()
		background-color: $white
		&--no-border
			&.list__row
				border: 0
		&--red
			background-color: rgba(255, 0, 85, 0.03)
	&.list--small
		.list__row
			min-height: $listHeight - 1
			margin-bottom: .4rem
			padding: 0 1.2rem
	&.list--clickable
		.list__row
			cursor: pointer
			&:hover
				background-color: $listHover
			&--red
				&:hover
					background-color: rgba(255, 0, 85, 0.08)

	&.list--actions-on-hover
		.list__row
			&:hover
				.list__col--action,
				.list__col--hidden-action
					> .btn
						visibility: visible
					> .dropdown
						visibility: visible
					> .popover__child
						visibility: visible

	&.list__item--highlighted-item
		.list__row
			background-color: #e5eeff
			&:hover
				background-color: #d2e2ff
				border: .1rem solid $dark10
	&.list__item--previous-item
		.list__row
			background-color: #fffae3 !important
			border: .1rem solid $dark10 !important
	&__loader
		+bold()
		+txtSmall()
		+flexAxis()
		width: 100%
		background-color: $blue
		padding: .4rem 0
		color: $white
	&__loader-icon
		+flexAxis()
		width: 100%
		height: 100%
		background: red
	&__cols
		+pbi(1.2)
		+flex()
		width: 100%
		flex-grow: 1
		height: $listHeight - .2 // do not remove : for the contacts / relation left color lines
	&__avatar
		width: 5rem
		height: 5rem
	&__btn-dropdown
		width: 100%
		display: flex
	&__col
		width: 100%
		min-width: 3rem
		max-width: 15rem
		flex-shrink: 1
		&:not(.list__col--action):not(.list__col--hidden-action) // if not dropdown actions are hidden by the overflow
			+ellipsis()
		&:last-child
			margin-left: auto
		&--spacer
			max-width: unset
			min-width: unset
			width: 2rem
			flex-shrink: 0
		&--mini
			min-width: 0
			max-width: 5rem
			flex-shrink: 0
		&--small
			min-width: 0
			max-width: 8rem
			flex-shrink: 0
		&--large
			min-width: 0
			max-width: 20rem
			flex-shrink: 0
		&--larger
			min-width: 0
			max-width: 26rem
			flex-shrink: 0
		&--fill
			+bold()
			min-width: 24rem
			max-width: none
		&--action,
		&--hidden-action
			+flexAxis()
			.dropdown__menu,
			.btn
				+trans()
		&--hidden-action
			max-width: 3rem
			.btn
				transition: none
				.icon
					transition: none
		&--center
			text-align: center
	@keyframes fakeGrandient
		0%
			background-position: 50% 0%
		100%
			background-position: -70% 0%
	&__fake
		+pbi(1.2)
		+flexAxis()
		position: relative
		z-index: 1
		height: $listHeight
		padding: 0 1.8rem
		border-bottom: .1rem solid $border
		background-color: $white
		&:before
			position: absolute
			top: 0
			left: 0
			content: ''
			width: 100%
			height: 100%
			background: $white
			background: linear-gradient(90deg, $white0 0%, $white 5%, $white0 10%)
			background-size: 300% 300%
			animation: fakeGrandient 1.6s linear infinite
		&__item
			+br()
			background-color: $sidebar
			background-clip: content-box
		&--searches,
		&--connections
			padding-left: 0
			background-color: $white
			> :first-child
				+flexAxis()
				width: 6rem
				height: 100%
				flex-shrink: 0
				margin-right: 0 !important
				padding-right: 0 !important
				.list__fake__item
					+sq($fakeHeight)
					+br()
		&--leads
			padding-left: 0
			background-color: $white
			height: 4rem
			.list__fake__item
				height: $fakeHeight - .6
			> :nth-child(2)
				.list__fake__item
					max-width: 1rem
					width: 100%
					flex-shrink: 1
			> :nth-child(3)
				.list__fake__item
					max-width: unset
					width: 100%
					flex-shrink: 1
			> :nth-child(4)
				.list__fake__item
					max-width: unset
					width: 100%
					flex-shrink: 1
			> :nth-child(5),
			> :nth-child(6),
			> :nth-child(7)
				.list__fake__item
					max-width: unset
					width: 100%
					flex-shrink: 1
			> :nth-child(8)
				.list__fake__item
					max-width: unset
					width: 30%
					flex-shrink: 1
					margin: auto

			> :nth-child(9),
			> :nth-child(10)
				.list__fake__item
					+sq($fakeHeight - .6)
					+br()
					margin: auto

		&--searches
			> :nth-child(2)
				min-width: 10rem
				.list__fake__item
					max-width: 26rem
					width: 100%
					height: $fakeHeight
					flex-shrink: 1
			> :nth-child(3),
			> :nth-child(4),
			> :nth-child(5),
			> :nth-child(6),
			> :nth-child(7),
			> :nth-child(8),
			> :nth-child(9),
			> :nth-child(10)
				.list__fake__item
					+sq($fakeHeight)
					+br()
					margin-left: 4rem

			&:nth-child(2),
			&:nth-child(6),
			&:nth-child(10),
			&:nth-child(14),
			&:nth-child(19)
				> :nth-child(2)
					.list__fake__item
						max-width: 40rem
			&:nth-child(3),
			&:nth-child(8),
			&:nth-child(12),
			&:nth-child(17),
			&:nth-child(15)
				> :nth-child(2)
					.list__fake__item
						max-width: 60rem
			&:nth-child(4),
			&:nth-child(7),
			&:nth-child(13),
			&:nth-child(16),
			&:nth-child(18),
			&:nth-child(21)
				> :nth-child(2)
					.list__fake__item
						max-width: 30rem
		&--connections
			> :nth-child(2)
				+flex()
				.list__fake__item
					&:first-child
						+sq(5.1)
						+br()
						flex-shrink: 0
						margin-right: 1.7rem
					&:nth-child(2)
						max-width: 26rem
						width: 100%
						height: $fakeHeight
			> :nth-child(3)
				+mbi(.6)
				width: 9rem
				flex-shrink: 0
				.list__fake__item
					+br()
					+sq($fakeHeight)
					flex-shrink: 0
					margin-left: 1rem
			> :nth-child(4)
				.list__fake__item
					height: $fakeHeight
					width: 18rem
			> :nth-child(5),
			> :nth-child(6)
				.list__fake__item
					+br()
					+sq($fakeHeight)
					margin: auto

			&:nth-child(2),
			&:nth-child(6),
			&:nth-child(10),
			&:nth-child(14),
			&:nth-child(19)
				> :nth-child(2)
					.list__fake__item
						&:nth-child(2)
							max-width: 40rem
				> :nth-child(4)
					.list__fake__item
						width: 11rem
			&:nth-child(3),
			&:nth-child(8),
			&:nth-child(12),
			&:nth-child(17),
			&:nth-child(15)
				> :nth-child(2)
					.list__fake__item
						&:nth-child(2)
							max-width: 20rem
				> :nth-child(4)
					.list__fake__item
						width: 14rem
			&:nth-child(4),
			&:nth-child(7),
			&:nth-child(13),
			&:nth-child(16),
			&:nth-child(18),
			&:nth-child(21)
				> :nth-child(2)
					.list__fake__item
						&:nth-child(2)
							max-width: 32rem
				> :nth-child(4)
					.list__fake__item
						width: 15rem
		&--admin
			padding: 0
			background-color: $white
			> :first-child
				+flexAxis()
				width: 2rem
			> :nth-child(2)
				+mbi(1.7)
				+flex()
				.list__fake__item
					&:first-child
						+sq(5.1)
						+br()
						flex-shrink: 0
					&:nth-child(2)
						max-width: 26rem
						width: 100%
						height: $fakeHeight
			> :nth-child(3),
			> :nth-child(4),
			> :nth-child(5),
			> :nth-child(6),
			> :nth-child(7),
			> :nth-child(8)
				.list__fake__item
					+br()
					height: $fakeHeight
					width: 60%
					flex-shrink: 0
			.list__col--fill
				width: 90%
			.list__col--email
				max-width: 28rem

.content__sidebar
	.list__scroll-to-top
		top: auto
		bottom: 1.6rem
		right: 1.6rem

.list--actions-on-hover
	.list__col--hidden-action
		> .dropdown
			visibility: hidden
		> .popover__child
			visibility: hidden
		> .btn
			visibility: hidden
.iconed-col,
.list__item--highlighted-item
	.list__col--fill
		+mbi(1)
		+flex()
	span
		white-space: nowrap
		overflow: hidden
		text-overflow: ellipsis
