body
	.rc-slider
		&-disabled
			background-color: transparent !important
		&-track,
		&-tracks
			background-color: $black
		&-rail
			background-color: $white
		&-dot-active
			background-color: $white
			border-color: $black
		&-mark
			top: 2.5rem
			&-text
				color: $black
				font-size: 1.6rem
				&.rc-slider-mark-text-active
					font-weight: 700 !important
					color: $black
		&-dot
			background-color: $black
			border-color: $black
		&-handle
			+trans()
			background-color: $black
			border-color: $black
			opacity: 1
			&:hover
				border-color: $white
				background-color: $black !important
			&.rc-slider-handle-dragging
				box-shadow: none !important
				border: 0 !important

		&-disabled
			background-color: #fff !important
			.rc-slider-handle,
			.rc-slider-dot
				border-color: #000 !important
				background-color: #000 !important
			.rc-slider-track
				background-color: #000 !important
