.welcome-message
	text-align: center
	color: $black
	.modal__header
		padding: 4rem 2rem 2rem 2rem
		.title
			font-size: 3rem
	.flash
		+bold()
		display: inline-flex
		text-align: center
		margin: auto
		margin-bottom: 20px
		font-size: 1.6rem
	&__image
		+flexAxis()
		margin-bottom: 2rem
		img
			margin: 2rem auto 2rem auto
			max-width: 24rem
	&__main
		+bold()
		margin-bottom: 2rem
		font-size: 2rem
		line-height: 1.3
		width: 70%
		margin: 0 auto 2rem auto
	&__secondary
		font-size: 1.7rem
		line-height: 1.3
		margin: 0 auto 3rem auto
	&__count
		font-size: 1.4rem
		margin-bottom: 3rem
	&__link
		width: 80%
		font-size: 1.6rem
		margin: 4rem auto 0 auto
		line-height: 1.3
	.modal
		&__footer,
		&__header
			justify-content: center
		&__footer
			.btn
				margin-bottom: 3rem
	&.appsumo-review
		.modal__header
			.title
				+br(3)
				font-size: 1.7rem
				font-weight: 400
				border: .1rem solid #ffb903
				padding: .4rem 2rem
				background-color: #fff2d1
		.welcome-message
			&__main
				font-size: 3rem
				width: 85%
			&__secondary
				width: 85%
		.btn--primary
			+bold()
			background-color: #ffb903 !important
			color: #000
			text-shadow: unset
.welcome-campaigns
	.modal__header
		justify-content: center
		.title
			font-size: 3rem
			line-height: 1.2
			max-width: 40rem
			text-align: center
	&__image
		+flexAxis()

		margin-bottom: 3rem
	&__message
		+mbib(3)
		+bold()
		margin: auto
		max-width: 50rem
		text-align: center
		font-size: 1.6rem
		line-height: 1.4
		.warning
			+br()
			padding: 1rem 2rem
			background-color: $pink
			color: $white
	&__actions
		+flex()
		margin-top: 2rem
		justify-content: center
		width: 100%
		gap: 3rem
