@mixin title()
	+flex()
	height: 6.4rem
	border-bottom: .1rem solid $border
	padding: 0 2rem
	color: $black
	flex-shrink: 0
.campaigns
	.content__sidebar__body
		overflow-y: auto
		&__section__header
			.title
				+flex()
				gap: 1rem
.campaign
	position: relative
	.lead-picker
		flex-grow: 1
		.react-select__option
			min-height: 3.8rem
			height: auto
	&__upgrade
		margin-top: 1rem
		.btn
			&__label
				line-height: 1.3
	&__recipients
		&__leads
			display: flex
			&__info
				width: 100%
				min-width: 0
				padding: 0.5rem 0
				height: 100%
		&__avatar
			margin-right: 0
			flex-shrink: 0
			&__img
				+avatar(2.3, 0, 0)
				flex-shrink: 0
	&__processing
		+flex()
		+bold()
		+br()
		height: 3.8rem
		padding: .4rem 1.2rem
		gap: 1rem
		white-space: nowrap
		background-color: $processing
		color: $black
		.icon
			stroke: $black !important
	&__overview
		+flex()
		font-size: 1.4rem
		height: 100%
		.popover__child,
		.popover__tooltip__cursor
			height: 100%
		&__item
			+flex()
			gap: 2rem
			min-width: 7rem
			height: 100%
			border-right: .1rem solid $border
			padding: 0 2.6rem
			color: $black
			strong
				font-size: 1.8rem
	&__status
		+flexAxis()
		+sq(4.2)
		+br(10)
		margin-left: 2rem
		color: $black
		box-shadow: inset 0 0 .6rem $black10
		.icon
			stroke: $black !important
		&--processing,
		&--stby,
		&--queued,
		&--stby
			background-color: $processing
		&--pause
			background-color: $yellow
		&--finished
			background-color: #88ff39
		&--archived
			background-color: $dark
			.icon
				stroke: $white !important
		&--draft
			background-color: $blue
			.icon
				stroke: $white !important

	.content__sidebar__body__section__header
		.title
			+flex()
	&__btn
		width: 7rem
		min-width: 5rem
		height: 100%
		&--bordered
			+flexAxis()
			border-right: .1rem solid $border
	&__empty
		display: block
		font-size: $txtSmall
		font-style: italic
		color: $black40
	&__header
		+flex()
		width: 100%
		min-height: $listHeight
		flex-shrink: 0
		border-bottom: .1rem solid $border
		&.home
			overflow-x: auto
			padding-right: 0
		&__actions
			+flex()
			height: 100%
			gap: 1.6rem
			margin-left: auto
			border-left: .1rem solid $border
			> :first-child
				margin-left: 1.6rem
			> :last-child
				margin-right: 1.6rem
		&__title
			+flex()
			flex-grow: 1
			height: 100%
			padding-left: 3rem
			min-width: 30rem
			padding-right: 3rem
			.input
				&__item
					+bold()
					font-size: 1.8rem !important
					color: $black
					border: 0 !important
					padding: 0 !important
			&__actions
				+flex()
				gap: 1.6rem
				margin-left: auto
	&__subheader
		+flex()
		width: 100%
		min-height: $listHeight - 1
		flex-shrink: 0
		border-bottom: .1rem solid $border
		padding-right: 1.6rem
	&__sequences
		+flexCol()
		width: 100%
		height: 100%
		max-width: 32rem
		border-right: .1rem solid $border
		&-wrapper
			height: 100%
			overflow-y: auto
		.title
			+title()
			+flex()
			gap: 1rem
		.sequence
			+flexSpread()
			+bold()
			+trans()
			flex-shrink: 0
			width: 100%
			height: 5.5rem
			padding: 0 2.7rem
			color: $black
			border-bottom: .1rem solid $border
			&:hover
				background-color: $blue6
			.icon:not(.icon--fill)
				stroke-width: .2rem
			&.incomplete
				background-color: #ff00000d
			&.complete
				background-color: #0eceab10
			&.active
				background-color: $blue10
			&-title
				+flex()
				gap: 2rem
			&-count
				+br()
				background-color: #c2ecff
				color: $black
				margin-left: auto
				font-size: 1.2rem
				padding: .2rem .7rem
				line-height: 1.4

	&__config
		+flexCol()
		width: 100%
		height: 100%
		background-color: $black2
		.title
			+flexSpread()
			+title()
			border-color: $dark10
			padding: 0 3rem
		.recipients-info
			bold
			padding: 1.6rem 3rem
			min-height: 5rem
			font-size: 1.4rem
			flex-shrink: 1
			color: $black
			background-color: #c2ecff
			border-bottom: .1rem solid $border
		&-wrapper
			height: 100%
			overflow-y: auto
			.pipeline &
				width: 100%
				display: flex
				align-items: flex-start
				gap: 1.9rem
				background-color: $white
				overflow-y: auto
				overflow-x: auto
				padding: 3rem
			.dropdown:not(.templates-link)
				> .btn
					background-color: $white
					&:disabled
						color: $black !important
					.icon
						+sq(1.8)
						stroke-width: 2.5
						margin-left: auto
				.dropdown__menu
					width: 100%
				&:not(.small)
					min-width: 22rem
				&.small
					.dropdown__menu
						min-width: unset
			.react-select
				&__placeholder
					color: $black !important
					font-size: 1.3rem !important
			.select,
			.asyncselect,
			.react-select
				&__placeholder,
				&__indicator
					+trans()
				&__control
					&:not(.select__control--is-focused):not(.asyncselect__control--is-focused)
						transition: .3s all ease-out !important
						background-color: #fff
						min-height: 3.6rem !important
						height: auto !important
						min-width: 22rem
						border: .1rem solid $black20 !important
						&:hover
							border-color: $blue !important
							.select,
							.asyncselect,
							.react-select
								&__indicator
									svg
										color: $blue
								&__placeholder,
								&__single-value
									color: $blue !important

				&__single-value
					color: $black !important
				&__option
					height: 3.8rem
					cursor: pointer
					&--is-selected
						color: $black !important
						&.select__option,
						&.asyncselect__option,
						&.react-select__option
							&--is-focused
								background-color: $blue30 !important
								color: $black !important
								cursor: default
				&__indicator
					&-separator
						display: none
					svg
						color: $dark
						stroke-width: 0.1rem
						stroke: #fff
			.column
				.input
					max-width: unset !important
					width: 100%
		.react-select
			&--is-disabled
				opacity: .5
				.react-select__single-value
					color: $dark !important
					&.react-select__single-value--is-disabled
						color: $black !important

	.sequence-config
		position: relative
	.seq-activated
		+flex()
		+br(2)
		gap: 1rem
		font-size: 1.3rem
		border: .1rem solid $green
		padding: .2rem 2.6rem .2rem 1.6rem
		color: $green
		background-color: $white
		.icon
			stroke-width: .2rem
			stroke: $green

	.autom
		+trans()
		padding: 2rem 3rem
		border-bottom: .1rem solid $black6
		color: $black
		.main-img
			max-width: 80rem
			margin-bottom: 3rem
		&-line
			+flex()
			+bold()
			+trans()
			gap: 1.6rem
			flex-wrap: wrap
			flex-shrink: 1
			min-height: 2.6rem
			position: relative
			font-size: 1.5rem
			&:not(:only-child)
				margin-bottom: 1.6rem
			&.bulleted
				.btn
					font-size: 1.3rem
			&.column
				flex-direction: column
				align-items: flex-start
				>
					flex-grow: 1
			.spacer
				+sq(.3)
			.bullet
				+sq(.3)
				+br(.14)
				+trans()
				display: flex
				background-color: $black30
				flex-shrink: 0
				transform: rotate(45Deg)
		&-step
			+flexAxis()
			+br(10)
			+sq(1.6)
			+trans()
			flex-shrink: 0
			font-size: 1.6rem
			color: $white
			background-color: $black20
		&.activated
			.autom-step
				+sq(3.5)
			.step-blue
				background-color: $blue
			.step-green
				background-color: $green
			.step-red
				background-color: $step-red
			.step-purple
				background-color: $step-purple
			.bullet
				+sq(.8)
				background-color: $green
				&.bullet-blue
					background-color: $blue
				&.bullet-green
					background-color: $green
				&.bullet-red
					background-color: $step-red
				&.bullet-purple
					background-color: $step-purple

		&-desc
			+bold()
			font-size: 1.5rem
			ul
				margin: 3rem 0 3rem 3rem
				li
					list-style: disc outside
					margin-bottom: 1rem
					line-height: 1.4
		.switch__text
			font-size: 1.5rem
		.message
			width: 100%
			max-width: 100%
			.input__item[type=textarea]
				+regular()
				max-width: 100%
				resize: vertical
				min-height: 20rem
			&.invite
				.input__item[type=textarea]
					resize: none
					min-height: 13rem
		.templates__section__vars
			.btn
				background-color: $white
		.btn--link-primary,
		.btn--link
			font-weight: 400 !important
	&-stats
		.campaign__header
			padding: 0 3rem
	.input
		max-width: 22rem
		&__item
			+bold()
			color: $black !important
			border-color: $black20 !important
			&:hover:not(:disabled),
			&:focus
				border-color: $blue !important
	&__stats
		+fullwh()
		overflow-y: auto
		padding: 3rem
		background-color: $black3
		.recharts-text
			&.recharts-label
				font-size: 1.8rem
				&.recharts-pie-label-text
					font-size: 1.4rem
					fill: $black
		&__donuts
			display: flex
			align-content: flex-start
			flex-wrap: wrap
			gap: 2rem
			g,
			text,
			path,
			svg,
			circle
				&:focus-visible,
				&:focus
					outline: none
			path
				stroke-width: 0
		.donut
			&__section
				padding: 4rem 0 0 0
				max-width: 40rem
				height: 46rem
				.recharts-text
					&.recharts-label
						font-size: 3rem
					&.recharts-pie-label-text
						font-size: 2rem
						fill: $black
	&-logs
		.campaign
			&__header
				padding: 0
				border: 0
				flex-direction: column
				align-items: flex-start
				flex-shrink: 0
				&__title
					+flex()
					width: 100%
					padding: 0
					min-height: 6.8rem
					border-bottom: .1rem solid $border
					> *
						+flex()
						padding: 0 3rem
						height: 100%
						border-right: .1rem solid $border
					.dropdown
						> .btn
							.btn__label
								font-weight: 700
			&__sub-header
				+flex()
				width: 100%
				padding: 0 3rem
				min-height: 5rem
				flex-shrink: 0
				background-color: $blue6
				color: $black
				border-bottom: .1rem solid $border
		.list__col
			line-height: 4rem
			.popover__child
				display: flex
				justify-content: flex-start

@media screen and (max-width: 1260px)
	.campaign
		&__header
			&__actions
				.btn
					+sq(3.6)
					justify-content: center
					padding: 0 !important
					&__label
						display: none
					.icon
						margin-right: 0 !important
@media screen and (min-width: 1261px)
	.campaign
		&__header
			&__actions
				.btn
					padding: 0 1.2rem !important
					.icon
						display: none
