.logout,
.delete
	+flexCol()
	align-items: center
	justify-content: center
	padding: 6rem
	gap: 4rem
	.login
		&__hero,
		&__hero--dark
			max-width: 40rem
			margin-bottom: 0
		&__logo
			margin-bottom: 3rem
		&__step
			&__body
				font-size: 1.6rem
				padding: 0
				.not-found,
				.par
					margin-bottom: 2rem
				.not-found
					font-size: 3rem
	.btn
		font-size: 1.6rem
