.dropzone
	+flexCol()
	align-items: center
	padding: 20px
	border-width: 2px
	border-radius: 2px
	border-color: #eeeeee
	border-style: dashed
	background-color: #fafafa
	color: #bdbdbd
	outline: none
	transition: border .24s ease-in-out
