.password-strength
	&__wrapper
		+flex()
		margin: .5rem 0 0
	&__item
		flex-basis: 0%
		flex-grow: 1
		position: relative
		max-width: 100%
		width: 100%
		height: .2rem
	&__space
		width: .4rem
	&__desc
		margin: .5rem 0 0
		color: #898792
		font-size: 1.4rem
