.file
	+br()
	+mbi(.8)
	+trans()
	display: inline-flex
	padding: 1rem 1.6rem 1rem 1.2rem
	background-color: $white
	color: $dark
	text-decoration: none
	border: .1rem solid $inputBorderColor
	cursor: pointer
	min-width: 22rem
	&__size
		+txtSmall()
		color: $light
	&:hover
		color: $blue
