.service-message
	+mbi()
	+flexSpread()
	position: relative
	z-index: 1060
	width: 100%
	min-height: 5rem
	padding: .8rem $padding + .6 .8rem $padding + .6
	right: 0
	left: 0
	color: $white
	background-color: $dark70
	&--success
		background-color: $green !important
	&--error
		background-color: $red !important
	&--warning
		background-color: $orange !important
	.btn
		.icon
			stroke: $white
		&__label
			+bold()
		&:hover
			.icon
				stroke: $green
	&--info
		background-color: $yellow !important
		color: $black
		border-bottom: .1rem solid $black8
		.service-message__text
			text-shadow: none
		.btn
			.icon
				stroke: $black
		a
			color: $black !important
			text-decoration: underline
	&__text
		+textShadow()
		font-size: $txt + .1
		line-height: 2rem
		pointer-events: auto
