.team
	&-options
		.settings__content
			padding: 0
			background-color: $white
			overflow: hidden
			display: flex
			flex-direction: column
		&__menu
			+flex()
			height: 5.8rem
			padding: 0 3rem
			flex-shrink: 0
			border-bottom: .1rem solid $border
			li
				+flex()
				height: 100%
				gap: 3rem
	&__header
		+flex()
		max-height: $listHeight
		height: 100%
		flex-shrink: 0
		border-bottom: .1rem solid $border
		> *
			+flex()
			height: 100%
			padding: 0 $boardPad
	&__list
		height: 100%
	&__seats
		+flex()
		height: 100%
		margin-left: auto
		padding: 0
		&:not(:last-child)
			border-left: .1rem solid $border
		> *
			+flex()
			height: 100%
			padding: 0 $boardPad

	&__seat
		+bold()
		color: $black
		border-left: .1rem solid $white20
		&.connect
			background-color: $subOne
		&.scrape
			background-color: $subTwo
		&.fusion
			background-color: $subThree
		&.not-available
			+flexCol()
			justify-content: center
			text-shadow: unset
			background-image: url(../../images/texture.png)
			.info
				+br()
				margin-top: .5rem
				font-size: .9rem
				background-color: $black60
				color: $white
				padding: .2rem .4rem

	&__list
		.list__col
			&--spacer
				width: 4rem
				flex-shrink: 0
	&__link
		+linkBox()
