@keyframes spin
	from
		transform: rotate(0deg)
		visibility: hidden
	to
		transform: rotate(360deg)
		visibility: visible
@keyframes hide
	from
		color: $white
	to
		color: $black
.donut
	+flexAxis()
	position: relative
	width: 19rem
	height: 19rem
	border-radius: 50%
	flex-shrink: 0
	visibility: hidden
	animation: spin .5s ease-out .3s forwards
	&:after
		+flexAxis()
		+bold()
		content: attr(data-value)
		position: absolute
		width: 100%
		height: 100%
		z-index: 2
		font-size: 2rem
		color: $white
		animation: hide .4s ease-out .8s forwards
	&:before
		content: ""
		display: flex
		width: 18rem
		height: 18rem
		border-radius: 50%
		background: $white
		position: relative
		z-index: 1
	&__section
		+flexCol()
		+br()
		border: .1rem solid $border
		align-items: center
		height: 35rem
		max-width: 32rem
		width: 100%
		flex-shrink: 0
		align-self: flex-start
		padding: 4rem
		background-color: $white
		.recharts
			&-label
				font-size: 2rem
				font-weight: 700

	&__legend
		font-size: $title4
		margin: 0 0 2rem 0
		line-height: 1.4
		text-align: center
		min-height: 6rem
@media screen and (max-width: 1274px)
	.donut
		&__section
			max-width: unset
			min-width: unset
			border-right: 0
