.lead-lists
	&__header
		+flex()
		min-height: $listHeight
		flex-shrink: 0
		border-bottom: .1rem solid $border
		> *
			+flex()
			height: 100%
			padding: 0 $boardPad
			&:not(:last-child)
				border-right: .1rem solid $border
		&__title
			+mbi(5)
		&__search
			flex-grow: 1
		&__select
			padding: 0
			.dropdown
				width: 100%
				height: 100%
				> .btn:not(.btn--primary)
					+bold()
					width: 100%
					height: 100%
					padding: 0 2rem 0 2.6rem

	&__header-info
		+flex()
		border-bottom: .1rem solid $border
		> *
			+flex()
			height: 100%
			padding: 0 $boardPad
			&:not(:last-child)
				border-right: .1rem solid $border
		.counter
			font-size: 1.4rem
		.flashcontainer
			margin-left: auto
			padding: 0
		.flash
			border-radius: 0
			border: 0
			border-left: .1rem solid $border
			height: 100%
			min-width: 0
			flex-shrink: 1
			margin-left: auto
	.list
		&__header
			padding-right: 0
		&__row
			padding: 0
		&__col
			width: 100%
			max-width: 10rem
			flex-shrink: 0
			text-align: center
			&.team-owner
				text-align: left
				max-width: 16rem
			.team-avatar
				gap: .8rem
				.avatar
					+avatar(2.6,0)
					+br(10)
			&--action
				max-width: 5rem
				.dropdown__menu.s
					right: 8rem
			&--fill
				text-align: left
				max-width: 100%
				flex-shrink: 1
				&.scrape-list
					+flex()
					width: 100%
					min-width: 22rem
				.scrape-list-name
					+ellipsis()
				.enriched-profile
					margin: 0 0 0 1rem
	.col-origin-icon
		+flexAxis()
		justify-content: flex-end
		max-width: 5rem
		overflow: visible !important
	.col-overflow
		overflow: visible !important
	.col-importing
		+flex()
		justify-content: center
		font-weight: bold
		color: $blue
		overflow: visible !important
		.tooltip
			+mbi(.8)
			width: 100%
		.import-count
			+ellipsis()
		.icon
			stroke-width: .2rem
	.col-importing,
	.col-importation,
	.col-imported
		max-width: 12rem
	.col-date
		max-width: 16rem
	.empty-img
		max-width: 40rem
