@function solid($rgba, $background: #fff)
	@return mix(rgb(red($rgba), green($rgba), blue($rgba)), $background, alpha($rgba) * 100%)
// background-color: solid($blue6,solid($black91))
@mixin liquid($color, $opacity: 0.16)
	background-color: rgba($color, $opacity)

$border: solid($black94)
$text: $white70
$light: $white70
$green-sf: #228202
$blue: #5cacff

@keyframes hidedark
	from
		color: $black
	to
		color: $white
@keyframes spindark
	from
		transform: rotate(0deg)
		visibility: hidden
	to
		transform: rotate(360deg)
		visibility: visible
@mixin disabled($b: #3474FF, $t: #fff)
	cursor: default
	opacity: .3
	box-shadow: none
	&:hover
		opacity: .3
		background-color: $b
		color: $t
		.icon
			stroke: $t
@mixin box()
	+br()
	box-shadow: 0 .3rem 2rem $black30
	border: .1rem solid solid($black90)

body.dark
	color: $white60
	background-color: solid($black90)
	.blue
		color: $blue !important
	h1,h2,h3,h4
		color: $white94
	h5
		color: $text
	input, select, textarea
		color: $white
	::-webkit-scrollbar
		background: solid($black90) !important
	::-webkit-scrollbar-thumb
		background: $white15 !important
	::-webkit-scrollbar-corner
		background: solid($black90) !important
	a
		&:not(.btn)
			color: $blue
	.enrich-process
		color: $blue
		.icon
			stroke: $blue
	.btn--link-red
		.icon
			stroke: $red !important
		&:hover
			color: $light !important
			.icon
				stroke: $light !important

	.input
		&__item
			&:not([type=button]):not([type=checkbox]):not([type=radio])::-webkit-input-placeholder
				color: $white60
			&:not([type=button]):not([type=checkbox]):not([type=radio])
				border-color: $white20
			&:not([type=button]):not([type=checkbox]):not([type=radio])
				background-color: solid($black84) !important
				color: $white !important
				&:hover:not(:disabled),
				&:focus
					border-color: $blue !important
					color: $blue !important
				&[type=textarea]
					+trans()
					&:hover,
					&:focus
						border-color: $white !important
						color: $white !important
						background-color: $blue10 !important
					&:not(:placeholder-shown)
						background-color: $blue10 !important

		&.input--iconed
			.icon
				stroke: $text
	.icon
		stroke: $text
		&.icon--blue,
		&.icon--processing
			stroke: $blue !important
		&.icon--green
			stroke: $green
		&.icon--red
			stroke: $red
	.chat
		&__avatar,
		&__message__read__avatar
			border: 0
	.input
		&--checkbox,
		&--radio
			.input
				&__item
					opacity: .2
					border: $border
					background-color: solid($black80) !important
					&:checked
						opacity: 1
	.dropdown
		&__menu
			+box()
			background-color: solid($black80)
			&__item
				&__title
					background-color: solid($black88)
					border-color: solid($black90)
		&__separator
			border-color: solid($black90)
	.popover
		&__content
			+box()
			overflow: hidden
			background-color: solid($black70)
			color: $white80
			> svg
				path
					fill: solid($black70)
			.labels-popover__wrapper
				background-color: #1a1a1a
			&.lane-actions
				background-color: solid($black84)
				> svg
					path
						fill: solid($black88) !important
	.btn
		&.btn--campaign
			border-color: solid($black98)
		&.btn--dropzone
			color: $blue
			border-color: $blue
			.btn__label
				+liquid($blue, .1)
		&--icon:not(.btn--link):not(.btn--link-info):not(.btn--sidebar-link)
			&.btn--bulk
				background-color: solid($black86) !important
				&:hover:not(.btn--loading):not(:disabled)
					background-color: $blue20 !important
					color: $white
					.icon
						stroke: $white
				&.new-folder
					background-color: unset !important
					&:hover:not(.btn--loading):not(:disabled)
						background-color: unset !important
		&--tab-switch
			background-color: $black
			color: $white
			&:disabled
				border-color: #3474FF
				background-color: #3474FF
			&:hover:not(:disabled)
				background-color: #054adf
				border-color: #054adf !important
		&--yellow,
		&--white,
		&--important,
		&--primary:not(.btn--processing)
			&:hover
				&:not(.btn--loading):not(:disabled)
					background-color: $green !important
					color: $black !important
					text-shadow: none !important
					.icon
						stroke: $black !important
		&--green-sf
			background-color: $green-sf
		&--yellow
			.icon
				stroke: $black
			&:disabled
				+disabled($yellow, $black)
		&--white
			background-color: $white10
			color: $white
			.icon
				stroke: $white
			&:disabled
				+disabled($white10, $white)
			&:hover
				&:not(.btn--loading):not(:disabled)
		&--sidebar-link
			border-color: solid($black94)
			.icon
				stroke: $white90
			&.active,
			&:hover
				background: $blue15
				.icon
					stroke: #3474FF
		&--secondary
			color: $white90
			.icon
				stroke: $white90
			&:hover
				.icon
					stroke: #3474FF
		&--secondary,
		&--template
			&.active,
			&:hover
				background-color: $blue15
				color: #3474FF
				.icon
					stroke: #3474FF
			&:disabled
				&:hover
					color: $text !important
					.icon
						stroke: $text !important

		&--toggle:not(.btn--link),
		&--secondary-dark,
		&--secondary-white,
		&--secondary-blue
			&:hover
				background-color: $blue
				color: $white
				.icon
					stroke: $white

		&--secondary-dark
			background-color: solid($black86)
			color: $white90
			.icon
				stroke: $white90
			&:disabled
				color: $text
				&:hover
					color: $text
					.icon
						stroke: $text
		&--secondary-blue
			background-color: $blue40
			color: $white
			&:hover
				background-color: $blue
		&--notification
			color: $white
			.icon
				stroke: $white
		&--dropdown-item
			color: $text
			&:hover,
			&.active
				background-color: $blue60
				color: $white
				.icon
					stroke: $white
			&:disabled
				+disabled(transparent,$white)
		&--dark-mode
			background-color: $white10
			border-color: $black98
			.icon
				stroke: $white
			&:hover
				background-color: $white
				.icon
					stroke: $black
		&--bulk
			&:hover
				color: $white
				.icon
					stroke: $white
		&--group
			.dropdown
				.btn--group-item
					background-color: solid($black82)
					&.btn--secondary-dark
						&:hover
							color: $white
							border-color: solid($black70)
							background-color: solid($black76)
							.icon
								stroke: $white
						&:disabled
							opacity: .3
							color: $text
							background-color: solid($black82)
							.icon
								stroke: $text
							&:hover
								color: $text
								.icon
									stroke: $text
			> :first-child,
			> :last-child
				.btn--group-item
					border-color: solid($black76)
					&:hover
						border-color: solid($black70)
		&--list-action
			background-color: solid($black91)
			&:hover
				color: $white
				.icon
					stroke: $white
			&:disabled
				background-color: solid($black91)
				color: $white
				.icon
					stroke: $white
				&:hover
					color: $white
					.icon
						stroke: $white
		&--tab
			background-color: solid($black86) !important
			border-color: solid($black98)
			color: $white60 !important
			&:hover
				background-color: $blue20 !important
				color: $white !important
			&.active
				background-color: $blue20 !important
				color: $white !important
			&.btn--tab--white
				color: $white70 !important
				&:hover
					color: $blue !important
					.icon
						stroke: $blue !important

		&--link,
		&--link-info,
		&--link-inline,
		&--link-primary
			color: $text
			.icon
				stroke: $text
			&:hover
				color: $white
				.icon
					stroke: $white
		&--transparent
			color: $white
			&:disabled
				color: $white
			&:hover:not(:disabled),
			&.active
				background-color: transparent
				color: $blue !important
				.icon
					stroke: $blue !important
		&--toggle
			&:not(.btn--link)
				&.btn--secondary-blue
					background-color: rgba(52, 116, 255, .4) !important
					color: $white
					&:hover
						background-color: $blue !important

		&--bordered
			border-color: $white40
			&:hover:not(:disabled)
				border-color: $blue !important
				color: $blue !important

	.label
		&:not(.label--iconed)
			color: $white
		&--selectable
			&.label--selected
				background-color: $blue15
	.labels
		&-popover
			&__body
				.label__name
					color: $white
				.btn--link
					.icon
					&:hover
						.icon
							stroke: $white
			&__actions
				background-color: $white4
				border-color: $black30
			&__footer
				.btn
					&--primary
						background-color: $white10
					&:hover
						background-color: $white20
		&__color
			border-color: #1a1a1a

	.avatar
		border-color: $white20
	.team-avatar,
	.list-owner
		.avatar
			border: 0 !important
	.tooltip
		&:before
			color: $blue90
		&:after
			color: $white
			background-color: $blue90
			box-shadow: 0 0 1.6rem $black
		&.tooltipped
			&-s,
			&-se,
			&-sw
				&:before
					border-bottom-color: $blue90
			&-n,
			&-ne,
			&-nw
				&:before
					border-top-color: $blue90
			&-w
				&:before
					border-left-color: $blue90
			&-e
				&:before
					border-right-color: $blue90

	.sidebar
		background-color: solid($black94)
	.content
		&__body
			background-color: solid($black91)
			&.affiliate
				background-color: solid($black84)
		&.team
			.content__body
				background-color: solid($black84)
		&__sidebar
			background-color: solid($black91)
			border-color: solid($black98)
			.input.input--filter
				.input__item
					background-color: transparent !important
			&__header__info__title,
			&__header__info__desc
				color: $text
			&__body
				&__section
					border-color: solid($black97)
					&.section-reduce
						.icon
							stroke: $white
						.title
							color: $white
						&.section-selected
							background-color: $blue20
					.title
						&:hover
							color: $white
					&__options__item
						background-color: solid($black84)
						border-color: solid($black98)
					&__search
						.input__item:not([type=button]):not([type=checkbox]):not([type=radio])
							background-color: transparent !important
							color: $white !important
							&:hover,
							&:focus
								color: $white !important
	.enriched-profile
		border-color: $blue !important
		.icon
			stroke: $blue !important
	.modal
		background-color: $black80
		&__content
			background-color: solid($black84)
	.list
		.splitted-list
			&__header__sticky
				background-color: solid($black88)
				box-shadow: 0 0 .4rem $black50
			&__left
				box-shadow: 0 0 .4rem $black50
			&__left,
			&__right
				.list__row:not(.list__row--leadnetwork ):not(.list__row--already-used)
					&:hover
						background-color: solid($black86) !important
		&--pattern
			&:before
				z-index: 0
				background-size: 40%
				background-image: url(../../images/chat-pattern-dark.png)
		&--even-odd
			.list__item:nth-child(even)
				.list__row:not(.list__row--red)
					background-color: solid($black88)
			.list.list--clickable
				.list__item:nth-child(even)
					.list__row:hover
						background-color: solid($blue15,solid($black86))
		&__header,
		&__filter
			border-color: solid($black98)
			background-color: solid($black88)
			.input__item:not([type=button]):not([type=checkbox]):not([type=radio])
				background-color: transparent !important
				color: $white !important
				&:hover,
				&:focus
					color: $white !important
			&__lead-count
				color: $white
				border-color: solid($black98)
			&--actions
				&__btn,
				.btn--processing
					border-color: solid($black98)
			&--legend
				background-color: unset
				border-color: solid($black98)
				.legend
					&-inbox
						background-color: #262619 !important
					&-duplicated
						background-color: #281b1b !important
		&__header
			background-color: solid($black89)
		&__row
			transition: borde-color 0s
			color: $light
			background-color: solid($black86)
			border-color: $border
		&.list--clickable
			.list__row
				&:hover
					background-color: solid($blue15,solid($black86))
		&__item
			&.archived--item
				.list__row
					background-image: url(../../images/archived-list-dark.png)
			&.unread
				.list__row
					background-color: solid($blue10,solid($black86))
				.connections__list__user__item *
					color: $white
			&.remind
				.list__row
					background-color: #2a3532
				&:hover
					.list__row
						background-color: #25302d
				.connections__list__user__item *
					color: $white
			&.sponsor-item
				.list__col--checkbox
					&:before
						background-color: #5b5b5b
		&__col
			&.black
				color: $white !important
			&.green
				color: $green !important
		&__fake
			background-color: solid($black86)
			border-color: $border
			&:before
				content: ''
				background: solid($black86)
				background: linear-gradient(90deg, $black0, solid($black86) 5%, $black0 10%)
				background-size: 300% 300%
			&--searches,
			&--connections
				background-color: solid($black86)
			.list__fake__item
				background-color: solid($black82)
		&__filter
			.input__item
				color: $white
	.bulk-actions
		&-modal__message__section p,
		&__selected-members__name
			color: $white90

		&-modal__message
			&.modal--full
				.bulk-actions-modal__message__section
					&:first-child
						border-color: $white6
						.input__item
							background-color: solid($blue10,solid($black94)) !important
					&:last-child
						background-color: $black30
						.templates__vars
							> .btn
								background-color: solid($black80)
								&:hover
									background-color: solid($black70)
									color: $white
									.icon
										stroke: $white
	.templates
		&__header
			background-color: solid($black87)
			border-color: solid($black98)
			.input__item:not([type=button]):not([type=checkbox]):not([type=radio]):not([type=textarea])
				border-radius: 0 !important
				background-color: solid($black87) !important
				color: $white !important
		&__sections
			background-color: $white12
		&__section
			&:first-child
				border-color: $black
				background-color: solid($blue6,solid($black88))
				.templates__section
					&__vars
						> .btn
							background-color: solid($blue30,solid($black86))
					&__message
						.input__item:not([type=button]):not([type=checkbox]):not([type=radio])[type=textarea]
							background-color: solid($blue10,solid($black86)) !important
							&:hover,
							&:focus
								border-color: $white40 !important

			&:last-child
				background-color: $black30
				border-color: $black
				.templates__section__vars
					> .btn
						background-color: solid($black80)
						&:hover
							background-color: solid($black70)
							color: $white
							.icon
								stroke: $white
			&__message
				.input__item
					background-color: solid($black84) !important
				&-emojis
					border-color: solid($black98)
			&__attachments
				border-color: solid($black98)
			&__counter-char
				background-color: solid($black84)
				border-color: solid($black98)
		&__type,
		&__type-button,
		&__section__vars
			border-color: solid($black98)
			> .btn
				background-color: solid($black80)
				&:hover
					background-color: solid($black70)
					color: $white
					.icon
						stroke: $white
	.lead-lists
		&__header,
		&__header-info
			border-color: solid($black98)
			> *:not(:last-child)
				border-color: solid($black98)
			.counter
				color: $text
			.flash
				background-color: solid($blue20, $black82)
				border-color: $black
				color: $text
		&__header
			&__search
				.input__item:not([type=button]):not([type=checkbox]):not([type=radio])
					background-color: transparent !important
	.leads-in-list
		.not-provided
			color: $white50
		.not-found
			color: $white75
		.searching
			color: $processing
		.list__row
			.list__col
				color: $white
				&.enriched-email-col
					color: $blue !important
			&--leadnetwork,
			&--already-used
				background-color: #262619 !important
				&:hover
					background-color: #262619 !important
			&--duplicated
				background-color: #281b1b !important
				&:hover
					background-color: #281b1b !important
			&--leadnetwork,
			&--already-used,
			&--duplicated
				.list__col
					color: $white80
					.popover__tooltip__cursor
						.icon
							stroke: $white80
	.connections
		&__header
			border-color: solid($black98)
			&__actions
				border-color: solid($black98)
		.list
			&__col
				.enriched-profile
					border-color: $white80
					.icon
						stroke: $white80

				.card-notes
					.btn
						.icon
							stroke: $white !important
			&__filter--actions
				.select-indicator
					background-color: solid($black86)
					border-color: $black
				&__unread
					.dropdown > .btn
						background-color: $blue20
						color: $white
						border-color: $border
						.icon
							stroke: $white
				&__csv
					border-color: solid($black98)
					border-right: 0
					&:hover
						background-color: $blue30
				&__btn,
				&__btns
					border-color: $black
			&__item
				.btn--label,
				.btn.btn--board,
				.btn--board .btn,
				.btn--pin:not(.active)
					color: $white60
				&:hover
					.btn--group-item
						border-color: solid($black70) !important
		&__list
			&__user
				&__date,
				&__name
					color: #A7A7A7 // fix for emoji transparency istead using $light variable
				&__avatar
					border-color: $black
				.name
					color: $white85

			.sponsored-message
				color: $white60
			.sponsored
				color: $white !important
				background-color: transparent
				border: .1rem solid $white40
				letter-spacing: .05rem

	.leads
		&__filter
			border-color: solid($black98)
			&__header
				color: $text
			&__add__btn-group
				.dropdown
					border-color: solid($black70)
					background-color: solid($black76)
				> :nth-child(2):not(:last-child):not(.btn)
					border-color: solid($black70)
				.input__item
					border-radius: 0 !important
		.content
			&__sidebar
				&__body
					&--bordered
						border-color: solid($black98)
					&__section
						.title
							background-color: solid($black91)
							border-color: solid($black98)
						&__filters
							background-color: solid($black90)
							border-color: solid($black98)
						&__search
							border-color: solid($black98)
							background-color: solid($black88)
	.asynchronous
		&__header
			border-color: solid($black98)
			h1
				border-color: solid($black98)
	.react-datepicker
		border-color: $black
		background-color: solid($black91)
		&-popper[data-placement^=bottom]
			.react-datepicker__triangle
				fill: solid($blue10,solid($black86))
				color: solid($blue10,solid($black86))
				stroke: $black
		&__header
			background-color: solid($blue10,solid($black86))
			color: $white
		&__day-name,
		&__day,
		&__time-name
			color: $white
		&__year-read-view--down-arrow,
		&__month-read-view--down-arrow,
		&__month-year-read-view--down-arrow,
		&__navigation-icon::before
			border-color: $white
	.lnuser
		&__header
			border-color: solid($black98)
			.btn
				&.btn--bulk
					border-left: .1rem solid solid($black98)
					border-right: .1rem solid solid($black98)
		&--lead
			.lnuser
				&__sidebar
					&.left
						color: $text
				&__user
					&__industry,
					&__location,
					&__company,
					&__job
						color: $white90
			.box__value,
			.box__label
				color: $white90 !important
		&__reminder
			&__set
				background-color: #374e48
				color: $white
		&__user
			&__avatar
				border-color: $black50
			&__headline
				color: $white90
		&__company,
		&__user-position
			&__card
				background-color: $white10
				border-color: transparent
		&__note
			&__container
				background-color: rgba(253, 255,216,.26)
				border-color: $black
			&__display
				color: $white
			&__date
				color: $white80
		&__origin
			h4
				color: $text
			&__body
				.btn--secondary
					background-color: $white6
					border-color: $white15
					&:hover
						border-color: $white
						color: $white
						.icon
							stroke: $white
		&__sidebar
			&.left,
			&.right
				border-color: solid($black98)
			&__section
				&__separator
					border-color: solid($black98)
		&__body
			&__header
				border-color: solid($black98)
				&__actions
					&--left,
					&--right
						.lnuser__body__header__action
							border-color: solid($black98)
			&__chat
				background-image: url(../../images/chat-pattern-dark.png)
				background-color: solid($black87)
				&:before
					background-color: solid($black87)
				&__footer
					border-color: solid($black98)
				&__content__invitation
					h1
						color: $white40
	.chat
		background-color: #252f43
		&.me
			background-color: #1e2d31
		&__message
			color: $white90
	.file
		background: $black16
		border-color: $black20
		&__name
			color: $white
		&__size
			color: $white60
	.empty
		h1
			color: $white

	.icon--empty
		rect,
		circle,
		path
			stroke: $white60 !important
			fill: solid($black88) !important
		polygon
			fill: solid($black91) !important
		polyline,
		line
			stroke: $white60 !important
	.publication
		&__header
			background-color: solid($black90)
			border-color: solid($black98)
		.input__item
			background-color: solid($black90) !important
			color: $text !important
		&__attachment
			background-color: $blue70
			color: $white
			border-color: $black
	.box
		background-color: $white10
		border-color: transparent
	.board
		background-color: solid($black90)
		&__campaig-processing
			.icon
				stroke: $black !important
		&__header
			border-color: solid($black98)
			.react-select__control
				background-color: solid($black90) !important
			&__section
				border-color: solid($black98)
				.input
					&--filter
						.input__item:not([type=button]):not([type=checkbox]):not([type=radio])
							background-color: transparent !important
							color: $white !important
				.react-select
					&__control:not(.select__control--is-focused):not(.asyncselect__control--is-focused)
						background-color: transparent !important

		.content
			&__sidebar
				&__header
					.btn
						&.menu
							background-color: transparent !important
				&--large
					&.badges
						background-color: solid($black90)
						.btn.board__sidebar__badge
							background-color: solid($black96) !important
							border-color: $white40 !important
							&:hover,
							&.active
								color: $blue !important
								background-color: $blue30 !important
								border-color: $blue !important

	.content
		.react-trello-board
			background-color: solid($black80)
			> div:last-child > :nth-child(1)
				background-color: solid($black90)
				button:not(.btn--link):not(.btn--primary)
					background-color: solid($black93)
					color: $text
					&:hover
						color: $white

		.react-trello-lane
			background-color: solid($black93)
			&__header
				.icon.icon--load
					fill: white !important
			&__new
				background-color: solid($black84)
				.input
					&__item
						box-shadow: 0 0 0 .1rem solid($black84)
						line-height: 3
						color: $white !important
						&:focus,
						&:hover
							box-shadow: 0 0 0 .2rem $blue
						&::selection
							background-color: $blue20
							color: $white90 !important
			&__title
				color: $white
				&:hover
					background-color: solid($black84)
					color: $white90
					border-color: $white10
		.react-trello-card
			background-color: solid($black84)
			border-color: $black70
			&:hover
				background-color: $blue20
				box-shadow: inset 0 0 0 0.1rem solid($black98)
			.card
				&__member
					&__avatar
						border-color: $black40
					&__name
						color: $text
				&__headline
					color: $white50
				&__message
					color: $white80
	.seq-activated
		background-color: transparent
	.switch
		&__text
			color: $white
		&__inner:after
			background-color: $white20
			box-shadow: inset 0 0 .15rem $white
			+br(1)
		&__checkbox:disabled + .switch__label .switch__controller
			background-color: $white40

	.subscribe
		&__name
			color: $white
		&__plan-item
			&:hover
				.plans-limits li .icon
					stroke: $white
	.modal.subscribe
		.billing-switcher
			color: $white
		.rc-slider
			&-mark-text
				color: $white
				&-handle
					background-color: $white !important
					border-color: $white !important
					&.rc-slider-handle-dragging
						background-color: $white !important
			&-dot
				border-color: $white
				background-color: $white
			&-rail
				background-color: $white15 !important
			&-track
				background-color: $blue !important
			&-handle
				background-color: $white !important
				border-color: $white !important
	.subscribe__plans__tab
		border-color: $black
		> .subscribe__plan-item
			&:nth-child(1)
				background-color: #fffa6d
			&:nth-child(2)
				background-color: #5cedfe
			&:nth-child(3)
				background-color: #6effb1
			&:nth-child(4)
				background-color: #b4a5ff
			&:hover:not(.active)
				color: $white
				background-color: #054adf !important
	.pricing-wrapper
		color: $black
		h4
			color: $black
		> :nth-child(1)
			background-color: #fffa6d
			.plans-price-wrapper
				border: 0
		> :nth-child(2)
			background-color: #5cedfe
			.plans-price-wrapper
				border: 0
		> :nth-child(3)
			background-color: #6effb1
			.plans-price-wrapper
				border: 0
		> :nth-child(4)
			background-color: #b4a5ff
			.plans-price-wrapper
				border: 0
	.plans
		&-yearly-details
			.normal-price
				color: $white
		&-limits li .icon
			stroke: $black
		&-price-wrapper
			color: $white
			background-color: $black80
	.most-popular
		background-color: #3474FF
	.pricing-cta .btn:not(:hover)
		background-color: #3474FF
	.subscription
		&__info
			color: $text
			strong
				color: $white
		&--manage
			background-color: solid($black91)
			border-color: solid($black98)
		&__packs
			background-color: solid($black91)
			border-color: solid($black98)
		&--choose
			background-color: solid($black90)
			.billing-switcher
				color: $white

			.subscription
				color: $white
				&__content
					background: transparent
				&-cat
					color: $white
					background-color: solid($black88)
					&-features
						background-color: solid($black84)
			.btn--white
				background-color: $white30
				color: $white
			.flash--red
				background-color: $red
		&--manage
			.subscription__packs
				.packs-container
					.pack
						background-color: solid($black84)
						color: $white90
						border-color: solid($black98)
						&-name
							color: $white
						&-title
							background-color: $subTwoDark
							color: $white
						&-price
							border: .1rem solid $white10
							color: $white
						&--one
							background-color: solid($black84)
						&-icon
							.icon
								stroke: $text
						.btn
							&:hover
								background-color: $green !important
						&:hover
							background-color: $blue20
		&--program
			.content__body
				.subscription
					&__content
						background-color: transparent
			.appsumo
				&-img
					&-white
						display: none
					&-dark
						display: block
	.account,
	.settings
		&__left
			background-color: solid($black90)
			border-color: solid($black98)
		&__right
			background-color: solid($black84)
			border-color: solid($black98)
		&__card
			background-color: solid($black93)
			border-color: $black
		&__name
			color: $white90
		&__detail
			color: $white80
		&__avatar
			border-color: solid($black98)
		&__cover
			img
				border-color: solid($black98)
	.settings
		&__content
			background-color: solid($black87)
		&__header
			border-color: solid($black98)
	.timezone
		&__week-days
			background-color: solid($black86)
			border-color: $white10
	.webhook
		&__header
			border-color: solid($black98)
		&__list-container
			background-color: solid($black86)
		&__item
			background-color: solid($black90)
			border-color: $black
		&__name
			color: $white
		&__url
			border-color: $black
			background-color: solid($black94)
			color: $white
		&__trigger
			&__item
				background-color: $white6
				border-color: $black
				color: $white
				.icon
					stroke: $white
	.quotas
		&__item
			background-color: solid($black90)
			border-color: solid($black98)
			.rc-slider
				&-mark-text
					color: $white
				&-rail
					background-color: solid($black70)
				&-dot
					background-color: $white
					border-color: $white
				&-handle
					background-color: $white !important
					border-color: $white !important
					&.rc-slider-handle-dragging
						background-color: $white !important
				&-disabled
					background-color: transparent !important
					.rc-slider
						&-dot
							background-color: $white !important
							border-color: $white !important
						&-track
							background-color: rgb(52, 116, 255) !important
			&--warning
				.rc-slider-disabled
					.rc-slider
						&-track
							background-color: $red !important
	.donut
		background-color: solid($black) !important
		animation: spindark .5s ease-out .3s forwards
		&__section
			background-color: solid($black88)
			border-color: solid($black)
		&__desc
			color: $white90
		&:before
			background-color: solid($black80)
		&:after
			animation: hidedark .4s ease-out .8s forwards
			color: solid($black92)
	.account
		&__seat
			color: #fff
		&__appsumo
			color: $white
			.appsumo-img-white
				display: none
			.appsumo-img-dark
				display: block
			&__changes
				background-color: $white10
		&__features
			background-color: solid($black93)
			border-color: solid($black98)
			.plans-limits
				li
					color: $white
				.icon
					stroke: $white
		&__quotas
			border-color: solid($black98)
			&__info
				background-color: $white10
				color: $white90
		.add-on
			&__price
				background-color: $black15
			.icon--empty
				rect,
				circle,
				path
					stroke: $white60 !important
					fill: #282a37 !important
				polygon
					fill: #282a37 !important
				polyline,
				line
					stroke: $white60 !important

		&__pack,
		.add-on
			+liquid($packOne)
			border-color: solid($black98)
			p,
			h2.title
				color: $white
			.rc-slider
				&-rail
					background-color: $white30
				&-mark-text
					color: $white80
					&.rc-slider-mark-text-active
						color: $white
				&-track,
				&-tracks
					background-color: $white
				&-handle,
				&-dot
					background-color: $white
					border-color: $white

	.appsumo-review
		border-color: solid($black98)
		&__subtitle,
		&__message
			color: $white
	.affiliate
		&-options__menu
			li
				.btn
					background-color: solid($black86)
					color: $white
					.icon
						stroke: $white
					&:hover
						background-color: solid($black96)
						color: $blue
						.icon
							stroke: $blue
	.exclude-members
		&__item
			li
				background-color: solid($black88)
				border-color: $black
				color: $light
	.team
		.seats
			background-color: solid($black84)
			border-color: solid($black98)
			color: $white90
	.affiliate,
	.team
		&__header
			background-color: solid($black88)
			border-color: solid($black98)
			color: $white90
		&-options__menu
			border-color: solid($black98)
			li
				border-color: solid($black98)
		&__ad
			display: none
		&__link
			background-color: solid($black84)
			background-image: url(../../images/chat-pattern-dark.png)
			color: $white80
			&__container
				background-color: solid($black95)
				color: $white
		&__funnel
			background-color: solid($black84)
		&__funnel,
		dt
			color: $white90
		&__features
			&__item
				background-color: solid($black80)
				box-shadow: 0 0 30rem $black60
				h2
					color: $blue
				.icon
					rect,
					ellipse,
					circle,
					path,
					polygon,
					line
						stroke: $white90 !important
						fill: none !important
	.campaign
		.input__item:not([type=button]):not([type=checkbox]):not([type=radio])
			border-color: $black !important
		.autom-line
			.input__item:not([type=button]):not([type=checkbox]):not([type=radio])[type=textarea]
				background-color: solid($blue10,solid($black86)) !important
				border-color: $white20 !important
				&:hover,
				&:focus
					border-color: $white40 !important

		&-logs
			.campaign__header__title
				> *
					border-color: solid($black98)
			.list__col
				&.black
					color: $white !important
		&__sub-header
			background-color: $blue15
			border-color: solid($black98)
			color: $white
		&__config-wrapper
			background-color: $black10
			.dropdown:not(.templates-link) > .btn
				background-color: solid($black84)
			.select,
			.asyncselect,
			.react-select
				&__indicator
					svg
						fill: $white80
						stroke: solid($black84)
						stroke-width: 0.05rem
				&__control
					&:not(.select__control--is-focused)
						&:hover
							border-color: $blue !important
							.select,
							.asyncselect,
							.react-select
								&__indicator
									svg
										fill: $blue !important
								&__placeholder
									color: $blue !important
								&__single-value
									color: $blue !important
			.column
				.input__item:not([type=button]):not([type=checkbox]):not([type=radio])
					background-color: $white15 !important
					border-color: solid($black98) !important
					&:hover,
					&:focus
						border-color: $blue !important
		&__empty
			color: $white60
		&__overview
			background-color: solid($black88)
			&__item
				background-color: solid($black88)
				border-color: solid($black98)
				color: $white
		&__status
			.icon.icon--processing
				stroke: $black !important
		&__stats
			.donut__section
				.recharts-text.recharts-pie-label-text
					fill: $white
		&__subheader
			border-color: solid($black98)
		&__header
			background-color: solid($black88)
			border-color: solid($black98)
			&__title,
			&__actions
				border-color: solid($black98)
			.input__item:not([type=button]):not([type=checkbox]):not([type=radio])
				background-color: transparent !important
				&:hover,
				&:focus
					color: $white !important
		&__btn--bordered
			border-color: solid($black98)
		&__config
			background-color: solid($black84)
			border-color: solid($black98)
			.title
				color: $white80
				border-color: solid($black98)
			&.pipeline
				.autom-line
					.input__item:not([type=button]):not([type=checkbox]):not([type=radio])
						background-color: $white15 !important
						border-color: solid($black98) !important
						&:hover,
						&:focus
							border-color: $blue !important
		&__sequences
			background-color: solid($black88)
			border-color: solid($black98)
			.title
				color: $white80
				border-color: solid($black98)
			.sequence
				color: $white80
				border-color: solid($black98)
				&.active
					color: $white
					background-color: $blue20

		.autom
			border-color: solid($black90)
			color: $white
			&-desc
				color: $white
			&-step
				background-color: $white20
			&-line
				.bullet
					background-color: $white20
			&.activated
				.bullet
					background-color: #3474ff
					&.bullet-blue
						background-color: #3474ff
					&.bullet-green
						background-color: $step-green
					&.bullet-red
						background-color: $step-red
					&.bullet-purple
						background-color: $step-purple
			.templates__section__vars
				.btn
					+trans()
					background-color: solid($black80)
					&:hover
						background-color: solid($black70)
						color: $white
						.icon
							stroke: $white
	.pipeline
		.column
			background-color: solid($black87)
		.move,
		.lbl
			background-color: solid($black78)
			color: $white
	.welcome-message
		&__image
			.icon
				circle,
				line
					stroke: $white90 !important
					fill: none !important
		&__main,
		&__secondary
			color: $white
		&.appsumo-review
			.title
				+liquid($yellow, 0.6)
				color: $white
	.progress
		border-color: solid($black98)
		&__start,
		&__end
			&-value
				background-color: solid($black70)
				border-color: $white10
				color: $text
		&__current-value
			&::after
				background-color: solid($black70)
				border-color: $white10
				color: $text
		&__full
			background-color: solid($black76)
		.black
			color: $text
	.fullwh.flex-axis
		background-color: solid($black84)
	.login
		background-color: solid($black88)
		&__left
			border-color: solid($black98)
			background-color: solid($black91)
		&__step,
		&__par__title
			color: $white90
		&__hero--dark,
		&__logo--dark
			display: block
		&__hero,
		&__logo
			display: none
	.flash
		color: $white90
		&--green
			+liquid($green)
			border-color: $black80
		&--blue
			+liquid($blue)
			border-color: $black80
		&--yellow
			+liquid($yellow)
			border-color: $black80
		&--orange
			+liquid($orange)
			border-color: $black80
		&--red
			+liquid($red)
			border-color: $black80
	.recharts-text
		&.recharts-label,
		&.recharts-pie-label-text
			fill: $white
	.skill
		color: $white
		background-color: $white10
	.gif-picker
		&-header
			background-color: solid($black84)
		&-content
			background-color: solid($black94)
	.select,
	.asyncselect,
	.react-select
		&__value-container
			background: transparent !important
		&__control
			background-color: solid($black84) !important
		&__menu
			background-color: solid($black88) !important
		&__input
			color: $white !important
			background-color: solid($black84) !important
		&__option
			color: $white !important
			background-color: solid($black89) !important
			&:hover
				background-color: solid($black84, $blue) !important

		&__single-value
			color: $white !important
		&__placeholder
			color: $white60 !important
		&__control
			&:not(.select__control--is-focused):not(.asyncselect__control--is-focused)
				background-color: solid($black84) !important
				border-color: $white40 !important
		&__multi-value
			&__label
				color: $white !important
			&__remove
				svg
					fill: $white !important
		&--is-selected
			color: $white80 !important
			&.select__option,
			&.asyncselect__option,
			&.react-select__option
				color: $white !important
				&--is-focused
					background-color: $blue50 !important
					color: $white !important
	.react-select
		&__placeholder
			color: $white !important
		&__menu
			background-color: #141414
	&.smooth-dnd-no-user-select
		.smooth-dnd-container
			&.vertical
				background-color: $black !important
