.account
	&__wrapper
		+fullwh()
		display: flex
	&__right,
	&__left
		height: 100%
		overflow-y: auto
	&__left
		background-color: #fafafa
		width: 100%
		h5.title
			color: $blue
		h1.title
			font-size: 3rem
			margin: 2rem 0
		&__box
			display: flex
			gap: 6rem
	h2.title
		font-size: 2.2rem
		margin-bottom: 2rem
	&__right
		border-right: .1rem solid $dark10
		width: 42rem
		flex-shrink: 0
		align-items: center
	&__current
		padding: 3rem
		flex-grow: 1
		.title
			display: flex
			justify-content: space-between
		&__actions
			display: flex
			gap: 1rem
	&__actions
		display: flex
		gap: 2rem
	&__features
		width: 100%
		gap: 1.6rem
		padding: 1.6rem 3rem
		background-color: #fafafa
		border-top: .1rem solid $border
		border-bottom: .1rem solid $border
		h4
			margin-bottom: 2rem
		.plans-limits
			margin-bottom: 2rem
	&__info
		+flex()
		gap: 4rem
		flex-wrap: wrap
	&__date
		+flex()
		gap: 2rem
		p
			font-size: 1.5rem
		&__box
			+quotaBox()
	&__appsumo
		+flexCol()
		align-items: center
		padding: 0 3rem 3rem 3rem
		color: $black
		h2.title
			font-size: 1.8rem
			margin-bottom: .5rem
		.appsumo-img-white,
		.appsumo-img-dark
			max-width: 30rem
		.appsumo-img-dark
			display: none
		&__top
			+flexCol()
			align-items: center
			width: 100%
			gap: 2rem
			margin-bottom: 6rem
			.title
				flex-grow: 1
				margin-bottom: 0
		&__bottom
			+flexCol()
			justify-content: center
			flex-wrap: wrap
			text-align: center
			gap: 3rem 0
		&__changes
			+br(2)
			font-size: 1.4rem
			padding: 1rem 2rem 2rem 2rem
			border: .1rem solid $red
			max-width: 60rem
			width: 100%
			ul
				padding: 1.6rem 2rem .6rem 2rem
				li
					list-style: disc inside
					margin-bottom: 1rem
					text-align: left
		&__plans-compair
			+flex()
			justify-content: center
			padding: 3rem 0 0 0

	&__seats
		display: flex
		align-items: center
		gap: 1rem
		flex-grow: 1
		&__wrapper
			gap: 1rem
			display: flex
	&__seat
		+br()
		+flexCol()
		gap: 1rem
		padding: 2.6rem
		color: $black
		min-width: 16rem
		text-align: center
		&.connect
			background-color: $subOne
		&.scrape
			background-color: $subTwo
		&.fusion
			background-color: $subThree
		&__title
			font-size: 1.6rem
			text-transform: uppercase
			letter-spacing: .1rem
		&__info
			text-transform: uppercase
			letter-spacing: .2rem
			font-size: 1rem
		&__count
			+bold()
			+br(10)
			align-self: center
			padding: 1rem 1.6rem
			background-color: $black
			color: $white
			font-size: 2rem
	.appsumo-review
		text-align: center
		color: $black
		padding: 3rem
		gap: 3rem
		width: 100%
		&__title
			font-size: 2.2rem
			margin-bottom: 1rem
		&__subtitle
			+bold()
			font-size: 1.6rem
			margin-bottom: 1rem
		&__message
			margin-bottom: 2rem
			font-size: 1.5rem
			line-height: 1.3
		&__image
			max-width: 15rem
			margin: 3rem auto
		.btn
			+bold()
			font-size: 1.5rem
			background-color: #ffba01
			color: $black
			text-shadow: none
	&__quotas
		padding: 3rem
		border-bottom: .1rem solid $border
		&__desc
			margin-bottom: 2rem
		&__info
			+quotaBox()
			background-color: #fff
			color: $black
			padding: 1.3rem 2rem
			font-size: 1.6rem
			text-align: center
			margin-bottom: 3rem
			border: .1rem solid $black10
		&__item
			+flexSpread()
			gap: 2rem
			&:not(:last-child)
				margin-bottom: 3rem
		&__box
			+quotaBox()
			padding: .4rem 1.6rem
			font-size: 1.4rem
	&__pack
		+flexCol()
		+br(2)
		text-align: left
		flex-shrink: 0
		padding: 3rem
		background-color: $white
		border: .1rem solid $black20
		max-width: 60rem
		width: 100%
		&--automations
			padding: 0
		.subtitle
			+quotaBox()
			background-color: $blue
			padding: .6rem 1.4rem
			margin-bottom: 2rem
		&__slider
			+flex()
			width: 100%
			flex-direction: column
			padding: 0 3rem 5rem 3rem
			position: relative
			min-height: 10rem
		&__amount
			+quotaBox()
			font-size: 2rem
			margin: 3rem 0
		.rc-slider
			&-rail
				background-color: $black15
			&-mark-text
				font-size: 1.3rem
		.btn
			+bold()
			margin: auto
	.for-team
		+br()
		+bold()
		+flex()
		background-color: $blue
		color: $white
		padding: .4rem .8rem
		text-transform: uppercase
		letter-spacing: .05rem
		font-size: .9rem
		height: 2.4rem
	.seats
		+flexSpread()
		+mbi()
		+br()
		padding: 1rem 2rem
		background-color: $white
		border: .1rem solid $border
		margin-bottom: 2rem !important
		&--counter
			+br()
			+bold()
			+flex()
			background-color: $dark
			color: $white
			padding: .4rem .8rem
	.add-on
		+flexCol()
		+br(2)
		text-align: left
		flex-shrink: 0
		background-color: $white
		border: .1rem solid $black20
		max-width: 60rem
		width: 100%
		margin-top: 3rem
		margin-left: 3rem
		margin-bottom: 10rem
		&__intro
			display: flex
			gap: 3rem
			padding: 3rem
		&__slider
			+flex()
			width: 100%
			flex-direction: column
			padding: 0 6rem
			position: relative
			min-height: 8rem
		.rc-slider
			&-rail
				background-color: $black15
			&-mark-text
				font-size: 1.3rem
		&__price
			+flexSpread()
			padding: 2rem 3rem
			border-top: .1rem solid $black6
			background-color: $black2

			&__switch
				+flex()
				gap: 1rem
			&__info
				display: flex
				align-items: flex-start
				gap: 1rem
				height: 5rem
			&__amount
				+bold()
				font-size: 3rem
				line-height: 3rem
			&__period
				text-transform: uppercase
				font-size: 1rem
