.empty
	+mbib(2)
	+flexAxis()
	flex-direction: column
	position: relative
	z-index: 1
	&--message
		width: 100%
		height: 100%
	h1
		font-size: $title
		text-align: center
		margin-bottom: 8rem
