.content
	display: flex
	flex-grow: 1
	height: 100%
	min-height: 0
	min-width: 0
	width: 100%
	&__body
		overflow: hidden
		display: flex
		flex-direction: column
		flex-grow: 1
	&__sidebar
		+flexCol()
		width: $sidebarContentWidth
		background-color: $contentSideBarColor
		flex-shrink: 0
		align-items: center
		overflow: hidden
		border-right: .1rem solid $border
		&--large
			width: $sidebarContentWidth + 6
		&--wide
			width: $sidebarContentWidth + 10
		&--huge
			width: $sidebarContentWidth + 20
		&__header
			width: 100%
			padding: 1.6rem $sidebarPadding 2.1rem $sidebarPadding
			&__title
				+flexSpread()
				&--inverse
					+mbi()
					justify-content: flex-start
					.btn--white
						&:hover
							background-color: $dark !important
			&__info
				width: 100%
				margin-top: 1.8rem
				&__title,
				&__desc
					+bold()
					line-height: 1.4
					color: $black
				&__title
					+mbi(1)
					+flex()
					font-size: $title2 + .1
					margin-bottom: 1.2rem
					span
						+ellipsis()
					.icon
						stroke: $black
						stroke-width: .13rem
				&__desc
					+flexSpread()
					+ellipsis()
					width: 100%
					font-size: $title5
					margin-bottom: .8rem
					min-height: 3.6rem
					.btn--processing
						border-radius: 0
						height: 2.4rem
						font-size: 1.2rem
						.icon
							+sq(1.6)
							stroke-width: .2rem
				&__meta
					margin-bottom: .8rem
				&__meta,
				&__link
					+flex()
					+mbi(1)
					font-size: $txt - .1
				&__link
					.icon
						stroke: $blue
					a
						+flex()
						+mbi(1)
						color: $blue
						cursor: pointer
						&:hover
							color: $dark
							.icon
								stroke: $dark

		&__body
			+fullwh()
			flex-grow: 1
			display: flex
			flex-direction: column
			width: 100%
			flex-grow: 1
			overflow: hidden
			&--bordered
				border-top: .1rem solid $border
			&__section
				padding: 1.6rem $sidebarPadding
				min-height: 5rem
				flex-shrink: 0
				border-bottom: .1rem solid $border
				overflow: hidden
				.title
					+trans()
				&.section-reduce
					animation: hideSection .4s ease
					animation-fill-mode: forwards
					.content__sidebar__body__section__filters
						visibility: hidden
					.title
						color: $black
					.expand-icon
						stroke: $black
					&.section-selected
						background-color: $blue6
						border-bottom: .1rem solid $black3
						.title
							+bold()
							color: $blue
				&.section-show
					animation: showSection .8s ease
					visibility: visible
					overflow: visible !important
					.title
						&:hover
							color: $black
				&--no-border
					border: 0
				&--scroll
					overflow-y: auto
					height: 100%
				&--special
					+flex()
					min-width: 0
					width: 100%
					padding: 1rem $sidebarPadding
					overflow: unset
				&--leads
					padding: 0
					.content__sidebar__body__section
						&__header-action,
						&__header
							padding: 1.6rem $sidebarPadding 0 $sidebarPadding
						&__filters
							padding: 0 $sidebarPadding .6rem $sidebarPadding
							&--leads
								padding: .6rem $sidebarPadding
					.content__sidebar__body__section__separator
						width: 80%
						margin: 0 auto
				.title
					margin-bottom: 1.1rem
					.btn
						&__label
							text-transform: initial
				&__search
					+flex()
					padding: 0 $sidebarPadding - .2
					border-bottom: .1rem solid $border
					height: 5.2rem
					flex-shrink: 0
					.input
						&.input--filter
							.input__item
								+bold()
								border: 0
								color: $black
				&__header-action
					+flexSpread()
					margin-bottom: 1.1rem
					.title
						margin: 0
					.btn
						+txtSmall()
						.icon
							stroke: $light
						&:hover
							.icon
								stroke: $blue
						&.unselect
							margin-left: auto
							margin-right: 1rem
					&:hover
						.btn
							visibility: visible
				&__options
					margin: 2rem 0
					&__item
						+flex()
						+mbi()
						+br()
						+bold()
						padding: 1rem
						border: .1rem solid $border
						background-color: $white
						.btn--mini
							+bold()
				&__filters
					width: 100%
					+mbib(.1)
					&--leads
						max-height: 16rem
						overflow-x: auto
					&__header-action
						+mbi()
						display: flex
						align-items: flex-start
						justify-content: space-between
						.btn
							.icon
								stroke: $light
							&:hover
								.icon
									stroke: $blue
						&:hover
							.btn
								visibility: visible
				&__separator
					+separator()

@keyframes hideSection
	0%
		max-height: 100%
		overflow: hidden
	100%
		max-height: 5rem
		overflow: hidden
@keyframes showSection
	0%
		max-height: 5rem
		overflow: hidden
	100%
		max-height: 100%
		overflow: auto

@media screen and (max-width: 1360px)
	.content
		&__sidebar
			&--huge
				width: $sidebarContentWidth + 10
@media screen and (max-width: 1024px)
	.content
		&__sidebar
			&--huge
				width: $sidebarContentWidth
