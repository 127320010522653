$blue: #3474FF
$primary: $blue
$blueLight: #84d8ff
$green: #00ac8d
$green-sf: #2f7b15
$greenLight: #a9d3c4
$red: #ff0055
$orange: #ff720c
$yellow: #ffc200
$yellowDark: #efb700
$yellowDarker: #d4a200
$pink: #FF004E
$pinkLight: #ffa4ee
$light: #9B9EB9
$lighter: #bcbfd5
$violet: #7f4aea
$violetLight: #916fd6
$kiwi: #b6dc3a
$grass: #61c63c

$processing: #79ffe6
$compose: $light
$sonata: #ffb87c // #ff7cac
$concerto: #6d9dff
$symphony: #75c7c6

$subOne: #7ef8b6
$subOneDark: #72e3a7
$subOneLight: #cdf0dd

$subTwo: #f6f161
$subTwoDark: #e4df58
$subTwoLight: #f1e9c6

$subThree: #88f8fb
$subThreeDark: #75dee1
$subThreeLight: #c7f1f2

$packOne: #b1c0ff

$brownLight: #d6a06f
$brownLighter: #fff9f1

$dark: rgb(72, 72, 82)
$dark90: rgba(72, 72, 82, .9)
$dark80: rgba(72, 72, 82, 0.8)
$dark70: rgba(72, 72, 82, .7)
$dark60: rgba(72, 72, 82, .6)
$dark50: rgba(72, 72, 82, .5)
$dark40: rgba(72, 72, 82, .4)
$dark35: rgba(72, 72, 82, .35)
$dark30: rgba(72, 72, 82, .3)
$dark25: rgba(72, 72, 82, .25)
$dark20: rgba(72, 72, 82, .2)
$dark15: rgba(72, 72, 82, .15)
$dark14: rgba(72, 72, 82, .14)
$dark13: rgba(72, 72, 82, .13)
$dark12: rgba(72, 72, 82, .12)
$dark11: rgba(72, 72, 82, .11)
$dark10: rgba(72, 72, 82, .1)
$dark9: rgba(72, 72, 82, .09)
$dark8: rgba(72, 72, 82, .08)
$dark7: rgba(72, 72, 82, .07)
$dark6: rgba(72, 72, 82, .06)
$dark5: rgba(72, 72, 82, .05)
$dark4: rgba(72, 72, 82, .04)
$dark3: rgba(72, 72, 82, .03)
$dark2: rgba(72, 72, 82, .02)
$dark1: rgba(72, 72, 82, .01)

$black: #000
$black99: rgba(0,0,0, .99)
$black98: rgba(0,0,0, .98)
$black97: rgba(0,0,0, .97)
$black96: rgba(0,0,0, .96)
$black95: rgba(0,0,0, .95)
$black94: rgba(0,0,0, .94)
$black93: rgba(0,0,0, .93)
$black92: rgba(0,0,0, .92)
$black91: rgba(0,0,0, .91)
$black90: rgba(0,0,0, .9)
$black89: rgba(0,0,0, .89)
$black88: rgba(0,0,0, .88)
$black87: rgba(0,0,0, .87)
$black86: rgba(0,0,0, .86)
$black85: rgba(0,0,0, .85)
$black84: rgba(0,0,0, .84)
$black83: rgba(0,0,0, .83)
$black82: rgba(0,0,0, .82)
$black81: rgba(0,0,0, .81)
$black80: rgba(0,0,0, .8)
$black79: rgba(0,0,0, .79)
$black78: rgba(0,0,0, .78)
$black77: rgba(0,0,0, .77)
$black76: rgba(0,0,0, .76)
$black75: rgba(0,0,0, .75)
$black74: rgba(0,0,0, .74)
$black73: rgba(0,0,0, .73)
$black72: rgba(0,0,0, .72)
$black71: rgba(0,0,0, .71)
$black70: rgba(0,0,0, .7)
$black60: rgba(0,0,0, .6)
$black50: rgba(0,0,0, .5)
$black40: rgba(0,0,0, .4)
$black35: rgba(0,0,0, .35)
$black30: rgba(0,0,0, .3)
$black25: rgba(0,0,0, .25)
$black20: rgba(0,0,0, .2)
$black19: rgba(0,0,0, .19)
$black18: rgba(0,0,0, .18)
$black17: rgba(0,0,0, .17)
$black16: rgba(0,0,0, .16)
$black15: rgba(0,0,0, .15)
$black14: rgba(0,0,0, .14)
$black13: rgba(0,0,0, .13)
$black12: rgba(0,0,0, .12)
$black11: rgba(0,0,0, .11)
$black10: rgba(0,0,0, .1)
$black9: rgba(0,0,0, .09)
$black8: rgba(0,0,0, .08)
$black7: rgba(0,0,0, .07)
$black6: rgba(0,0,0, .06)
$black5: rgba(0,0,0, .05)
$black4: rgba(0,0,0, .04)
$black3: rgba(0,0,0, .03)
$black2: rgba(0,0,0, .02)
$black1: rgba(0,0,0, .01)
$black0: rgba(0,0,0, 0)

$white: #fff
$white90: rgba(255,255,255, .9)
$white99: rgba(255,255,255, .99)
$white98: rgba(255,255,255, .98)
$white97: rgba(255,255,255, .97)
$white96: rgba(255,255,255, .96)
$white95: rgba(255,255,255, .95)
$white94: rgba(255,255,255, .94)
$white93: rgba(255,255,255, .93)
$white92: rgba(255,255,255, .92)
$white91: rgba(255,255,255, .91)
$white90: rgba(255,255,255, .9)
$white89: rgba(255,255,255, .89)
$white88: rgba(255,255,255, .88)
$white87: rgba(255,255,255, .87)
$white86: rgba(255,255,255, .86)
$white85: rgba(255,255,255, .85)
$white84: rgba(255,255,255, .84)
$white83: rgba(255,255,255, .83)
$white82: rgba(255,255,255, .82)
$white81: rgba(255,255,255, .81)
$white80: rgba(255,255,255, .8)
$white79: rgba(255,255,255, .79)
$white78: rgba(255,255,255, .78)
$white77: rgba(255,255,255, .77)
$white76: rgba(255,255,255, .76)
$white75: rgba(255,255,255, .75)
$white74: rgba(255,255,255, .74)
$white73: rgba(255,255,255, .73)
$white72: rgba(255,255,255, .72)
$white71: rgba(255,255,255, .71)
$white70: rgba(255,255,255, .7)
$white60: rgba(255,255,255, .6)
$white50: rgba(255,255,255, .5)
$white40: rgba(255,255,255, .4)
$white30: rgba(255,255,255, .3)
$white20: rgba(255,255,255, .2)
$white19: rgba(255,255,255, .19)
$white18: rgba(255,255,255, .18)
$white17: rgba(255,255,255, .17)
$white16: rgba(255,255,255, .16)
$white15: rgba(255,255,255, .15)
$white14: rgba(255,255,255, .14)
$white13: rgba(255,255,255, .13)
$white12: rgba(255,255,255, .12)
$white11: rgba(255,255,255, .11)
$white10: rgba(255,255,255, .1)
$white9: rgba(255,255,255, .09)
$white8: rgba(255,255,255, .08)
$white7: rgba(255,255,255, .07)
$white6: rgba(255,255,255, .06)
$white5: rgba(255,255,255, .05)
$white4: rgba(255,255,255, .04)
$white3: rgba(255,255,255, .03)
$white2: rgba(255,255,255, .02)
$white1: rgba(255,255,255, .01)
$white0: rgba(255,255,255, 0)

// Main colors

$blue: rgba(52,116,255, 1)
$blue90: rgba(52,116,255, .9)
$blue80: rgba(52,116,255, .8)
$blue70: rgba(52,116,255, .7)
$blue60: rgba(52,116,255, .6)
$blue50: rgba(52,116,255, .5)
$blue40: rgba(52,116,255, .4)
$blue30: rgba(52,116,255, .3)
$blue25: rgba(52,116,255, .25)
$blue20: rgba(52,116,255, .2)
$blue19: rgba(52,116,255, .19)
$blue18: rgba(52,116,255, .18)
$blue17: rgba(52,116,255, .17)
$blue16: rgba(52,116,255, .16)
$blue15: rgba(52,116,255, .15)
$blue14: rgba(52,116,255, .14)
$blue13: rgba(52,116,255, .13)
$blue12: rgba(52,116,255, .12)
$blue11: rgba(52,116,255, .11)
$blue10: rgba(52,116,255, .1)
$blue9: rgba(52,116,255, .09)
$blue8: rgba(52,116,255, .08)
$blue7: rgba(52,116,255, .07)
$blue6: rgba(52,116,255, .06)
$blue5: rgba(52,116,255, .05)
$blue4: rgba(52,116,255, .04)
$blue3: rgba(52,116,255, .03)
$blue2: rgba(52,116,255, .02)
$blue1: rgba(52,116,255, .01)
$blue0: rgba(52,116,255, 0)

$linkedin: #007ebb

$chatMessage: #d3f7ff
$chatMessageMe: #dfffe8
$sponsorMessage: #ffffff

//privileges

$premium: #bb8d4e
$openprofile: #0275b2
$opentowork: #2f7b15

//relationship

$relation: $blue
$contact: $yellowDark

$leadContact: $contact
$leadRelation: $relation

$sponsorRelation: $black60
$sponsorContact: #dadada
$sponsor: $black60

// Sidebar
$sidebar: #F0F0F0
$sidebarButton: transparent
$sidebarButtonActive: #E8E8E8

$contentSideBarColor: #F9F9F9

// Texts & Components
$text: $dark

$border: #EDEDED
$buttonHover: $dark

$laneColor: #F4F4F4
$cardColor: $white
$cardColorHover: #f5fffe
$cardBorderHover: #dcece7

$unread: #eff5ff
$unreadHover: #e9f1ff

$remind: #eefffd
$remindHover: #dffdfa

$modalBg: rgba(0, 11, 31, .85)

// Progress Bar
$progressGreen: $green
$progressOrange: $orange
$progressRed: $red

$flashRed: #FFDBDC
$flashRedDark: #FAB2B2
$flashGreen: #DBFCEB
$flashGreenDark: #B1E5CF
$flashBlue: #E3F3FF
$flashBlueDark: #AED3FF
$flashOrange: #FFE9D9
$flashOrangeDark: #FAD19C
$flashYellow: #FCF9DB
$flashYellowDark: #E5DF9B
$flashLight: #F9F9F9
$flashLightDark: #E0E0E0

// Switch
$switch-inactive: $dark40
$switch-disabled: $dark10
$switch-active: $green
$switch-focus: $blue

// Inputs
$inputBorderColor: #e5e5e5
$inputColor: $white
$placeHolderColor: $light

$listHover: #F3F7FF
$listHoverDark: #e5edff

// automations
$step-green: #6bb948
$step-red: #e65f5f
$step-purple: #725cd0
