@keyframes gradient
	0%
		background-position: 80% 0%
	100%
		background-position: -20% 0%
.panel
	position: absolute
	z-index: 10
	background: $white
	height: 100%
	width: $panelWidth
	top: 0
	box-shadow: 0 0 2rem $dark20
	display: flex
	flex-direction: column
	&--right
		right: 0
		border-left: .1rem solid $border
	&--left
		left: 0
		border-right: .1rem solid $border
	&__ellipsis
		display: flex
		flex-direction: column
		align-items: flex-start
		margin-right: 0 !important
		+ellipsis()
	&__header
		+flexSpread()
		padding: 1.6rem $panelpadding
		min-height: $panelHeaderHeight
		border-bottom: .1rem solid $border
		&__action
			margin-left: auto
		&__header-layout
			width: 0
		&__title
			+ellipsis()
			font-size: $title
			margin-bottom: 0
			flex-grow: 1
		&__sub-title
			color: $dark60
			line-height: 1 // to make the header border fit with the page header border on the background
		&__content
			+mbi(2)
			+flex()
			width: 0
		&__fake-action
			+sq()
			+br(50)
			background: $dark20
			background: linear-gradient(90deg, $dark20 20%, $dark30 30%, $dark20 40%)
			background-size: 400% 400%
			animation: gradient 1.4s ease-in-out infinite
	&__body
		+mbib(4)
		display: flex
		flex-direction: column
		align-items: flex-start
		height: 100%
		min-height: 0
		overflow-y: auto
		padding: $panelpadding 0
		&__title-container
			+mbi(2)
			+flex()
			width: 0
			flex-shrink: 0
			margin-bottom: 1rem
		&__title
			+ellipsis()
			font-size: $title
			margin-bottom: 0
			flex-grow: 1
		&__sub-title
			color: $dark60
			+regular()
		&__sections-container
			+mbib(3)
		&__section
			.select
				max-width: min-content
			&__title
				+regular()
				color: $dark60
				+txtSmall()
				line-height: 1.6rem
			&__list
				li
					&:not(.no-item)
						list-style: none
						padding-left: 1rem
						margin-left: 1rem
						&:before
							font-size: .8rem
							position: relative
							left: -1rem
							top: - .2rem
							content: "\2022"
							color: $dark60
		&__fake-content
			padding: 2rem
			width: 100%
			&__section
				+mbib(1)
				width: 100%
				margin-bottom: 4rem
				&:first-child
					.panel__fake-line
						&:first-child
							height: 2rem
							background: $dark20
							background: linear-gradient(90deg, $dark20 20%, $dark30 30%, $dark20 40%)
							background-size: 400% 400%
							animation: gradient 1.4s ease-in-out infinite
						&:last-child
							height: 1rem
	&__fake-line
		+br(10)
		background: $dark6
		background: linear-gradient(90deg, $dark6 20%, $dark10 30%, $dark6 40%)
		background-size: 400% 400%
		animation: gradient 1.4s ease-in-out infinite
		height: 1rem
		&:nth-child(even)
			background: $dark6
			background: linear-gradient(90deg, $dark10 20%, $dark20 30%, $dark10 40%)
			background-size: 400% 400%
			animation: gradient 1.4s ease-in-out infinite
			height: 1.2rem
