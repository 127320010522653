.login
	+fullwh()
	+flex()
	overflow: hidden
	background-color: #F7F7F7
	.bullet-container
		+flex()
		+mbi(1)
		position: relative
		top: -.7rem
	.bullet
		+br(5)
		+sq(.8)
		background-color: #767b94
		flex-shrink: 0
		&.current
			background-color: $blue
			&.error
				background-color: $red
		&.done
			background-color: $green
	.mobile
		display: none
		width: 100%
		@media screen and (max-width: 768px)
			display: flex
			flex-direction: column
			justify-content: center
		.login
			&__logo,
			&__logo--dark
				margin-bottom: 2rem
			&__par__title
				margin-bottom: 4rem
	&__left
		padding: 4rem
		max-width: 48rem
		height: 100%
		background-color: $white
		border-right: .1rem solid $border
		box-shadow: 0 0 5rem $black10
		overflow-y: auto
		@media screen and (max-width: 960px)
			padding: 4rem 2rem
			max-width: 36rem
		@media screen and (max-width: 768px)
			display: none
	&__right
		padding: 5rem 6rem
		&:not(.get-email)
			width: 65%
			height: 100%
			@media screen and (max-width: 960px)
				padding: 3rem 2rem
			@media screen and (max-width: 768px)
				width: 100%
				overflow-y: auto
		&.get-email
			@media screen and (min-width: 769px)
				display: flex
				flex-direction: column
				width: 100%
				height: 100%
				overflow-y: auto
				.login__step
					height: auto
					overflow-y: hidden
					&__body
						overflow-y: hidden
				.mobile
					.login
						&__logo,
						&__logo--dark
							margin-bottom: 2rem
						&__par__title
							margin-bottom: 4rem
	&__header
		margin-bottom: 4rem
	&__logo,
	&__logo--dark
		max-width: 16rem
		width: 100%
		margin: 0 auto 8rem auto
	&__hero,
	&__hero--dark
		margin-bottom: 4rem
	&__hero--dark,
	&__logo--dark
		display: none
	.par
		font-size: $txt + .4
		margin-bottom: 2rem
		width: 100%
		text-align: center
		line-height: 1.4
	&__par
		color: $blue
		font-size: $txt + .4
		line-height: 1.4
		text-align: center
		margin-bottom: 4rem
		&__title
			+bold()
			color: $black
			font-size: $txt + 1
			margin-bottom: 2rem
	&__form
		+flexCol()
		gap: 2rem
		max-width: 35rem
		margin: auto
		.par
			font-size: 1.6rem
			line-height: 1.4
			text-align: center
		.btn
			justify-content: center !important
	&__step
		+fullwh()
		+flexCol()
		justify-content: flex-start
		align-items: flex-start
		color: $black
		@media screen and (max-width: 768px)
			height: auto
		&__header
			+flexCol()
			+semibold()
			width: 100%
			align-items: center
			flex-shrink: 0
			font-size: 1.6rem
			color: $blue
			@media screen and (max-width: 768px)
				margin-bottom: 3rem
		&__label
			margin-right: auto
			@media screen and (max-width: 960px)
				margin-right: unset
				margin-bottom: 4rem
			.get-email &
				margin-right: unset
			&.success
				color: $green
		&__body
			+fullwh()
			display: flex
			justify-content: center
			align-items: center
			height: 80%
			padding: 4rem
			width: 100%
			flex-direction: column
			overflow-y: auto
			@media screen and (max-width: 860px)
				padding: 0
			@media screen and (max-width: 768px)
				overflow: hidden
				justify-content: flex-start
				height: auto
			.loader-wrapper
				margin-bottom: 6rem
			.not-found
				font-size: 2rem
				color: $red
				margin-bottom: 4rem
				font-weight: 400
			h1
				font-size: 3rem
				text-align: center
				line-height: 1.4
				margin-bottom: 4rem
		a
			+br(1)
			box-shadow: 0 0 1rem $dark10
			margin: 2rem
		.linkedin-sync
			margin-top: 6rem
			width: 26rem
			+flex()
			&-images
				max-width: 6rem
				width: 100%
				flex-shrink: 0
			.loader-bullets
				position: relative
				left: -15rem
