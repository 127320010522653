.splitted-list
	&__header
		+flex()
		width: 100%
		flex-shrink: 0
		position: sticky
		top: 0rem
		z-index: 11
		&__sticky
			position: sticky
			top: 0rem
			left: 0
			width: 30rem
			flex-shrink: 0
			height: 100% !important
			background-color: #F9F9F9
			box-shadow: 0 0 .4rem $black10
			.list__col
				&.col-name
					min-width: unset !important
	&__container
		display: flex
		width: 100%
	&__left,
	&__right
		.list__row:not(.list__row--leadnetwork):not(.list__row--already-used):not(.list__row--duplicated)
			&:hover
				background: $white !important
	&__left
		width: 30rem
		position: sticky
		top: 10rem
		left: 0
		z-index: 10
		box-shadow: 0 0 .4rem $black10
		.list__item
			width: 30rem
			.name
				margin-left: 0
	&__right
		flex-grow: 1
