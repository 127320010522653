.privilege
	+br()
	+bold()
	+textShadow()
	display: inline-flex
	align-items: center
	padding: .4rem .6rem
	height: 2.8rem
	margin: 0 .3rem
	color: $white
	font-size: 1.2rem
	&--large
		padding: .8rem 1.4rem
		height: 3.6rem
		font-size: $title3
	&--premium
		background: $premium
	&--open-profile
		background: $openprofile
	&--open-to-work
		background: $opentowork
