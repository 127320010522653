.tips
	&__header
		+mbi()
		display: flex
		align-items: flex-start
		justify-content: space-between
		margin-bottom: 2rem
	&.popover__content
		+bold()
		background-color: $black80
		color: $white
		font-size: 1.5rem
		min-width: 30rem
		.btn--link
			.icon
				stroke: $white
	svg
		path
			fill: $black80 !important
