.content
	.react-trello
		&-board
			background-color: $white
			height: 100%
			padding: $boardPad - .5rem
			position: relative
			> div:not(.smooth-dnd-container)
				height: 100%
				min-height: 4rem
			> div:last-child
				> :nth-child(1)
					+br(.8)
					background-color: $sidebar
					padding: 0
					background-color: $white
					button:not(.btn--link):not(.btn--primary)
						+trans()
						background-color: $sidebar
						color: $dark
						padding: 1.2rem 2.1rem
						&:hover
							background-color: $blue6
							color: $blue
			.smooth-dnd-container
				height: 100%
		&-lane
			+br(.8)
			max-height: calc(100% - .5rem)
			display: flex
			flex-direction: column
			padding-top: 0
			background-color: $laneColor
			> :nth-child(3)
				margin-top: 0
				order: 1
			&__header
				+flexSpread()
				flex-shrink: 0
				height: 4.8rem
				padding: 0 0 0 .6rem
				order: 1
				position: relative
				.icon.icon--load
					fill: black !important
				.react-trello-lane
					&__title
						+br()
						+flex()
						+trans()
						width: 100%
						max-width: 21rem
						height: 3rem !important
						padding: 0 .6rem !important
						position: relative
						border: .1rem solid transparent
						top: 0
						left: -.7rem
						z-index: 0
						&:hover
							background-color: $white50
							border: .1rem solid $dark20
							cursor: text

					&__new
						padding: 0
						margin: 0
						.input
							position: absolute
							top: .9rem
							left: 0
							&__item
								+bold()
								+ellipsis()
								font-size: $txt + .1
								color: $black !important
								max-width: 21rem
								height: 3rem !important
								padding: 0 .6rem !important
								border: 0 !important
								box-shadow: 0 0 0 .1rem $inputBorderColor
								line-height: 3
								&:focus,
								&:hover
									box-shadow: 0 0 0 .2rem $blue
								&::selection
									background-color: $blue20
									color: $dark !important
			&__new
				+br(.8)
				margin-top: .5rem
				margin-left: .5rem
				padding: .8rem
				background-color: $laneColor
				&__actions
					height: $buttonHeight
					display: flex
					justify-content: space-between
					.btn
						margin-top: .5rem
					.btn--link
						padding: 0 .5rem
			&__title
				+bold()
				+ellipsis()
				font-size: $txt + .1
				max-width: 21rem
				width: 100%
			&__footer
				order: 2
				*
					transition: unset
				.btn
					margin: 1.6rem 0 1rem 0
			.smooth-dnd-draggable-wrapper
				&.animated
					.react-trello-card
						box-shadow: inset 0 0 0 .1rem $black15
						.card__message
							transition: none
						.card-notes
							visibility: hidden
					.smooth-dnd-container
						&.vertical
							+br()
							min-height: 2rem
							background: $black3
		&-card
			border-radius: 0 .6rem .6rem 0
			position: relative
			transition: background-color, box-shadow .3s ease
			background-color: $cardColor
			border-bottom: .3rem solid $dark11
			padding: .6rem .8rem .8rem .8rem
			&.lead-contact
				+userStatus($contact)
				position: relative
			&.lead-relation
				+userStatus($relation)
				position: relative
			&.relation
				+userStatus($relation)
				position: relative
			&.contact
				+userStatus($contact)
				position: relative
			&.sponsor
				+userStatus($sponsorContact)
				position: relative
			*
				transition: unset
			&:hover
				background-color: $cardColorHover
				box-shadow: inset 0 0 0 .1rem $cardBorderHover
				border-color: $cardBorderHover
				.card
					&__message
						color: $black
					&__menu
						visibility: visible
			.popover__child
				visibility: hidden
				position: absolute
				top: .6rem
				right: .6rem
				&.card-notes
					display: block
					width: 2rem
					visibility: visible
					position: relative
					top: 0
					right: 0
			.labels-list
				display: inline-flex
				align-items: center
				flex-shrink: 0
				max-width: 20rem
				min-height: 2.8rem
				.label--iconed,
				.label-add
					margin-bottom: .4rem
					width: 2rem
				&--nolabel
					display: none
			.card
				overflow: visible

				.popover__child
					+trans(.1s)
					visibility: hidden
					position: absolute
					top: .6rem
					right: .6rem
					.card__menu
						margin-right: 0

				&__member
					+mbi(1.2)
					+flex()
					flex-shrink: 0
					height: 3.6rem
					margin-bottom: .4rem
					&__avatar
						+sq(3.2)
						+br()
						overflow: hidden
						border: .1rem solid $border
						flex-shrink: 0
					&__name
						+bold()
						+ellipsis()
						color: $dark
						padding-right: 2.6rem
				&__headline
					+ellipsis()
					margin-bottom: .4rem
					color: $dark
				&__message
					+trans(.1s)
					color: $light
					word-break: break-word
					overflow: hidden
					display: -webkit-box
					-webkit-line-clamp: 3
					-webkit-box-orient: vertical
					overflow: hidden
				&__note
					.icon
						margin-top: 1rem
						stroke: $light

.lane-actions,
.card-actions
	padding: 0

.card-notes
	padding: 0
.smooth-dnd-no-user-select
	.smooth-dnd-container
		&.vertical
			+br()
			min-height: 5rem
			background: $black3
