.board
	.content
		&__sidebar
			&__header
				+flex()
				width: 100%
				gap: 2rem
				&__title
					width: 100%
					gap: 2rem
			&__body
				overflow-y: auto
			&--large
				+trans()
				.sort-title
					display: none
				&.badges
					width: 8rem
					background-color: $white
					.sort-title
						display: block
						text-align: center
						font-size: .9rem
						letter-spacing: .05rem
					.content
						&__sidebar
							&__header
								+flexAxis()
								flex-shrink: 0
								height: $listHeight
								width: 8rem
								padding: 0
								border-bottom: .1rem solid $dark10
								&__title
									display: none
							&__body
								&__section
									padding: 0
									border: 0
									&__header
										.title
											display: none
									&__filters
										padding: 1.6rem 0
										+mbib(.7)
										li
											+flexAxis()

					.board__sidebar
						&__longname
							display: none
						&__badge
							display: flex
	&__modal
		&--addcard
			.asyncselect
				&__indicators
					display: none
				&__value-container
					cursor: text
	&__sidebar
		&__badge
			+sq(3.8)
			+br(10)
			+flexAxis()
			+trans()
			border: .1rem solid $dark20
			background-color: $white
			color: $black
			overflow: hidden
			display: none
			&:hover,
			&.active
				+bold()
				background-color: $blue13
				color: $black
				border-color: $black
	&__empty
		+fullwh()
		+flexAxis()
		.empty
			margin-bottom: 12rem
	&__search
		width: initial
	&__header
		+flex()
		min-height: $listHeight
		flex-shrink: 0
		border-bottom: .1rem solid $border
		> *
			+flex()
			height: 100%
		.btn
			&.link-campaign
				padding: 0 2.6rem 0 2.2rem !important
				white-space: nowrap

		&__section
			padding: 0 $boardPad
			border-right: .1rem solid $border
			position: relative
			z-index: 10
			&.title
				+mbi(3)
				justify-content: space-between
				&.fullwidth
					width: auto
					flex-grow: 1
			&.name
				+mbi(1)
				flex-shrink: 1
				max-width: 60rem
				width: 100%
			&.search
				max-width: 30rem
				width: 100%
				flex-shrink: 1
				.input
					width: 100%
			&.fill
				flex-grow: 1
				width: 100%
			&.labels
				+mbi()
				flex-shrink: 1
				padding: 1rem $boardPad
	&__name
		+ellipsis()
		width: 100%
		.btn
			width: 100%
	&__actions,
	&__pin
		+flexAxis()
		flex-shrink: 0
	&__actions
		margin-left: auto
		display: flex
		gap: 2rem
	&__label
		width: 100%
		margin-right: 0 !important
		.react-select
			&__value-container
				border: 0 !important
				padding: 0 !important
			&__control
				border: 0 !important
				padding: 0 !important
				&.react-select__control--is-focused
					border: 0 !important
					box-shadow: none !important
					&.react-select__control--menu-is-open
						border: 0 !important
						box-shadow: none !important
			&__option
				.label
					width: 100%
					min-height: 0
			&__dropdown-indicator,
			&__indicator-separator
				display: none
			&__clear-indicator
				padding: 0 !important
				margin-left: 1rem
	&__add-member
		display: flex
		&__info
			width: 100%
			min-width: 0
		&__avatar
			margin-right: 0
			flex-shrink: 0
			&__img
				+avatar(3.4, 0)
		&__name
			+mbi(.6)
			+bold()
			.relation
				color: $relation
			.contact
				color: $contact
		&__headline
			+ellipsis()
	.labels-manage
		margin-left: auto
	&__campaig-processing
		+flex()
		+bold()
		padding: 0 3rem
		gap: 1rem
		white-space: nowrap
		background-color: $processing
		color: $black
		.icon
			stroke: $black !important

.modal
	&--move-to-board
		.modal__body
			+flexCol()
			gap: 2rem
		&__section
			+mbib(1)
		&__count
			width: 6rem
			.input__item
				min-width: 6rem!important
		&__count-container
			display: flex
		.board__label
			+br()
			border: .1rem solid $border
			padding: 0 1.1rem
