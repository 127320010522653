.bulk-actions
	&-modal
		&__message
			.modal__body--overflow
				overflow: auto
			&__save
				max-width: 35rem
				margin-top: 1.6rem
				margin-bottom: 4rem
				.btn
					display: inline-flex
					width: auto
				h3
					font-size: 1.5rem
					margin-bottom: $padding
			&__body
				height: 100%
			&__sections
				.input
					height: 100%
					&__item
						min-height: 16rem
						height: 100%
					&__line,
					&__container
						height: 100%
				&.split
					display: flex
					padding: $padding + 1
					gap: $padding + 1
					.bulk-actions-modal__message__section
						+br()
						width: 100%
						max-width: 50%
						border: .1rem solid $black10
						background-color: $blue6
			&__section
				+flexCol()
				width: 100%
				h2,
				p
					margin-bottom: $padding
				p
					min-height: 6rem
					color: $black
					font-size: 1.4rem
					line-height: 1.3
				&:only-child
					background-color: transparent
				&:last-child:not(:only-child)
					background-color: transparent
					.templates__section__vars .btn
						background-color: $dark6
						&:hover
							background-color: $blue15
				&:first-child:not(:only-child)
					.btn
						background-color: $blue10
						&:hover
							background-color: $blue15
				.templates__section__vars
					margin-bottom: 2rem
			&.modal--full
				.bulk-actions-modal__message__save
					max-width: 45rem
					width: 100%
					align-self: center
					padding: 0
				.modal__footer
					padding-right: 10rem
				.modal__body--overflow
					overflow: hidden
				.bulk-actions-modal__message__body
					display: flex
					flex-direction: column
					overflow-y: auto
				.bulk-actions-modal__message__body__alert
					width: 100%
					padding: $padding + 1 $padding + 1 0 $padding + 1
				.bulk-actions-modal__message__section
					padding: $padding + 1
					&:first-child
						border-right: .1rem solid $border
						.templates__vars .btn
							background-color: $blue10
							&:hover
								background-color: $blue15
					&:last-child
						background-color: $black3
						.templates__vars .btn
							background-color: $dark6
							&:hover
								background-color: $blue15
			&__vars
				border-bottom: .1rem solid $border
			.templates__vars
				margin-bottom: $padding
			.modal
				&__body
					.flash
						align-items: flex-start
						overflow-y: auto
						max-height: 20rem
		&__labels
			.react-select
				&__option
					.label
						width: 100%
						min-height: 0
		&__boards
			.modal
				&__body
					display: flex

	&__selected-members
		+mbib(.8)
		max-width: 22rem
		height: 100%
		li
			display: flex
			width: 100%
			min-width: 0
		&__info
			display: flex
			flex-direction: column
			overflow: hidden
		&__name
			+bold()
			+ellipsis()
		&__status
			+ellipsis()
	&__selected-user-popover
		overflow-y: auto
		max-height: 40rem
