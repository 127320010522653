@namespace svg "http://www.w3.org/2000/svg"
:not(svg|*)
	all: unset
	display: revert
	box-sizing: border-box
li 
	list-style: none 
[default-styles],
pre, code 
	all: revert 
[contenteditable="true"],
[contenteditable="true"] * 
	-webkit-user-modify: read-write 
	-moz-user-modify: read-write 
