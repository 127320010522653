.settings
	display: flex
	.none
		display: none
	.content
		&__sidebar
			&__body
				overflow-y: auto
				&__section
					padding: 1rem 2.4rem
					&:last-child
						padding-bottom: 6rem
	&__header
		+flex()
		min-height: $listHeight
		flex-shrink: 0
		border-bottom: .1rem solid $border
		> *
			+flex()
			height: 100%
			padding: 0 $boardPad
			&:not(:last-child)
				border-right: .1rem solid $border
	&__content
		+fullwh()
		align-content: flex-start
		background-color: $black3
		overflow-y: auto
		.automation-mode &
			background-color: $white
	&__card
		width: 100%
		padding: 3rem 0
		background-color: #F5F5F5
		position: relative
		flex-shrink: 1
		box-shadow: 0 -1rem 1rem $black3
		border-top: .1rem solid $black8
	&__avatar
		+box()
		+avatar(4,0,#FFF)
		+br()
		margin: 0 auto 1.2rem auto
	&__detail
		width: 100%
		display: flex
		justify-content: center
		flex-wrap: wrap
		padding: 0 1rem
		&.network
			margin-top: 2rem
			gap: 1rem
			flex-wrap: nowrap
		.box
			width: 100%
			max-width: 11rem
			margin-right: 0
			&__value
				font-size: 1.6rem
				margin-bottom: .4rem
			&__label
				font-size: .9rem
	&__name
		+flexAxis()
		+bold()
		text-align: center
		font-size: 1.6rem
		line-height: 1.4
		margin-bottom: 1rem
	&__link
		margin: auto
		width: auto
	&__position
		+ellipsis()
		+bold()
		text-align: center
		font-size: 1.4rem
		line-height: 1.4
		white-space: normal
		margin-bottom: 1rem
	&__content
		padding: 3rem
