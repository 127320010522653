.dynamic-tag
	&__input
		+stickedInputButton()
		width: 0
		display: flex
		margin-bottom: 1rem
	&__tags
		+mbi(.4)
		.label
			margin-bottom: .4rem
