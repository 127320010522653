$fontFamily: "Mulish", arial, sans-serif

$txt: 1.3rem
$txtSmall: $txt - .2
$txtMini: $txt - .4
$txtNano: $txt - .5
$fontWeight: 400

$title: $txt + .7
$title2: $txt + .4
$title3: $txt + .3
$title4: $txt + .2
$title5: $txt + .1
$title6: $txt

$fakeHeight: 1.6rem

$br: .6rem

$sidebarWidth: 5rem
$sidebarButtonSize: $sidebarWidth

$sidebarContentWidth: 26.2rem

// Buttons & Inputs

$padding: 1.2rem
$sidebarPadding: 2.4rem

$buttonHeight: 3.6rem
$buttonPadding: 0 $padding 0 $padding
$buttonFontSize: $txt
$buttonLineHeight: $txt * 2

$buttonHeightSmall: $buttonHeight - .6
$buttonPaddingSmall: 0 $padding - .3 0 $padding - .3
$buttonFontSizeSmall: $txt - .1
$buttonLineHeightSmall: $buttonFontSizeSmall * 2

$buttonHeightMini: $buttonHeight - 1
$buttonPaddingMini: 0 $padding - .4 0 $padding - .4
$buttonFontSizeMini: $txtSmall
$buttonLineHeightMini: $buttonFontSizeMini * 2

$buttonHeightNano: $buttonHeight - 1.4
$buttonPaddingNano: 0 $padding - .6 0 $padding - .6
$buttonFontSizeNano: $txt - .3
$buttonLineHeightNano: $buttonFontSizeNano * 2

$buttonHeightLarge: $buttonHeight + .8
$buttonPaddingLarge: 0 $padding + .4 0 $padding + .4
$buttonFontSizeLarge: $txt + .2
$buttonLineHeightLarge: $buttonFontSizeLarge * 2

$inputMinWidth: 16rem

$inputHeight: $buttonHeight
$inputPadding: 0 $padding 0 $padding
$inputFontSize: $txt
$inputLineHeight: $buttonLineHeight

$inputHeightSmall: $buttonHeightSmall
$inputPaddingSmall: 0 $padding - .1 0 $padding - .1
$inputFontSizeSmall: $buttonFontSizeSmall
$inputLineHeightSmall: $buttonLineHeightSmall

$inputHeightLarge: $buttonHeightLarge
$inputPaddingLarge: 0 $padding + .4 0 $padding + .4
$inputFontSizeLarge: $buttonFontSizeLarge
$inputLineHeightLarge: $buttonLineHeightLarge

// tabs

$tabBtnHeight: $buttonHeight
$tabBtnPillHeight: $buttonHeight

// panel
$panelWidth: 36rem
$panelpadding: 2.4rem
$contentHeaderHeight: 7.8rem
$panelHeaderHeight: $contentHeaderHeight

// modal
$modalWidth: 62rem
$modalPadding: 2.4rem

$listHeight: 6.8rem
$listPadding: 1.8rem

$boardPad: 2.4rem

$lnHeaderHeight: 5.2rem
$lnPad: 3rem
