
@mixin header()
	+flex()
	height: $lnHeaderHeight
	padding: 0 $lnPad
	flex-shrink: 0

@keyframes hideSidebar
	0%
		width: 32rem
		visibility: visible
	5%
		visibility: hidden
	100%
		width: 0
		visibility: hidden

@keyframes showSidebar
	0%
		width: 0
		visibility: hidden
	95%
		visibility: hidden
	100%
		width: 32rem
		visibility: visible
.lnuser
	display: flex
	flex-direction: column
	&--lead
		flex-direction: row
	&__custom-info-modal
		.modal__body
			+mbib(2)
	&__header
		+flexSpread()
		border-radius: .5rem .5rem 0 0
		height: 5.8rem
		flex-shrink: 0
		border-bottom: .1rem solid $border
		overflow: hidden
		&__left,
		&__right
			+flexSpread()
			+trans()
			&.large
				min-width: 32rem

		.tooltip,
		.btn
			width: 5.8rem !important
			height: 5.8rem !important
			max-width: 100% !important
			flex-shrink: 0
			margin: 0 !important
	&__content
		height: 100%
		width: 100%
		display: flex
		overflow: hidden
	&__sidebar
		+flexCol()
		+trans()
		height: 100%
		overflow: hidden
		flex-shrink: 0
		visibility: visible
		animation: unset
		width: 32rem
		&.hide-left-sidebar,
		&.hide-right-sidebar
			animation: hideSidebar .5s ease
			width: 0
			border: 0 !important
		.btn--toggle
			width: auto
		&__header
			+header()
			&--no-border
				border: 0
		&__tab
			+flex()
			border-top: .1rem solid $border
			border-bottom: .1rem solid $border
			height: 4.2rem
			width: 100%
			.btn--tab
				flex-grow: 1
		&__content
			+flexCol()
			height: 100%
			min-height: 0
			overflow: hidden
		.btn.btn--open-board.btn--transparent.btn--no-label
			&.active
				cursor: pointer
				background-color: transparent
				&:hover
					background-color: $sidebar
					color: $dark
					.icon
						stroke: $dark
		&.left
			border-right: .1rem solid $border
			.follow-btn
				margin-left: auto
			.yellow
				color: $dark
			.lnuser
				&__sidebar
					&__header
						+flexSpread()
		&.right
			border-left: .1rem solid $border
			.lnuser
				&__sidebar
					&__header
						padding-right: 1.6rem
						.btn,
						.tooltip
							margin-left: auto
					&__section
						flex-direction: column
						justify-content: flex-start
						align-items: flex-start
						min-height: $lnHeaderHeight
						padding-top: 2rem
						padding-bottom: 1rem
		&__section
			+flexSpread()
			padding: 0 $lnPad
			min-height: $lnHeaderHeight + 1
			flex-shrink: 0
			&-scroll
				+fullwh()
				overflow-y: auto
			&__info
				width: 100%
				display: flex
				flex-direction: column
				text-align: center
				padding: 0
			&__separator
				+separator()
				margin: 3rem 0
			&__extra-info
				text-align: center
				color: $light
				margin-bottom: 1rem
				padding: 1rem 3rem
			&__stats
				padding: 1rem 0
				display: flex
				justify-content: center
				margin-bottom: 2rem
				gap: 1.4rem
			&__current,
			&__user-info
				width: 100%
				+mbib(1.4)
			&__group
				padding: 0 3rem
				+mbib(4)
			&__user-info
				.tooltip
					display: flex
					.btn
						margin: auto
				.enrichement
					justify-content: center
					.flash-content
						+bold()
						+flexCol()
						align-items: center
						gap: .8rem
						color: $blue
						.btn
							+regular()
							text-decoration: underline
							&:hover
								text-decoration: none
								background-color: transparent
			&__current
				margin-bottom: 5rem
				&__info
					+mbib(1)
					margin-bottom: 4rem !important
					h5
						margin-bottom: 1.4rem !important
			&.actions-button
				min-height: unset
				margin-bottom: 2rem
				.btn
					flex-grow: 1
					justify-content: center
				&.two-buttons
					.btn
					.popover__child
						flex-grow: 1
						justify-content: center
					.btn
						margin-right: .5rem
					.popover__child
						margin-left: .5rem
			&__kanbox
				+br()
				+flexCol()
				background-color: $blue5
				margin: 1rem $lnPad $lnPad $lnPad
				padding: $lnPad - 1 0
				border: .1rem solid $blue20
				h5
					color: $blue
				.lnuser__sidebar__section
					&:first-child
						margin-bottom: 1.6rem
						justify-content: center
						&:last-child
							margin-bottom: 0
					&:last-child
						margin-bottom: 1rem
					&.last
						order: 5
						margin: 2rem 0 0 0
				.btn--secondary
					border: .1rem solid $blue10
					background-color: $blue6
					&:hover
						background-color: $blue10
					&-dark
						border: .1rem solid $blue20
						background-color: $white
						color: $black
						.icon
							stroke: $black
						&:hover
							background-color: $blue10
				.btn--group-item
					background-color: $white !important
					color: $black
					&:hover
						background-color: $blue10 !important
	&__origin
		+mbib(1.4)
		width: 100%
		.btn
			width: 100%
		h4
			font-size: $title5
	&__board
		+mbib(1.4)
		width: 100%
		.dropdown
			width: 50%
			max-width: unset
			&.btn--board
				width: 100%
			&__menu
				max-width: 26rem
				max-height: 26rem
				overflow-y: auto
		.btn--group
			.dropdown
				&:first-child
					.dropdown__menu
						width: 26rem
						left: 0
						right: 50%
						transform: unset
				&:last-child
					.dropdown__menu
						width: 26rem
						left: -100%
						right: 0
						transform: unset
	&__reminder,
	&__note
		+mbib(1.4)
		width: 100%
		&__container
			+br()
			+mbib(.8)
			position: relative
			padding: 1rem 2rem .4rem 1.4rem
			background-color: #FDFFD8
			border: .1rem solid #E3E1CD
			.dropdown
				position: absolute
				top: .6rem
				right: .4rem
		&__date
			+txtMini()
			text-transform: uppercase
		&__display
			color: $black
		&__edit
			display: flex
			flex-direction: column
			.btn
				margin-left: auto !important
	&__reminder
		&__set
			+flexSpread()
			+br()
			height: $buttonHeight
			padding: 0 1.2rem 0 0.8rem
			background-color: $remindHover
			border: .1rem solid $black10
			color: $black
			&__content
				+flex()
				gap: 1rem
				.icon
					stroke: $black
		> .dropdown
			width: 100%
		.dropdown__menu
			width: 25.9rem !important

	&__board
		&__header
			+flexSpread()
			margin-bottom: 1.4rem
	&__labels
		display: flex
		flex-direction: column
		width: 100%
		.labels__wrapper
			cursor: default
		.labels-list
			width: 100%
			min-width: 0
			flex-direction: column
		&__header
			+flexSpread()
			margin-bottom: 1.4rem
			&--special
				align-items: flex-start
				justify-content: flex-start
				flex-direction: column
				h5
					margin-bottom: 1.4rem
		.popover__child
			> [role=button]
				display: flex
				flex-direction: column
				width: 100%
				.label
					margin: 0 0 .6rem 0
					width: 100%
	&__actions
		display: flex
		flex-direction: column
		width: 100%
		&__header
			margin-bottom: 1.4rem
		&__list
			+mbib(.6)
			margin-bottom: 3rem
			.popover__child
				display: block
			&__separator
				height: 2rem
				width: 100%
				flex-shrink: 0
	&__user
		&__custom-info
			+mbib(2)
			background-color: $black3
			border-top: .1rem solid $black6
			border-bottom: .1rem solid $black6
			padding: 2rem 3rem
		&__email
			+flexAxis()
			&.enriched-email
				color: $blue
		&__enriched
			+flexAxis()
			&__label
				+br()
				+txtSmall()
				background-color: $yellow
				color: $black
				padding: .4rem 1rem
		&__name
			margin-bottom: 2rem
			flex-shrink: 0
			line-height: 1.3
			padding: 0 3rem
		&__headline
			+bold()
			font-size: $txt + .1
			line-height: $txt * 1.6
			padding: 0 3rem
		&__avatar
			+br(2)
			+sq(25.7)
			border: .1rem solid $border
			overflow: hidden
			flex-shrink: 0
			margin-bottom: 1rem
		&__company
			+bold()
			+title2()
			line-height: 1.3
			margin-bottom: 1rem
		&__industry
			font-size: $txtSmall
		&__visit
			.btn
				width: auto
				justify-content: center
		&__skills
			+flexCol()
			&-container
				+flexAxis()
				+mbi(.4)
				flex-wrap: wrap
				width: 100%
				max-height: 15rem
				overflow-y: auto
				overflow-x: hidden
				padding: 0 3rem
				margin-bottom: 1rem
	&__body
		flex-grow: 1
		display: flex
		flex-direction: column
		width: 100%
		min-width: 0
		&--left-radius
			border-radius: .6rem 0 0 .6rem
			overflow: hidden
		&--right-radius
			border-radius: 0 .6rem .6rem 0
			overflow: hidden
			.btn.send
				margin-right: 8rem
		&.lnuser__body--left-radius
			&.lnuser__body--right-radius
				+br()
		&__header
			+header()
			border-bottom: .1rem solid $border
			padding: 0
			width: 100%
			border-bottom: .1rem solid $border
			&__actions
				+flex()
				height: 100%
				flex-shrink: 0
			&__action
				+sq(5)
				flex-shrink: 0
				height: 100%
			&__content
				display: flex
				align-items: center
				justify-content: space-between
				min-width: 0
				padding: 0 1.6rem
				flex-shrink: 1
				width: 100%
				min-width: 0
			.template-messages
				min-width: 0
				margin-left: auto
				.dropdown
					width: 100%
					min-width: 0
					&__menu
						overflow-y: auto
						max-height: 30rem
						max-width: 30rem
			.multiple-conversations
				min-width: 0
				.dropdown
					width: 100%
					min-width: 0
					position: relative
					> .btn
						+bold()
						&:after
							+sq(2)
							+flexAxis()
							content: '▼'
							margin-left: 1rem

		&__chat
			+trans()
			overflow: auto
			display: flex
			flex-direction: column-reverse
			width: 100%
			flex-grow: 1
			padding: 0 calc($lnPad / 2)
			background-image: url(../../images/chat-pattern.png)
			background-size: 40%
			// background-color: #fffef5
			background-color: $white
			position: relative
			&:before
				content: ''
				position: absolute
				top: 0
				left: 0
				width: 100%
				height: 100%
				background-color: $white
				opacity: .15
				z-index: -1
			&.chat-empty,
			&.chat-disabled
				+flexAxis()
				flex-direction: row
			&.chat-disabled
				background-color: #e8e8e8
			&__content
				padding-top: 3rem
				display: flex
				flex-direction: column-reverse
				&__template
					+fullwh()
					align-items: center
					display: flex
				&__invitation,
				&__invite
					+flexCol()
					align-items: center
					max-width: 40rem
					width: 100%
					h1
						font-size: $title + .5
						text-align: center
						margin-bottom: 2rem
					.optional
						margin-left: auto
					.input
						margin-bottom: 2rem
						&__item
							min-height: 16rem
				&__invitation
					h1
						margin-bottom: 4rem

			&__footer
				border-top: .1rem solid $border
	.btn
		&--withdraw
			ustify-content: center
			flex-grow: 0
			background-color: $orange
			text-shadow: .1rem .1rem 0 $black10
	&__company__card,
	&__user-position__card
		+br(1)
		+mbi(2)
		display: flex
		padding: 2rem
		border: .1rem solid $border
		text-align: center
		flex-grow: 1
		box-shadow: 0 .3rem 0 $black8
		background-color: $white
		font-size: $title4
		line-height: 1.3
		&__icon
			.icon
				stroke-width: .1rem
				stroke: $black
		&__info
			text-align: left
			&__section
				+mbib(1)
				margin-bottom: 2rem
				max-height: 15rem
				overflow-y: auto
				overflow-x: hidden
		&__meta
			+mbi()
			+bold()
	&__user
		&__summary
			max-height: 15rem
			overflow-y: auto
			overflow-x: hidden
	&__match
		+flex()
		+mbi()
		+bold()
		&__label
			+br()
			padding: .4rem 1rem
			&--ok
				background-color: $green-sf
				color: $white
			&--nok
				background-color: $red
				color: $white
		&__reason
			color: $red
	&__import
		&__meta
			+mbib(1)
			margin-bottom: 1rem
			width: 100%
			min-width: 0
			.icon
				stroke-width: .16rem
	.box
		max-width: 12rem
	&--lead
		.lnuser
			&__sidebar
				width: 46rem
				&.left
					flex-grow: 1
					background-color: $black3
					color: $black
					.lnuser__sidebar__section__info
						padding: 0 6rem
					h5
						font-size: $txt
						margin-bottom: 2rem !important
				&.right
					border: 0
				&__section
					&__user-info
						margin-bottom: 3rem
					&__current__info
						+mbib(3)
					&__extra-info
						+mbi()
			&__company__card,
			&__user-position__card
				&__info
					&__section
						max-height: unset
			&__user
				&__skills
					margin-bottom: 3rem
					&-container
						max-height: unset
					.skill
						height: 3.8rem
						padding: 0 1.2rem
						font-size: $title5 + .1
				&__avatar
					margin: 3rem auto
				&__name
					font-size: $title + 1
					line-height: 1.4
				&__phone,
				&__email
					+bold()
					font-size: $title
					line-height: 1.2
				&__industry
					font-size: $title
					line-height: 1.4
					color: $black
				&__summary
					font-size: $title4
					line-height: 1.3
					margin: 4rem 0
				&__location
					+bold()
					font-size: $title3
					margin-bottom: 3rem
					line-height: 1.4
					color: $black
				&__industry
					+bold()
				&__headline,
				&__job
					font-size: $title
					line-height: 1.4
				&__job
					+bold()
					margin-bottom: 1rem
				&__company
					font-size: $title + .5
					line-height: 1.4
					color: $black
					&__website,
					&__info
						font-size: $title3
						line-height: 1.3
					&__website
						margin-bottom: 1rem

			&__actions
				&__list
					width: 100%
		.box
			max-width: 26rem
			&__value
				color: $black !important
				font-size: $title + 1
				line-height: 1.4
				max-width: 20rem
			&__label
				color: $black !important
				font-size: $title4
				margin-bottom: 2rem
