.labels
	display: flex
	&-list
		display: flex
		flex-wrap: wrap
		&--spacing
			+mbi(.2)
	&__wrapper
		display: flex
		width: 100%
		flex-wrap: wrap

	&__color-piker
		display: flex
		flex-wrap: wrap
		margin: 1rem 0 1.6rem 0
	&__color
		+br()
		+trans()
		width: 4rem
		height: 2.9rem
		margin: .2rem
		border: .2rem solid $white
		position: relative
		&--selected,
		&:hover
			box-shadow: 0 0 0 .2rem $blue
	&-popover
		max-width: 32rem
		width: 100%
		padding: 0 !important
		overflow: hidden
		&__wrapper
			display: flex
			flex-direction: column
		&__header
			padding: 1rem 1.6rem 0 1.6rem
			position: relative
			&__title
				+title4()
				text-align: center
				color: $dark
		&__body
			padding: 2rem 1.6rem .2rem 1.6rem
			overflow-y: auto
			max-height: 33rem
			.loading-icon
				position: absolute
				right: 1rem
				top: 1rem
		&__actions
			padding: 1.6rem 4.5rem 1.6rem 4.5rem
			border-top: .1rem solid $border
			background-color: #F6F6F6
			.btn
				width: 100%
		&__footer
			display: flex
			width: 100%
			.btn
				width: auto
				flex-grow: 1
				border-radius: unset
				height: 4.6rem
				border: 0
				justify-content: center
		&__delete
			padding: 0 1.6rem 1.6rem 1.6rem
			.btn
				justify-content: center
		&__label
			+mbi(1)
			width: 100%
			margin-bottom: .8rem
			+flex()
			.label
				width: 100%
				min-width: 0
				margin-top: 0
				margin-bottom: 0
				flex-shrink: 1
		&__form
			&__item
				padding: 1.6rem 1.6rem 0 1.6rem
				.input,
				p
					+bold()
