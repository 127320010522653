.asynchronous
	width: 100%
	height: 100%
	display: flex
	flex-direction: column
	&__popover
		position: relative
		height: 100vh
		max-width: 100%
		width: 100vw
		overflow: hiddent
		padding: 0
		top: 0 !important
		left: 5.1rem !important
		border-radius: 0 $br $br 0 !important
		> svg
			display: none
	&__header
		+flex()
		height: $listHeight
		padding: 0 2rem
		flex-shrink: 0
		background-color: $black2
		border-bottom: .1rem solid $border
		h1
			+flex()
			padding-right: 6rem
			height: 100%
			border-right: .1rem solid $border
		.btn--tab
			background-color: transparent
			border-right: .1rem solid $border
			font-size: 1.6rem
			color: $black60
			&.active
				color: $black
				background-color: $white
			&:hover
				&.active
					background-color: $white
					color: $black
					font-weight: 800
					cursor: default
	&__content
		height: 100%
		overflow: auto
	.list__header
		border-bottom: .1rem solid $black10
	.popover__tooltip__cursor
		justify-content: flex-start
