.icon
	+trans()
	stroke: $dark
	stroke-linecap: round
	stroke-linejoin: round
	flex-shrink: 0
	&--load,
	&--fill
		stroke-width: 0
		fill: $dark
	&--light
		stroke: $light
		&.icon--fill
			fill: $light
	&--white
		stroke: $white
		&.icon--fill
			fill: $white
	&--blue
		stroke: $blue
		&.icon--fill
			fill: $blue
	&--red
		stroke: $red
		&.icon--fill
			fill: $red
	&--green
		stroke: $green
		&.icon--fill
			fill: $green
	&--yellow
		stroke: $yellow
		&.icon--fill
			fill: $yellow
	&.icon--kanbox
		stroke-width: 0
		fill: $blue
	&.icon--empty
		fill: $white
		stroke-width: 0
	&:not(.icon--kanbox):not(.icon--empty):not(.icon--fill):not(.icon--plan)
		fill: none
	&.icon--processing
		stroke: $blue !important
		animation: process 3s linear infinite
