.affiliate
	&-options
		.settings__content
			padding: 0
			background-color: $white
			overflow: hidden
			display: flex
			flex-direction: column
		&__menu
			+flex()
			height: 5.8rem
			flex-shrink: 0
			border-bottom: .1rem solid $border
			li
				+flex()
				height: 100%
				border-right: .1rem solid $border
				.btn
					border-radius: 0
					padding: 0 3rem !important
					height: 100%
					color: $blue
					.icon
						stroke: $blue
	&__ad
		+flexAxis()
		width: 100%
		padding: 3rem 0
		img
			max-width: 30rem
	&__funnel
		+flexAxis()
		padding: 0 4rem
		font-size: 1.8rem
		line-height: 1.4
		margin-bottom: 3rem
		color: $black
		height: 100%
		overflow: unset !important
		overflow-y: auto !important
		justify-content: flex-start
		.title-discover
			font-size: $title - .4
			margin-bottom: 2rem
		.subtitle-discover
			font-size: $title + 1.5
			margin-bottom: 5rem
			text-align: center
			line-height: 1.3
		.title
			font-size: $title + 1
			margin-bottom: 3rem
		.subtitle
			font-size: $title + 1.5
			margin-bottom: 3rem
		img
			max-width: 30rem
		&.step2
			justify-content: center
			img
				max-width: 18rem
			.title
				font-size: $title
				margin-bottom: 1rem
		&__steps
			max-width: 40rem
			margin-bottom: 3rem
			li
				list-style: disc outside
				margin-bottom: 1.4rem
				ul
					padding-left: 3rem
					margin-top: 2rem
					li
						list-style: circle outside
		&__legal
			max-width: 42rem
			margin-bottom: 3rem
			font-size: 1.3rem
	&__features
		display: flex
		justify-content: space-between
		width: 100%
		margin-bottom: 10rem
		text-align: center
		&__item
			width: 100%
			padding: 5rem
			box-shadow: 0 0 30rem $black6
			background: $white
			.icon
				margin-bottom: 2rem
			h2
				font-size: 2.3rem
				margin-bottom: 1.4rem
				line-height: 1.4
				text-align: left
			p
				font-size: 1.6rem
				text-align: left
				margin-bottom: 3rem
			span
				display: block
				font-size: 1.4rem
				line-height: 1.2
				text-align: left
	&__link
		+linkBox()
	&__list
		width: 100%
		height: 100%
		.list
			&__filter
				padding: 0 $boardPad
			&__row
				min-height: unset
			&__cols
				height: 3.2rem
			&__col
				&:not(.list__col--spacer):not(.list__col--large)
					min-width: 12rem
				&--large
					flex-shrink: 1
@media screen and (max-width: 1600px)
	.affiliate
		&__funnel
			padding: 0 4rem
		&__features
			flex-wrap: wrap
			justify-content: center
			h2,
			p
				max-width: 40rem
				margin-left: auto
				margin-right: auto
				text-align: center
			&__item
				max-width: 40rem
				padding: 3rem
@media screen and (max-width: 860px)
	.affiliate
		&__funnel
			padding: 0 2rem
		&__features
			flex-wrap: wrap
