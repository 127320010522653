select
	+font()
.asyncselect__control--is-focused .asyncselect__single-value
	display: none
.select,
.react-select
	line-height: initial
	width: 100%
.select,
.asyncselect,
.react-select
	&__label
		margin-bottom: 1rem
		display: block
		font-weight: 700
	&__error
		display: block
		margin-top: 1rem
		color: $red
	&__control
		cursor: pointer !important
		transition: 0s all ease-out !important
		min-width: $inputMinWidth
		min-height: $inputHeight !important
		border-radius: .6rem !important
		background-color: transparent
		&:not(.select__control--is-focused):not(.asyncselect__control--is-focused)
			border-color: $inputBorderColor !important
			.error > &
				border-color: $red !important
		&--is-focused
			border-color: $dark !important
			box-shadow: none !important
			.select__indicator, .asyncselect__indicator
				svg
					stroke: $dark
	&__value-container
		padding-left: 1rem !important
	&__single-value
		color: $dark !important
	&__indicator
		padding-left: 1rem !important
		padding-right: 1rem !important
		&-separator
			background-color: $dark25
		svg
			stroke: $dark25
	&__placeholder
		white-space: nowrap
		color: $light !important
	&__option,
	&__multi-value
		.flag
			margin: 0 0 0 .6rem
			width: 2.2rem
			height: 1.5rem
	&__option
		+mbi()
		display: flex !important
		align-items: center
		color: $dark !important
		padding: .4rem 1.2rem !important
		&--is-disabled
			color: $dark40 !important
		&--is-selected
			color: $white !important
			background-color: $blue25 !important
			&.select__option--is-focused
				color: $white !important
				background-color: $blue !important
		&--is-focused:not(.select__option--is-selected)
			background-color: $blue10 !important
			color: $dark !important
	&__multi-value
		+flex()
		background-color: $blue
		border-radius: .4rem !important
		padding: .4rem .2rem .4rem 1rem
		color: $white
		position: relative
		&--is-disabled
			opacity: .6
		&__label,
		&__remove
			border-radius: .4rem !important
			background-color: transparent !important
			padding: 0 !important
		&__label
			font-size: $inputFontSize !important
			color: $black90 !important
			margin-right: .4rem
		&__remove
			cursor: pointer
			svg
				fill: $dark !important
	&--small
		.select,
		.asyncselect,
		.react-select
			&__control
				min-width: 18rem
				min-height: $inputHeightSmall !important
				font-size: $inputFontSizeSmall !important
			&__indicator
				padding: .2rem .6rem !important
			&__option,
			&__multi-value
				background-color: $white
				font-size: $inputFontSizeSmall !important
				.flag
					margin: 0 0 0 .6rem
					width: 2.2rem
					height: 1.5rem
				&__label
					font-size: $inputFontSizeSmall !important
					padding: 0 0 0 1rem !important
					line-height: 1.2
			&__option
				.flag
					margin: 0 1rem 0 0
			&__value-container
				padding: 0 0 0 .7rem !important
