.admin
	.plan-label
		+br()
		min-width: 0
		max-width: 100%
		display: inline-flex
		background-color: #fff
		border: .1rem solid $black6
		font-size: 1rem
		text-transform: uppercase
		letter-spacing: .1rem
		padding: .3rem .8rem
		color: $black
		&.client
			background-color: #66e990
			&.partner
				+bold()
				background-color: #2c79ff
				color: $white
			&.appsumo
				background-color: #ffb903
			&.partner
				+bold()
				background-color: #8244e7
				color: $white
			&.affiliate
				+bold()
				background-color: #e545d3
				color: $white
	.list
		&__col
			&--email
				max-width: 30rem
