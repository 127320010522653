@mixin flex()
	display: flex
	align-items: center
@mixin flexSpread()
	display: flex
	align-items: center
	justify-content: space-between
@mixin flexAxis()
	display: flex
	align-items: center
	justify-content: center
@mixin flexCol()
	display: flex
	flex-direction: column
@mixin ellipsis()
	white-space: nowrap
	overflow: hidden
	text-overflow: ellipsis
@mixin regular()
	font-weight: 400
@mixin medium()
	font-weight: 500
@mixin semibold()
	font-weight: 600
@mixin bold()
	font-weight: 700

@mixin txt()
	font-size: $txt
@mixin txtSmall()
	font-size: $txtSmall
@mixin txtMini()
	font-size: $txtMini
@mixin txtNano()
	font-size: $txtNano

@mixin title()
	font-size: $title
@mixin title2()
	font-size: $title2
@mixin title3()
	font-size: $title3
@mixin title4()
	font-size: $title4

@mixin placeHolderColor($c: $light, $w: 400)
	&::-webkit-input-placeholder
		font-weight: $w
		color: $c
	&:-moz-placeholder
		font-weight: $w
		color: $c
	&::-moz-placeholder
		font-weight: $w
		color: $c
	&:-ms-input-placeholder
		font-weight: $w
		color: $c

@mixin whiteTextShadow($c: $white70)
	text-shadow: .1rem .1rem 0 $c

@mixin textShadow($c: $dark20)
	text-shadow: .1rem .1rem 0 $c

@mixin trans($speed: .3s)
	transition: $speed all ease-out

@mixin font()
	font-family: $fontFamily
	font-size: $txt
	font-weight: $fontWeight
	color: $text
	line-height: $txt + .4
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale

@mixin inputFont()
	font-family: $fontFamily

// Make square
@mixin sq($size: $buttonHeight)
	$size: $size + 0rem
	width: $size
	height: $size

// It gives a margin right to all children except the last one.
@mixin mbi($a: 1.4)
	$a: $a + 0rem
	> :not(:last-child)
		margin-right: $a
@mixin pbi($a: 1.4)
	$a: $a + 0rem
	> :not(:last-child)
		padding-right: $a

// It gives a margin bottom to all children except the last one.
@mixin mbib($a: 1.4)
	$a: $a + 0rem
	> :not(:last-child)
		margin-bottom: $a

// Gives a border-radius : e. g. : br(3) = border-radius 3rem
@mixin br($a: $br)
	@if $a >= 50
		$a: $a * 100%
	@else
		$a: $a + 0rem
		border-radius: $a

// Border and shadow style of dropdowns
@mixin box()
	+br()
	box-shadow: 0 .3rem .8rem $dark20
	border: .1rem solid $dark15

// Buttons mixins

@mixin focus()
	&:focus-visible
		box-shadow: 0 0 .1rem .2rem $blue
		z-index: 1
		transition: all .3s

@mixin stickedInputButton()
	display: flex
	.input__item:not([type=button]):not([type=checkbox]):not([type=radio]):not([type=textarea])
		border-radius: $br 0 0 $br !important
	.btn
		border-radius: 0 $br $br 0 !important
		border-left: 0
		z-index: 0
@mixin separator()
	width: 100%
	height: .1rem
	border-top: .1rem solid $inputBorderColor
	margin: 1.1rem 0 0 0
	flex-shrink: 0
@mixin btn()
	+br()
	+flexBtn()
	+trans()
	+buttonFont()
	+focus()
	white-space: nowrap

@mixin btnNotHovered()
	+br()
	+flexBtn()
	+trans()
	+buttonFont()
	+focus()
	white-space: nowrap

@mixin flexBtn()
	flex-shrink: 0
	display: inline-flex
	align-items: center
	justify-content: flex-start

@mixin buttonFont()
	height: $buttonHeight
	font-size: $buttonFontSize
	line-height: $buttonLineHeight
	padding: $buttonPadding

@mixin buttonSmall()
	height: $buttonHeightSmall
	font-size: $buttonFontSizeSmall
	line-height: $buttonLineHeightSmall
	padding: $buttonPaddingSmall
	&.btn--no-label
		&.btn--link-inline, &.btn--link-primary, &.btn--link
			height: auto
			line-height: unset

@mixin buttonMini()
	height: $buttonHeightMini
	font-size: $buttonFontSizeMini
	line-height: $buttonLineHeightMini
	padding: $buttonPaddingMini
	&.btn--no-label
		&.btn--link-inline, &.btn--link-primary, &.btn--link
			height: auto
			line-height: unset

@mixin buttonNano()
	height: $buttonHeightNano
	font-size: $buttonFontSizeNano
	line-height: $buttonLineHeightNano
	padding: $buttonPaddingNano
	&.btn--no-label
		&.btn--link-inline, &.btn--link-primary, &.btn--link
			height: auto
			line-height: unset

@mixin buttonLarge()
	height: $buttonHeightLarge
	font-size: $buttonFontSizeLarge
	line-height: $buttonLineHeightLarge
	padding: $buttonPaddingLarge
	&.btn--no-label
		&.btn--link-inline, &.btn--link-primary, &.btn--link
			height: auto
			line-height: unset

@mixin disabled($b: #3474FF, $t: #fff)
	cursor: default
	opacity: .3
	box-shadow: none
	&:hover
		opacity: .3
		background-color: $b
		color: $t
		.icon
			stroke: $t
@mixin fullwh()
	height: 100%
	width: 100%
	min-width: 0
	min-height: 0

@mixin hover()
	&:not(.btn--group):not(.btn--switch):not(.btn--tab):not(.btn--loading)
		&:hover:not(:disabled)
			background-color: $buttonHover
			color: $white
			.icon
				stroke: $white
@mixin disableHoverEffect()
	&:hover
		background-color: $blue
		cursor: default

@mixin quotaBox()
	+br(10)
	+bold()
	padding: 1rem 1.6rem
	background-color: $black
	color: $white
	white-space: nowrap
@mixin bgPattern($o: .6, $s: 28%)
	&::before
		position: absolute
		top: 0
		left: 0
		content: ''
		width: 100%
		height: 100%
		background-image: url(../../images/chat-pattern.png)
		background-size: $s
		opacity: $o
		z-index: -1

@mixin userStatus($c: $relation)
	&:before
		width: .15rem
		height: 100%
		content: ''
		position: absolute
		left: 0
		top: 0
		z-index: 1
		background-color: $c

@mixin notification($c: $pink, $t: 1rem, $r: 1rem)
	&:before
		+sq(.8)
		+br(5)
		content: ''
		border: 0
		position: absolute
		top: $t
		left: $r
		background-color: $c
		z-index: 10
@mixin liquid($color, $opacity: 0.16)
	background-color: rgba($color, $opacity)
@mixin avatar($s: $buttonHeight, $m: 1.5rem, $c: $border)
	+sq($s)
	+br()
	flex-shrink: 0
	overflow: hidden
	margin-right: $m
	@if $c != 0
		border: .1rem solid $c
@mixin linkBox()
	+flexAxis()
	flex-direction: column
	width: 100%
	height: 100%
	overflow-y: auto
	color: $black
	background-image: url(../../images/chat-pattern.png)
	background-size: 40%
	.title
		font-size: 3.5rem
		margin-bottom: 2rem
	p
		margin-bottom: 3rem
		font-size: 1.6rem
	.icon
		stroke: $black
		margin-bottom: 2rem
	&__container
		+br(1)
		+mbi(4)
		+trans()
		padding: 1.6rem 3rem
		border: .1rem solid $black
		background-color: #FEFEFE
		margin-bottom: 18rem
		&:hover
			background-color: #eefaff
			border-color: $blue
			.copy,
			.link
				color: $blue
		.link
			+trans()
			+bold()
			font-size: 1.5rem
		.copy
			+bold()
			+trans()
			font-size: .9rem
			text-transform: uppercase
			letter-spacing: .1rem
			&:hover
				color: $blue
