input
	+font()
.input
	width: 100%
	&__container
		position: relative
	&__label
		+bold()
		display: block
		margin-bottom: 1rem
	&__error
		color: $red
		display: block
		margin: .5rem 0 .5rem 0
	&__line
		width: 100%
	&--checkbox,
	&--radio
		width: auto
		position: relative
		.input
			&__label
				cursor: pointer
			&__line
				+flex()
				line-height: 1
				.input__label
					order: 1
					margin: 0 0 0 1rem
			&__item
				+br(.4)
				+sq(1.4)
				border: .1rem solid $light
	&--disabled
		.input
			&__item,
			&__label
				cursor: default !important
				opacity: .4
	&__item
		+trans()
		&:not([type=button]):not([type=checkbox]):not([type=radio]):not([type=textarea])
			height: $inputHeight
			padding: $inputPadding
		&:not([type=button]):not([type=checkbox]):not([type=radio])
			+br()
			+placeHolderColor($placeHolderColor)
			min-width: $inputMinWidth
			width: 100%
			background-color: $inputColor
			border: .1rem solid $inputBorderColor
			&.error
				border-color: $red
			&:focus
				border-color: $light
		&[type=textarea]
			transition: 0s background-color ease-out
			padding: +calc($padding / 1.7) $padding
			min-height: $inputHeight * 2
		&[type=radio]
			all: revert
			cursor: pointer
			border-color: #AAB0C6
		&[type=checkbox]
			all: revert
			cursor: pointer
			line-height: revert
			border-color: #AAB0C6
		&[type=number]
			-moz-appearance: textfield
	&.input--large
		.input
			&__item
				&:not([type=button]):not([type=checkbox]):not([type=radio]):not([type=textarea])
					height: $inputHeightLarge
					padding: $inputPaddingLarge
			&__label
				margin-bottom: 1rem
				font-size: $inputFontSizeLarge
		&.input--nopadding
			.input
				&__item
					&:not([type=button]):not([type=checkbox]):not([type=radio])
						padding: 0
	&.input--small
		.input
			&__item
				&:not([type=button]):not([type=checkbox]):not([type=radio]):not([type=textarea])
					height: $inputHeightSmall
					padding: $inputPaddingSmall
				&:not([type=button]):not([type=checkbox]):not([type=radio])
					font-size: $inputFontSizeSmall
					min-width: $inputHeightSmall
			&__label
				margin-bottom: .6rem
				font-size: $inputFontSizeSmall
		&.input--nopadding
			.input
				&__item
					&:not([type=button]):not([type=checkbox]):not([type=radio])
						padding: 0
	&.input--filter
		.input__item
			&:not([type=button]):not([type=checkbox]):not([type=radio]):not([type=textarea])
				padding: 0
			&:not([type=button]):not([type=checkbox]):not([type=radio])
				+br(0)
				border-width: 0 0 .1rem 0
				background-color: transparent
			&:focus
				border-color: $dark
		&.input--iconed
			.input__item
				&:not([type=button]):not([type=checkbox]):not([type=radio])
					padding-left: 3rem
					padding-bottom: 0
			&.input--large
				.input__item
					&:not([type=button]):not([type=checkbox]):not([type=radio])
						padding-left: 2.8rem
						padding-bottom: 0
			&.input--small
				.input__item
					&:not([type=button]):not([type=checkbox]):not([type=radio])
						padding-left: 2.4rem
						padding-bottom: .4rem
	&.input--iconed
		.input
			&__item
				&:not([type=button]):not([type=checkbox]):not([type=radio])
					padding-left: 4rem
		&.input--large
			.input__item
				&:not([type=button]):not([type=checkbox]):not([type=radio])
					padding-left: 4.6rem
		&.input--small
			.input__item
				&:not([type=button]):not([type=checkbox]):not([type=radio])
					padding-left: 3.4rem
		.icon
			stroke: $light
			position: absolute
			left: 1rem
			top: .7rem
		&.input--large
			.icon
				left: 1.2rem
				top: 1rem
		&.input--filter
			.icon
				left: -.4rem
				top: .7rem
			&.input--large
				.icon
					left: -.4rem
					top: 1.1rem
			&.input--small
				.icon
					left: -.4rem
					top: .3rem
		&.input--small
			.icon
				left: .8rem
				top: .4rem
	&.input--large
		.input__item:not([type=button]):not([type=checkbox]):not([type=radio])
			font-size: $inputFontSizeLarge
			line-height: $inputLineHeightLarge
	&.mini
		.input__item:not([type=button]):not([type=checkbox]):not([type=radio])
			max-width: 6rem
			min-width: 6rem
			text-align: center
	&.input--noborder
		.input
			&__item:not([type=button]):not([type=checkbox]):not([type=radio])
				border: 0
	&.input--nopadding
		padding: 0

input
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button
		-webkit-appearance: none
		margin: 0
	&[type=search]
		&::-webkit-search-cancel-button
			cursor: pointer !important
