.dropdown
	position: relative
	display: inline-block
	&__separator
		width: 100%
		border-bottom: .1rem solid $border
	&__menu
		+box()
		display: none
		background-clip: padding-box
		background-color: $white
		left: 0
		list-style: none
		margin-top: .8rem
		position: absolute
		top: 100%
		min-width: 16rem
		z-index: 100

		> ul
			list-style: none

		&.w
			top: 0
			right: 100%
			left: auto
			width: auto
			margin-top: 0
			margin-right: 1rem

		&.e
			top: 0
			left: 100%
			width: auto
			margin-top: 0
			margin-left: 1rem

		&.ne
			top: auto
			bottom: 100%
			left: 0
			margin-bottom: .3rem

		&.s
			right: 50%
			left: auto
			transform: translateX(50%)

		&.sw
			right: 0
			left: auto

		&__item
			+ellipsis()
			+flex()
			height: $buttonHeight
			> :not(.btn):not(.popover__child):not(.react-datepicker-wrapper)
				+ellipsis()
				+flex()
				height: $buttonHeight + .6rem
				padding: 0 $padding
				width: 100%
				cursor: default
				&:hover
					background-color: transparent
			&:first-child
				> *
					border-radius: $br $br 0 0
			&:last-child
				> *
					border-radius: 0 0 $br $br
			&__title
				background-color: $dark4
				padding: 0 $padding 0 $padding
				border-bottom: .1rem solid $border
			.popover__child
				width: 100%
	&.open
		.dropdown__menu
			display: block
			&--section
				display: flex
