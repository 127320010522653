.switch
	+flex()
	+mbi()
	&--small
		+mbi(.6rem)
	&__container
		position: relative
		width: 3.4rem
		display: inline-block
		vertical-align: middle
		user-select: none
		text-align: left
		flex-shrink: 0
		.switch--small &
			width: 2.4rem
	&__checkbox
		display: none
	&__label
		+br(2)
		display: block
		overflow: hidden
		cursor: pointer
		margin: 0
		&:focus
			outline: none
			> span
				box-shadow: 0 0 .2rem .5rem $switch-focus
		> span
			&:focus
				outline: none
	&__text
		&--disabled
			opacity: .6
		.switch--small &
			+txtSmall()
	&__inner
		display: block
		width: 200%
		margin-left: -100%
		transition: margin 0.3s ease-in 0s
		&:before, &:after
			display: block
			float: left
			width: 50%
			height: 1.8rem
			padding: 0
			line-height: 1.8rem
			color: white
			font-weight: bold
			box-sizing: border-box
			.switch--small &
				height: 1.3rem
		&:before
			content: attr(data-yes)
			text-transform: uppercase
			padding-left: 1rem
			background-color: $switch-active
			color: $white
		&:after
			content: attr(data-no)
			text-transform: uppercase
			padding-right: 1rem
			background-color: $switch-inactive
			color: $white
			text-align: right
	&__controller
		+br(2)
		display: block
		width: 1.6rem
		margin: .1rem
		background: $white
		position: absolute
		top: 0
		bottom: 0
		right: 1.6rem
		transition: all 0.3s ease-in 0s
		.switch--small &
			width: 1.1rem
			right: 1.1rem
	&__checkbox
		&:checked + .switch__label
			.switch
				&__inner
					margin-left: 0
					&:before
						background-color: $switch-active
				&__controller
					right: 0
		&:disabled + .switch__label
			.switch
				&__inner
					background-color: $switch-disabled
					cursor: not-allowed
					&:after
						opacity: .4
				&__controller
					cursor: not-allowed
		&:checked:disabled + .switch__label
			.switch
				&__inner
					&:before
						opacity: .4
				&__controller
					right: 0
