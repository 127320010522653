.quotas
	.settings__content
		background-color: $black2
	&__wrapper
		display: flex
		justify-content: center
		flex-wrap: wrap
		gap: 4rem
	&__value
		+br()
		+flexAxis()
		+bold()
		height: 3rem
		min-width: 4rem
		background-color: $blue
		color: $white
		font-size: 1.6rem

	&__item
		+br(1)
		flex-grow: 1
		background-color: $white
		display: flex
		flex-direction: column
		min-height: 18rem
		max-width: 40rem
		width: 100%
		border: .1rem solid $black10
		&__header
			padding: 3rem
			display: flex
			justify-content: space-between
			background-color: $blue10
			border-radius: .9rem .9rem 0 0
		&__content
			padding: 3rem 4rem
		h4
			font-size: 1.6rem
		p
			margin-bottom: 4rem

		.rc-slider
			margin-top: auto
			margin-bottom: 4rem
		.rc-slider
			&-step
				background-color: $dark10
			&-track,
			&-tracks
				background-color: $blue !important
				.quotas__item &
					background-color: $red !important
			&-handle
				background-color: $blue !important
				border-color: $blue !important
				width: 2rem
				height: 2rem
				margin-top: -8px
				&:hover
					box-shadow: none !important
					background-color: $blue !important
					border-color: $blue !important
			&-disabled
				background-color: transparent !important
				.rc-slider
					&-track
						background-color: $blue !important
					&-handle
						background-color: $blue !important
						border-color: $blue !important
						&:hover
							box-shadow: none !important
							background-color: $blue !important
							border-color: $blue !important
		&--warning
			.quotas
				&__item
					&__notice
						+bold()
						color: $red
					&__header
						background-color: rgb(255 1 85 / 5%)
				&__value
					background-color: $red
			.rc-slider
				&-track,
				&-tracks
					background-color: $red !important
				&-handle
					background-color: $red !important
					border-color: $red !important
					&:hover
						box-shadow: none !important
						background-color: $red !important
						border-color: $red !important
				&-disabled
					background-color: transparent !important
					.rc-slider
						&-track
							background-color: $red !important
						&-handle
							background-color: $red !important
							border-color: $red
							&:hover
								box-shadow: none !important
								background-color: $red !important
								border-color: $red !important
