.leads-in-list
	position: relative
	.connections--list
		display: grid
	.list
		display: flex
		width: unset
		flex-direction: column
		flex-grow: 1
	.not-provided
		color: $black30
	.not-found
		color: $black75
	.searching
		color: #00bd9a
	.list
		&__cols
			height: auto
		&__item
			flex-grow: 1
		&__col
			flex-shrink: 1
			&--spacer
				flex-shrink: 0
				width: .7rem
				padding-right: 0
			&.target
				min-width: unset
				max-width: unset
				flex-grow: 1
			&--small
				min-width: 10rem
			&--mini
				min-width: 6rem
			&.col-email
				max-width: 30rem
			&.col-job
				max-width: 35rem
				margin-left: 1.6rem
			&.col-headline
				min-width: 40rem
				flex-grow: 1
			&.col-company
				max-width: 20rem
			&--hidden-action
				min-width: 4rem
			.dropdown__menu.s
				right: 7rem
			.badge
				margin: auto
			&--checkbox
				min-width: unset
				max-width: unset
				width: 3rem
				flex-shrink: 0 !important
			.icon
				&.not-match
					stroke-width: .3rem
					stroke: #b5616f
				&.match
					stroke-width: .3rem
					stroke: $grass
			&.enriched-email-col
				+flex()
				color: $blue !important
			&--hidden-action
				.dropdown
					> .btn
						+sq(3.4)

		&__row
			min-height: 0
			height: 3.4rem
			display: flex
			.list
				&__col
					color: $black
					.popover__tooltip__cursor
						.icon
							stroke: $black
			&--leadnetwork,
			&--already-used
				background-color: #fdffe9 !important
				.list
					&__col
						color: $black60
			&--duplicated
				background-color: #fff3f3 !important
				.list
					&__col
						color: $black60
		&__filter
			&__lead-count
				+flex()
				+bold()
				+mbi()
				color: $black
				font-size: $title3
				padding: 0 1.9rem
				height: 100%
				border-right: .1rem solid $border
				flex-shrink: 1
				.tooltip
					font-size: 1.3rem !important
					font-weight: 500
			&--actions
				&__btn,
				&__btns,
				&__csv
					+flexAxis()
					flex-shrink: 0
					width: 100%
					max-width: 4.8rem
					height: 100%
					border-right: .1rem solid $border
					.tooltip,
					.popover__child
						width: 100%
						height: 100%
					.popover__child
						+flexAxis()
					&.last
						margin-left: auto
						border: 0
						border-left: .1rem solid $border
				&__btn
					&--processing
						width: auto
						max-width: unset
						border-right: 0
