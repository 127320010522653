.progress
	width: 100%
	min-width: 0
	min-height: 23rem
	padding: 5rem 8rem 12rem 8rem
	border-bottom: .1rem solid $black10
	flex-shrink: 0
	&__wrapper
		position: relative
		display: flex
		align-items: center
		gap: 2rem
	&__legend
		font-size: $title - .15rem
		margin: 0 0 2rem 0
		line-height: 1.4
	&__desc
		font-size: $title4
		line-height: 1.3
		color: $black
		margin: 0 0 4rem 0
	&__full
		+br(3)
		height: .5rem
		width: 100%
		background-color: $dark10
	&__bar
		border-radius: 3rem 0 0 3rem
		height: 100%
		width: 0
		position: relative
		background-color: $black
		overflow: visible
	&__start-value,
	&__end-value
		+br(10)
		+bold()
		color: $white
		background-color: $black
		padding: 1rem 1.6rem
		font-size: 1.6rem
	&__start-value
		left: 0
	&__end-value
		right: 0
	&__current-value
		position: relative
		&::before
			content: ''
			width: .2rem
			height: 6.1rem
			position: absolute
			top: -.5rem
			right: 0
			z-index: 1
			border-right: .2rem solid $blue
		&::after
			+bold()
			font-size: 1.6rem
			border-radius: 10rem
			content: attr(data-value)
			position: absolute
			right: -50%
			top: 2.3rem
			z-index: 0
			background-color: $white
			padding: 1rem 1.6rem 1rem 2rem
			color: $white
			background-color: $blue
