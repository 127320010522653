@keyframes pull
	from
		height: 45vh
	to
		height: 8rem

.publication
	+trans()
	&__wrapper
		+trans()
		.input
			+trans()
			flex-shrink: 0
			&__item
				+trans()
				border-radius: 0 !important
				font-size: $txt + .1
				color: $black
				height: 8rem
				border: 0 !important
				padding: 1.2rem 2rem 0 2rem
			&.expanded
				.input__item
					animation: push .3s ease-out
					height: 45vh
	&__header
		+mbi(.6)
		+flexSpread()
		flex-grow: 1
		background-color: $contentSideBarColor
		// padding: 0 $lnPad - 1
		height: $lnHeaderHeight - 1
		border-bottom: .1rem solid $border
		&__actions
			&-left
				height: 100%
				display: inline-flex
				.btn
					height: 100%
					&:last-child
						border-right: .1rem solid $border
			&-right
				+mbi(3)
				display: inline-flex
				height: 100%
				align-items: center
				padding-right: $lnPad - 1
				&:only-child
					display: flex
					padding: 0 $lnPad - 1
					width: 100%
					> :last-child
						margin-left: auto
				.dropdown__menu
					max-height: 30rem
					overflow-y: auto
	&__attachment
		+flexSpread()
		gap: 1rem
		padding: 0 $lnPad - 1
		color: $blue
		background-color: $blue6
		flex-shrink: 0
		height: 4rem
		border-bottom: .1rem solid $black6
		.btn
			.icon
				stroke: $blue
	&__footer
		display: flex
		align-items: flex-end
		width: 100%
		padding: 0 1rem 1rem 1rem
		.btn
			width: 4rem !important
			height: 3rem !important
			&.gif
				margin-left: auto
				height: 3.6rem !important
				margin-right: 1rem
			&.send
				height: 3.6rem !important
				width: $buttonHeight * 1.2 !important
