.btn
	cursor: pointer
	text-decoration: none
	&.disabled
		opacity: .4
		cursor: default
		&:hover
			color: $blue
			.icon
				stroke: $blue
	&--icon:not(.btn--link):not(.btn--link-info):not(.btn--sidebar-link):not(.btn--dark-mode):not(.btn--campaign)
		+sq()
		+br()
		+flexAxis()
		padding: 0
		flex-shrink: 0
		// &:not(.btn--transparent):not(.btn--bulk):not(.btn--secondary):not(.btn--secondary-dark):not(.btn--secondary-blue):not(.btn--primary):not(.btn--important)
		//	+btn()
		&.btn--round
			+br(5)
		&.btn--loading
			padding: 0
			.icon
				+sq(2.4)
		&.btn--bulk
			+trans()
			+flexAxis()
			border-radius: 0
			width: 100%
			max-width: 4.8rem
			height: 100%
			&:hover:not(.btn--loading):not(.btn--processing)
				background-color: $blue6
				.icon
					stroke: $blue
			&:disabled
				+disabled($white,$black)
			&.btn--processing
				flex-shrink: 0
				width: 4.8rem
				&.btn--icon
					.icon
						stroke: $black
						animation: process 3s linear infinite
						transition: none
						stroke: .3rem
		&.active
			.icon
				stroke: $blue
				stroke-width: 1.6
	&--list-action
		+trans()
		+flexAxis()
		border-radius: 0
		height: 100%
		background-color: $white
		padding: 0 2.4rem
		&:hover:not(.btn--loading)
			background-color: $blue6
			color: $blue
			.icon
				stroke: $blue
		&:disabled
			+disabled($white,$black)
	&--has-icon
		&:not(.btn--nano):not(.btn--mini):not(.btn--small):not(.btn--large)
			+mbi(.7)
	&--green,
	&--green-sf,
	&--pink,
	&--openprofile,
	&--violet,
	&--primary,
	&--black,
	&--important
		+btn()
		+textShadow()
		color: $white
		.icon
			stroke: $white
			+trans()
		&.btn.btn--loading
			.icon
				&.icon--fill
					fill: $white
					stroke-width: 0
			&:not(.btn--nano):not(.btn--mini):not(.btn--small):not(.btn--large)
				.icon
					margin-right: .7rem
					fill: $white
					stroke-width: 0
		&.btn--icon
			flex-shrink: 0
		&:disabled
			cursor: default
			opacity: .3
	&--primary
		+hover()
		background-color: $blue
	&--black
		background-color: $black
		&:hover:not(:disabled)
			background-color: $primary
	&--important
		+hover()
		background-color: $red
	&--openprofile
		+hover()
		background-color: $openprofile
	&--violet
		+hover()
		background-color: $violet
	&--green-sf
		+hover()
		background-color: $green-sf
	&--green
		+hover()
		background-color: $green
	&--yellow
		+btn()
		+hover()
		background-color: $yellow
		color: $black
		text-shadow: none
		.icon
			+trans()
			stroke: $black
		&:hover
			border-color: $dark !important
		&:disabled
			cursor: default
			opacity: .3
	&--white
		+btn()
		+hover()
		background-color: $white
		color: $black
		text-shadow: none
		.icon
			+trans()
			stroke: $black
		&:disabled
			cursor: default
			opacity: .3

	&--pink
		+hover()
		background-color: $pink
		color: $white
		text-shadow: none
		.icon
			stroke: $white

	&--transparent
		+btnNotHovered()
		background-color: transparent
		color: $dark
		.btn__label
			+ellipsis()
			transition: none
		.icon
			+trans()
		&.btn--has-icon
			&:not(.btn--nano):not(.btn--mini):not(.btn--small):not(.btn--large)
				+mbi(.7)
		&.btn--icon
			+br(50)
			flex-shrink: 0
			&:hover:not(.btn--loading)
				.icon
					stroke: $dark
		&.active
			cursor: pointer
		&.active,
		&:hover:not(.btn--loading)
			background-color: $sidebar
			color: $dark
			.icon
				stroke: $dark
		&:disabled
			+disabled(transparent,$dark)
	&--inverse
		+btn()
		background-color: transparent
		border: .1rem solid $white
		color: $white
		.icon
			stroke: $white
		&.btn--has-icon
			&:not(.btn--nano):not(.btn--mini):not(.btn--small):not(.btn--large)
				+mbi(.7)
		&.btn--icon
			flex-shrink: 0
		&:hover:not(.btn--loading)
			border-color: $green
			color: $white
			.icon
				stroke: $white
		&:disabled
			+disabled(transparent,$white)
			&:hover
				border-color: $green
	&--dropdown-item
		+flexBtn()
		+ellipsis()
		+mbi(1)
		height: $buttonHeight
		font-size: $buttonFontSize
		line-height: $buttonLineHeight
		padding: $buttonPadding
		max-width: 36rem
		background-color: transparent
		color: $dark
		width: 100%
		.icon
			+sq(2)
			stroke-width: .15rem
		.btn__label
			+ellipsis()
		&:hover:not(.btn--loading),
		&.active
			background-color: $blue6
		&.active
			+bold()
		&.btn--has-icon
			padding: 0 $padding 0 $padding - .4
		&--important
			color: $red
			.icon
				stroke: $red
		&:disabled
			+disabled($white,$dark)
	&--dropzone
		+flexCol()
		+br()
		+trans()
		padding: .4rem
		width: 100%
		min-height: 10rem
		border: .15rem dashed $blue40
		color: $blue
		.btn__label
			+flexAxis()
			+fullwh()
			+br(.4)
			+trans()
			background-color: $blue6
			flex-grow: 1
		&:disabled
			+disabled(transparent,$blue)
			border-color: $dark40
			.btn__label
				background-color: $dark10
		&-container
			&.active
				.btn--dropzone
					border-color: $blue
					.btn__label
						background-color: $blue20
	&--link,
	&--link-info,
	&--link-inline,
	&--link-primary,
	&--link-red,
	&--link-black
		+focus()
		+trans()
		padding: 0
		height: auto
		line-height: $txt
		color: $dark
		vertical-align: baseline
		position: relative
		.btn__label
			line-height: $txt
			vertical-align: baseline
		&:hover
			color: $blue
			.icon
				stroke: $blue
		&:disabled
			cursor: default
			opacity: .3
			&:hover
				color: inherit
				opacity: .3
				text-decoration: underline
				.icon
					stroke: $dark
		.label &
			&:hover
				.icon
					stroke: $white
		.label.label--white &
			&:hover
				.icon
					stroke: $dark
		&.btn--icon
			padding: 0
			text-decoration: none
		&.btn--has-icon
			&:not(.btn--icon-right):not(.btn--no-label)
				.icon
					margin-right: .7rem
			&.btn--icon-right
				.icon
					margin-left: .3rem
	&--link-inline
		.icon
			+trans()
			position: relative
			top: .7rem
		&:not(.btn--icon-right)
			.icon
				left: -.3rem
			&.btn--small
				.icon
					top: .5rem
					left: -.3rem
			&.btn--large
				.icon
					top: 1.2rem
					left: -.3rem
		&.btn--icon-right
			.icon
				right: -.3rem
			&.btn--small
				.icon
					top: .5rem
					rigth: -.3rem
			&.btn--large
				.icon
					top: 1.2rem
					rigth: -.3rem
	&--link,
	&--link-info,
	&--link-primary,
	&--link-red,
	&--link-black
		+flex()
		&.btn--no-label
			+flexAxis()
		&.btn--large, &.btn--small
			height: auto
			padding: 0 !important
	&--link-info
		&:hover
			color: $blue
			text-decoration: none
			.icon
				stroke: $blue
		&:disabled
			&:hover
				color: $blue
				text-decoration: none
				.icon
					stroke: $blue
	&--link-inline
		text-decoration: underline
	&--link-inline,
	&--link
		&.btn--light-color
			color: $dark40
			.icon
				+trans()
				stroke: $dark40
			&:hover
				color: $blue
				.icon
					stroke: $blue
	&--link-primary
		color: $blue
		.icon
			stroke: $blue
		&:hover
			color: $black
			.icon
				stroke: $black
		&:disabled
			&:hover
				color: $blue
				text-decoration: none
				.icon
					stroke: $blue
	&--link-black
		color: $black
		.icon
			stroke: $black
		&:hover
			color: $blue
			.icon
				stroke: $blue
		&:disabled
			&:hover
				color: $black
				text-decoration: none
				.icon
					stroke: $black
	&--link-red
		color: $red
		.icon
			stroke: $red
		&:hover
			color: $black
			.icon
				stroke: $black
		&:disabled
			&:hover
				color: $red
				text-decoration: none
				.icon
					stroke: $red
	&--link-info
		color: $yellow
		.icon
			stroke: $yellow
		&:hover
			color: $blue
			text-decoration: underline
			.icon
				stroke: $blue
	&--link-info,
	&--link
		transition: none
		.icon
			+trans()
		.btn__label
			+trans()

	&--has-icon:not(.btn--no-label):not(.btn--link):not(.btn--link-info):not(.btn--link-primary):not(.btn--link-black):not(.btn--link-red):not(.btn--loading):not(.btn--nano):not(.btn--mini):not(.btn--small):not(.btn--large):not(.btn--dropdown-item):not(.btn--tab):not(.btn--sidebar-link):not(.btn--bulk):not(.btn--list-action)
		padding: 0 $padding 0 $padding - .4
		&.btn--icon-right
			padding: 0 $padding - .4 0 $padding
	&--loading
		cursor: default !important
		&:not(.btn--icon):not(.btn--nano):not(.btn--mini):not(.btn--small):not(.btn--large):not(.btn--sidebar-link)
			padding-left: $padding - .2
		.icon
			+sq(2.2)
	&--switch
		+btn()
		color: $dark
		background-color: $white
		border: .1rem solid $inputBorderColor
		&.btn--has-icon
			&:not(.btn--nano):not(.btn--mini):not(.btn--small):not(.btn--large)
				+mbi(.7)
		&.btn--icon
			flex-shrink: 0
		&:hover
			color: $dark
			background-color: $blue10
		&.active
			color: $white
			border-color: $dark
			background-color: $dark
			.icon
				stroke: $white
			&:hover
				background-color: $dark
	&--group-sidebar
		+trans()
		+br()
		position: relative
		width: 100%
		.dropdown
			position: absolute
			right: .6rem
			top: .4rem
			z-index: 1
			> .btn
				width: 2.6rem !important
				height: 2.6rem !important
				border-radius: 100% !important
				background-color: $black6
				&.btn--notification
					+notification($blue, 0, 1.9rem)
				&:hover
					background: $blue30 !important
					.icon
						stroke: $blue
			.btn--dropdown-item
				&.btn--notification
					+notification($blue, .6rem, 1.9rem)
		&:hover
			.dropdown
				> .btn
					background-color: $blue6
	&--tab
		+btn()
		justify-content: center
		text-align: center
		line-height: 1.4
		white-space: normal
		max-width: 15rem
		height: 100%
		padding: 0 2rem
		min-width: 8rem
		border-radius: 0
		background-color: transparent
		border-right: .1rem solid $border
		background-color: $black4
		color: $black50
		&.active
			+bold()
			background-color: $white
			color: $black
			.icon
				stroke: $black
		&:hover:not(:disabled)
			background-color: $white
			color: $black
			.icon
				stroke: $black
		&--white
			background-color: $white
			color: $black50
			.icon
				stroke: $black50
			&:hover:not(:disabled)
				background-color: $blue20
				color: $blue
				.icon
					stroke: $blue
		&:disabled
			+disabled(transparent,$dark)
	&--tab-switch
		+trans()
		background-color: $white
		font-weight: 900
		color: $black
		border: .1rem solid $black
		font-size: 1.4rem
		height: 3.6rem
		padding: 0 2rem
		&:disabled
			cursor: default
			opacity: 1
			background-color: $blue
			color: $white
			border-color: $blue !important
		&:hover
			background-color: $blue
			color: $white
			&:not(:disabled)
				background-color: #054adf
				color: $white
				border-color: #054adf !important
		&--group
			display: flex
			align-items: center
			> .btn
				border-radius: 0
				border-right: 0
				&:first-child
					border-radius: $br 0 0 $br
				&:last-child
					border: .1rem solid $black
					border-radius: 0 $br $br 0
	&--group
		display: inline-flex
		width: 100%
		min-width: 0
		.dropdown
			display: flex
			max-width: 50%
			.btn--group-item
				transition: 0s background-color ease-out
				flex-grow: 1
				height: $buttonHeight - .2
				max-width: 100%
				background-color: $contentSideBarColor
				.btn__label
					width: 100%
				&:hover
					background-color: $blue6
					border-color: $dark10
				&:disabled
					cursor: default
					background-color: #f6f6f6
					color: $dark40
					border-color: $dark5
					.icon
						stroke: $dark20
					&:hover
						background-color: #f6f6f6
						color: $dark40
						border-color: $dark5
						.icon
							stroke: $dark20
			&__menu
				max-width: 26rem
				max-height: 30rem
				overflow-y: auto
		> :first-child
			.btn--group-item
				border-radius: $br - .1 0 0 $br - .1
				border: .1rem solid $inputBorderColor
				border-right: 0
		> :not(:first-child):not(:last-child)
			.btn--group-item
				border-radius: 0
				border-left: .1rem solid $inputBorderColor
		> :last-child
			.btn--group-item
				border-radius: 0 $br - .1 $br - .1 0
				border: .1rem solid $inputBorderColor

	&--toggle:not(.btn--link),
	&--template,
	&--secondary,
	&--secondary-dark,
	&--secondary-white,
	&--secondary-blue
		+btnNotHovered()
		width: 100%
		background-color: transparent
		padding: 0 1.1rem
		.btn__label
			+ellipsis()
		&:hover
			background-color: $blue6
			color: $blue
			.icon
				stroke: $blue
		&.active
			cursor: pointer
			background-color: $sidebar
			+bold()
			color: $blue
			.icon
				stroke: $blue
				stroke-width: 1.8
			&:hover
				background-color: $blue6
				.icon
					stroke: $blue
		&.btn--has-icon
			+mbi(.8)
		&.btn--loading
			.icon--load
				fill: $dark
				stroke-width: 0
		&:disabled:not(.btn--secondary-dark)
			opacity: .5
			cursor: default
			&:hover
				background-color: transparent
				color: $dark
				.icon
					stroke: $dark
	&--secondary
		&.active,
		&:hover
			background-color: $blue10
		&.active
			&:hover
				background-color: $blue10
		&.no-label
			padding: .6rem 1.2rem .6rem 1.4rem !important
			.icon
				stroke-width: .12rem

	&--secondary-dark
		background-color: $laneColor
		&:disabled
			background-color: $laneColor
			color: $light
			.icon
				stroke: $light
			&:hover
				background-color: $laneColor
				color: $dark
				.icon
					stroke: $dark
	&--secondary-blue
		background-color: $blue6
		color: $blue
		.icon
			stroke: $blue
		&:hover
			background-color: $blue15
		&:disabled
			&:hover
				background-color: $blue6
				color: $blue
				.icon
					stroke: $blue
	&--secondary-white
		background-color: $white
		color: $dark
		.icon
			stroke: $dark
		&:hover
			background-color: $dark
			color: $white
			.icon
				stroke: $white
		&:disabled
			&:hover
				background-color: $white
				color: $dark
				.icon
					stroke: $dark
	&--template
		> :last-child
			margin-left: auto
	&--toggle
		&:not(.btn--link)
			&.active,
			&:hover
				cursor: pointer
				background-color: $blue12
				.icon
					stroke: $blue !important
			&.active
				.icon
					stroke: $blue
					stroke-width: 1.3
				&:hover
					background-color: $blue12
			&.btn--loading
				.icon
					fill: $blue !important
			&.btn--secondary-blue
				background-color: $blue6
				&:hover
					background-color: $blue15
		&.btn--link
			&.active,
			&:hover
				cursor: pointer
				.icon
					stroke: $blue !important
			&.active
				.icon
					stroke: $blue
					stroke-width: 1.3

	&--bordered
		border: .1rem solid $black20
		&:hover:not(:disabled)
			border-color: $blue
		&.btn--transparent,
		&.btn--white
			&:hover:not(:disabled)
				border-color: $black30
	&--sidebar-link
		+trans(.6s)
		+sq($sidebarButtonSize)
		+flexAxis()
		background-color: $sidebarButton
		border-top: .1rem solid $sidebar
		border-bottom: .1rem solid $sidebar
		&.active,
		&:hover
			background-color: $sidebarButtonActive
			.icon
				stroke: $blue
		&.active
			cursor: default
			background-color: $sidebarButtonActive
			.icon
				stroke: $blue
				stroke-width: 1.6
			&:hover
				background-color: $sidebarButtonActive
				.icon
					stroke: $blue
					stroke-width: 1.6
			img
				border: .2rem solid $blue
	&--notification
		+notification()
		+bold()
		position: relative
		&:not(.btn--no-label)
			+notification($pink, .5rem, 2.2rem)
			&.btn--sidebar-link
				+notification($pink, 1.1rem, 3rem)
		.icon
			stroke-width: .18rem
	&--processing,
	&--processing:disabled,
	&--processing.btn--primary
		+flex()
		+bold()
		cursor: default
		text-shadow: unset !important
		opacity: 1 !important
		background-color: $processing !important
		color: $black
		.icon
			stroke: $black !important
			stroke-width: .16rem
			animation: process 3s linear infinite
			transition: none
			stroke: .3rem
		&:hover
			background-color: $processing !important
			color: $black !important
			.icon
				stroke: $black !important
	&--group-menu
		+flex()
		> .btn
			&:first-child
				flex-shrink: 0
				border-radius: .6rem 0 0 .6rem !important
		.dropdown
			flex-shrink: 0
			> .btn
				border-radius: 0 .6rem .6rem 0 !important
				border-left: .1rem solid $white20
	&--dark-mode
		+br(10)
		+sq(3)
		+flexAxis()
		background-color: $white
		border: .3rem solid $black20
		transition: all .3s ease-in-out
		.icon
			stroke: $black
		&:hover
			background-color: $black80
			border: .1rem solid $black80
			.icon
				stroke: $white
				transform: rotate(360deg)
	&.active
		cursor: default
	&--small
		+buttonSmall()
		&.btn--switch
			+buttonSmall()
			&.btn--icon
				width: auto
				img
					max-width: 2.2rem
		&:not(.btn--custom-icon-size)
			.icon
				+sq(1.8)
		&:not(.btn--icon)
			.icon
				margin-right: .4rem
			&.btn--has-icon
				padding-left: $padding - .7
				&.btn--loading
					padding-left: $padding - .6
					.icon
						+sq(1.8)
		&.btn--icon:not(.btn--link):not(.btn--link-info):not(.btn--sidebar-link):not(.btn--campaign)
			+buttonSmall()
			width: $buttonHeightSmall
	&--mini
		&.btn
			+buttonMini()
			&.btn--icon
				padding: 0
			&:not(.btn--custom-icon-size)
				.icon
					+sq(1.8)
					stroke-width: .14rem
			&:not(.btn--icon)
				.icon
					margin-right: .3rem !important
				&.btn--has-icon
					padding-left: $padding - .6
					&.btn--loading
						padding-left: $padding - .3
						+disableHoverEffect()
						.icon
							+sq(1.6)
			&.btn--icon:not(.btn--link):not(.btn--link-info):not(.btn--sidebar-link)
				+buttonMini()
				width: $buttonHeightMini
	&--nano
		&.btn
			+buttonNano()
			&:not(.btn--custom-icon-size)
				.icon
					+sq(1.6)
					stroke-width: .15rem
			&:not(.btn--icon)
				.icon
					margin-right: .2rem
				&.btn--has-icon
					padding-left: $padding - .7
					&.btn--loading
						padding-left: $padding - .1
						+disableHoverEffect()
						.icon
							+sq(1.1)
			&.btn--icon:not(.btn--link):not(.btn--link-info):not(.btn--sidebar-link)
				+buttonNano()
				width: $buttonHeightNano
	&--large
		+buttonLarge()
		.icon
			stroke-width: .1rem
		&:not(.btn--custom-icon-size)
			.icon
				+sq(3.2)
		&:not(.btn--icon)
			.icon
				margin-right: .6rem
			&.btn--has-icon
				padding-left: $padding - .4
				&.btn--loading
					padding-left: $padding
					+disableHoverEffect()
					.icon
						+sq(3.2)
		&.btn--icon
			+buttonLarge()
			width: $buttonHeightLarge
	&--relation
		background-color: $blue
	&--contact
		background-color: $blue
	&--campaign
		+flexAxis()
		+trans()
		flex-shrink: 0
		width: 100%
		height: 100%
		border-right: .1rem solid $border
		&:hover
			background-color: $blue6
		&.active
			background-color: $blue10
			.icon
				stroke: $blue
				stroke-width: 1.6
	&--launch
		background-color: #6725ff
