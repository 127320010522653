
$loaderHeight: 1.5rem
$loaderWidth: .55rem
$loaderMinHeight: .15rem
$loaderSpace: $loaderWidth + .25

$loaderChatHeight: 1.6rem
$loaderChatWidth: .7rem
$loaderChatMinHeight: .15rem
$loaderChatSpace: $loaderWidth + .5

$loaderLargeHeight: 1.6rem
$loaderLargeWidth: .65rem
$loaderLargeMinHeight: .4rem
$loaderLargeSpace: $loaderLargeWidth + .4

$loaderSpeed: .5s
$loaderSpeedSmall: .35s

.loader
	+br(.8)
	+sq(3)
	+flexAxis()
	background-color: $blue
	position: relative
	z-index: 0
	&-wrapper
		+mbib()
		+flexAxis()
		flex-direction: column
	&-item
		z-index: 10
		+br(2)
		width: $loaderWidth
		height: $loaderHeight
		display: block
		margin: 20px auto
		position: relative
		background-color: $white
		animation: animLoader $loaderSpeed $loaderSpeed linear infinite alternate
		&:after,
		&:before
			+br()
			z-index: 10
			content: ''
			width: $loaderWidth
			height: $loaderHeight
			background-color: $white
			position: absolute
			top: 50%
			left: $loaderSpace
			transform: translateY(-50%)
			animation: animLoader $loaderSpeed $loaderSpeed + calc(calc($loaderSpeed / 2)) linear infinite alternate
		&:before
			left: - $loaderSpace
			animation-delay: 0s
	&--large
		+br(1)
		width: 4rem
		height: 3.4rem
		.loader
			&-item
				z-index: 10
				+br(2)
				width: $loaderLargeWidth
				height: $loaderLargeHeight
				display: block
				margin: 20px auto
				position: relative
				background-color: $white
				animation: animLargeLoader $loaderSpeed $loaderSpeed linear infinite alternate
				&:after,
				&:before
					+br()
					z-index: 10
					content: ''
					width: $loaderLargeWidth
					height: $loaderLargeHeight
					background-color: $white
					position: absolute
					top: 50%
					left: $loaderLargeSpace
					transform: translateY(-50%)
					animation: animLargeLoader $loaderSpeed $loaderSpeed + calc(calc($loaderSpeed / 2)) linear infinite alternate
				&:before
					left: - $loaderLargeSpace
					animation-delay: 0s
	&--chat
		border-radius: .55rem .55rem 0 .55rem
		width: 4rem
		height: 3rem
		&:before
			position: absolute
			z-index: -1
			content: ''
			width: 0
			height: 0
			top: 2.7rem
			right: .43rem
			border-top: 1rem solid $blue
			border-left: 1rem solid transparent
			border-end-end-radius: .2rem
			transform: rotate(-10deg)
		&:after
			position: absolute
			z-index: -1
			content: ''
			width: 0
			height: 0
			top: 2.6rem
			right: 0
			border-top: .7rem solid $blue
			border-left: .32rem solid $blue
			border-bottom: .32rem solid $blue
			border-right: .32rem solid $blue
			border-radius: .6rem
		.loader
			&-item
				width: $loaderChatWidth
				height: $loaderChatHeight
				animation: animLargeLoader $loaderSpeed $loaderSpeed linear infinite alternate
				&:after,
				&:before
					width: $loaderChatWidth
					height: $loaderChatHeight
					background-color: $white
					left: $loaderChatSpace
					animation: animLargeLoader $loaderSpeed $loaderSpeed + calc($loaderSpeed / 2) linear infinite alternate
				&:before
					left: - $loaderChatSpace
		&.loader--quick
			.loader
				&-item
					animation: animLargeLoader $loaderSpeed $loaderSpeed linear 3 alternate
					&:after,
					&:before
						animation: animLargeLoader $loaderSpeed $loaderSpeed + calc($loaderSpeed / 2) linear 3 alternate
		&.loader--small
			border-radius: .335rem .335rem 0 .335rem
			width: 2.28rem
			height: 1.65rem
			&.loader--chat
				&:after
					top: 1.04rem
					border-left: .02rem solid $blue
					border-radius: .6rem
				&:before
					top: 1.25rem
					right: 0.23rem
					transform: rotate(-15.2deg)
			.loader
				&-item
					animation: animSmallLoader $loaderSpeedSmall $loaderSpeedSmall linear 3 alternate
					width: .35rem
					height: 1.06rem
					&:before,
					&:after
						animation: animSmallLoader $loaderSpeedSmall $loaderSpeedSmall + calc($loaderSpeedSmall / 2) linear 3 alternate
					&:before
						top: 41%
						left: -.6rem
						width: .35rem
						height: .823rem
					&:after
						top: 39%
						left: 0.6rem
						width: .35rem
						height: .69rem

	@keyframes animSmallLoader
		0%
			height: .3rem
		100%
			height: .8rem

	@keyframes animLargeLoader
		0%
			height: $loaderLargeHeight + .4
		100%
			height: $loaderLargeMinHeight

	@keyframes animLoader
		0%
			height: $loaderHeight + .4
		100%
			height: $loaderMinHeight

.loader-bullets
	width: 1rem
	height: 1rem
	border-radius: 50%
	display: block
	margin: 1.5rem auto
	position: relative
	color: #FFF
	left: -10rem
	box-sizing: border-box
	animation: shadowRolling 2s linear infinite

@keyframes shadowRolling
	0%
		box-shadow: 0 0 $blue0, 0 0 $blue0, 0 0 $blue0, 0 0 $blue0

	12%
		box-shadow: 10rem 0 $linkedin, 0 0 $blue0, 0 0 $blue0, 0 0 $blue0

	25%
		box-shadow: 11rem 0 $linkedin, 10rem 0 $linkedin, 0 0 $blue0, 0 0 $blue0

	36%
		box-shadow: 12rem 0 $linkedin, 11rem 0 $linkedin, 10rem 0 $linkedin, 0 0 $blue0

	50%
		box-shadow: 13rem 0 $linkedin, 12rem 0 $linkedin, 11rem 0 $linkedin, 10rem 0 $linkedin

	62%
		box-shadow: 20rem 0 $blue0, 13rem 0 $linkedin, 12rem 0 $linkedin, 11rem 0 $linkedin

	75%
		box-shadow: 20rem 0 $blue0, 20rem 0 $blue0, 13rem 0 $linkedin, 12rem 0 $linkedin

	87%
		box-shadow: 20rem 0 $blue0, 20rem 0 $blue0, 20rem 0 $blue0, 13rem 0 $linkedin

	100%
		box-shadow: 20rem 0 $blue0, 20rem 0 $blue0, 20rem 0 $blue0, 20rem 0 $blue0

.loader-love
	position: relative
	width: 1rem
	height: 1.5rem
	animation: heartBeat 1s infinite cubic-bezier(0.215, 0.61, 0.355, 1)
	&:before,
	&:after
		content: ""
		background: $blue
		width: 1rem
		height: 1.5rem
		border-radius: 5rem 5rem 0 0
		position: absolute
		left: 0
		bottom: 0
		transform: rotate(45deg)
		transform-origin: 51% 67%
		box-shadow: 0.3rem 0 0.7rem $black20 inset
	&:after
		transform: rotate(-45deg)
	&--large
		width: 4rem
		height: 6rem
		&:before,
		&:after
			width: 4rem
			height: 6rem
			box-shadow: .5rem .4rem .5rem $black20 inset

@keyframes heartBeat
	0%
		transform: scale(0.95)
	5%
		transform: scale(1.1)
	39%
		transform: scale(0.85)
	45%
		transform: scale(1)
	60%
		transform: scale(0.95)
	100%
		transform: scale(0.9)

.loader-cloud
	width: 2.3rem
	aspect-ratio: 1
	--c: no-repeat radial-gradient(farthest-side,#bfbfc3 92%,#0000)
	background: var(--c) 50% 0, var(--c) 50% 100%, var(--c) 100% 50%, var(--c) 0 50%
	background-size: .46rem .46rem
	position: relative
	flex-shrink: 0
	.btn--sidebar-link.active &
		--c: no-repeat radial-gradient(farthest-side,#3474FF 100%,#0000) !important
	&:before
		content: ""
		position: absolute
		inset: 0
		margin: .18rem
		background: repeating-conic-gradient(#0000 0 35deg, #bfbfc3 90deg)
		-webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - .13rem),#000 0)
		border-radius: 50%
		.btn--sidebar-link.active &
			background: repeating-conic-gradient(#0000 0 35deg,#3474FF 0 90deg) !important
	&:after
		content: ''
		+sq(1)
		position: absolute
		background-repeat: no-repeat
		z-index: 10
		background-size: 1rem
		top: calc(50% - .5rem)
		right: calc(50% - .5rem)

	&-wrapper
		position: relative
		.tooltip
			+sq(2.3)
		&:after
			content: ''
			+sq(1)
			position: absolute
			background-repeat: no-repeat
			z-index: 10
			background-size: 1rem
			top: calc(50% - .5rem)
			right: calc(50% - .5rem)
			background-image: url(../../images/linkedin-light.webp)
			.btn--sidebar-link.active &
				background-image: url(../../images/linkedin-blue.webp) !important
		&.active
			&:after
				background-image: url(../../images/linkedin.webp)
				.btn--sidebar-link.active &
					background-image: url(../../images/linkedin-blue.webp)
			.loader-cloud
				animation: l18 1s infinite
				--c: no-repeat radial-gradient(farthest-side,#007ebb 92%,#0000)
				&:before
					background: repeating-conic-gradient(#0000 0 35deg,#007ebb 0 90deg)
		&.background
			.loader-cloud
				animation: l18 1s infinite

@keyframes l18
	100%
		transform: rotate(.5turn)
